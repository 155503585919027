import * as React from "react"
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import toastr from 'toastr'
import * as i18n from "i18n-js"
import InputLabel from "@mui/material/InputLabel/InputLabel";
import FormControl from "@mui/material/FormControl/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import Box from "@mui/material/Box/Box";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import Chip from "@mui/material/Chip/Chip";
import { ShippingMark } from "../Common/types";
import CopyButton from "./CopyButton";

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface AddPackingItemButtonProps {
  project_id: number,
  pack_finished: boolean,
  onItemAdded: () => void
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  style: { zIndex: 2400 }
};

function getStyles(name: string, shippingMark: readonly ShippingMark[], theme: Theme) {
  return {
    fontWeight:
      shippingMark.map(m => m.name).indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddPackingItemButton = (props: AddPackingItemButtonProps) => {


  const [show, setShow] = React.useState(false);

  const [width, setWidth] = React.useState("100");
  const [widthError, setWidthError] = React.useState("");

  const [height, setHeight] = React.useState("120");
  const [heightError, setHeightError] = React.useState("");

  const [depth, setDepth] = React.useState("150");
  const [depthError, setDepthError] = React.useState("");

  const theme = useTheme();
  const [shippingMark, setShippingMark] = React.useState<ShippingMark[]>([]);
  const [shippingMarks, setShippingMarks] = React.useState<ShippingMark[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof shippingMark>) => {
    const {
      target: { value },
    } = event;
    //@ts-ignore
    setShippingMark(value);
  };

  const handleClose = () => setShow(false);

  function buttonClicked() {
    setShow(true);
  }

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function loadShippingMarks() {
    Axios({
      method: "GET",
      url: `/shipping_marks.json`,
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        setShippingMarks(res.data)
      })
  }

  function reloadTasksTable() {
    Axios({
      method: "GET",
      url: `/projects/${props.project_id}/tasks`,
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        //@ts-ignore
        $("#packaging-tasks-table").html(res.data);
      })
  }

  function submitForm(): void {

    Axios({
      method: "POST",
      url: `/projects/${props.project_id}/packing_items.json`,
      data: { packing_item: { width: width, height: height, depth: depth }, shipping_marks: shippingMark.map(m => m.id) },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        setShow(false);
        toastr.success("Packing item is created");
        props.onItemAdded()
        reloadTasksTable()
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Packing item is NOT created", err)
      })
  }

  function widthChanged(newVal: string): void {
    setWidth(newVal)
    setWidthError(validateNumber(newVal, 5, 1250))
  }

  function heightChanged(newVal: string): void {
    setHeight(newVal)
    setHeightError(validateNumber(newVal, 5, 250))
  }

  function depthChanged(newVal: string): void {
    setDepth(newVal)
    setDepthError(validateNumber(newVal, 5, 300))
  }

  function validateNumber(newVal: string, minimum: number, maximum: number): string {
    if (!isNumber(newVal)) {
      return "It is not numeric";
    } else {
      let n = parseFloat(newVal);
      if (n > maximum) {
        return `Too big - maximum allowed is ${maximum}`;
      } else if (n < minimum) {
        return `Too small - minimum allowed is ${minimum}`;
      }
    }

    return "";
  }

  const isValid = (widthError == "" && heightError == "" && depthError == "")

  const link =
    <button onClick={() => buttonClicked()}
      className="btn btn-primary ml-3"
      title={'Add new item'}
      disabled={props.pack_finished}
    >Add new item</button>

  React.useEffect(() => {
    if (show) {
      setShippingMark([]);
      loadShippingMarks();
    }
  }, [show]);

  return (
    <div className="pt-2 row">
      <CopyButton
        project_id={props.project_id}
        tab_index={5}
        copiedSuccess={() => props.onItemAdded()}
      />
      {link}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            New Packing Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group row"><label className="col-lg-6 col-form-label">Length</label>
              <div className="col-lg-6">
                <div className="input-group m-b">
                  <input
                    type="text"
                    placeholder="length"
                    value={width}
                    onChange={(e) => widthChanged(e.target.value)}
                    className="form-control" />

                  <div className="input-group-prepend">
                    <span className="input-group-addon">cm</span>
                  </div>
                </div>
                <span className="form-text text-danger m-b-none">{widthError}</span>
              </div>
            </div>

            <div className="form-group row"><label className="col-lg-6 col-form-label">Height</label>
              <div className="col-lg-6">

                <div className="input-group m-b">
                  <input
                    type="text"
                    placeholder="height"
                    value={height}
                    onChange={(e) => heightChanged(e.target.value)}
                    className="form-control" />
                  <div className="input-group-prepend">
                    <span className="input-group-addon">cm</span>
                  </div>
                </div>

                <span className="form-text text-danger m-b-none">{heightError}</span>
              </div>
            </div>

            <div className="form-group row"><label className="col-lg-6 col-form-label">Depth</label>
              <div className="col-lg-6">

                <div className="input-group m-b">
                  <input
                    type="text"
                    placeholder="depth"
                    value={depth}
                    onChange={(e) => depthChanged(e.target.value)}
                    className="form-control" />
                  <div className="input-group-prepend">
                    <span className="input-group-addon">cm</span>
                  </div>
                </div>

                <span className="form-text text-danger m-b-none">{depthError}</span>

              </div>

            </div>
            <div className="form-group row"><label className="col-lg-4 col-form-label">Shipping Marks</label>
              <div className="col-lg-8">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="shipping-marks-label">Shipping marks</InputLabel>
                  <Select
                    labelId="shipping-marks-label"
                    id="shipping-marks"
                    multiple
                    value={shippingMark}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Shipping marks" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value.id} label={value.name} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {shippingMarks.map((mark) => (
                      //@ts-ignore
                      <MenuItem
                        key={mark.id}
                        value={mark}
                        style={getStyles(mark.name, shippingMark, theme)}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <span>{mark.name}</span>
                          <img width="18px" height="24px" src={mark.url} alt={mark.name}></img>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="primary" onClick={() => submitForm()} disabled={!isValid} >
            Add
          </Button>

        </Modal.Footer>
      </Modal >
    </div >
  )
}
export default AddPackingItemButton
