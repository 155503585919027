import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { Button } from 'react-bootstrap';
import Good from './Good';
import DefineProdButton from './DefineProd'
import { TextField } from '@mui/material';
//@ts-ignore
import _ from "lodash";

import { int, GoodData, NoteData, ProductData } from '../Common/types'
import { FormatPrice } from '../Common/functions';

export const PRODUCT_FIELD_INDEX = 1
export const QTY_FIELD_INDEX = 2

export const SUPPLIER_FIELD_INDEX = 6
export const LOCATION_FIELD_INDEX = 7

interface NoteProps {
  note: NoteData,
  readonly: boolean,
  type: "Inv" | "R" | "I" | "?"
}

export interface ICursorCords {
  col: int,
  row: int
}

const newEmptyGood: GoodData = {
  id: 0,
  product: { id: 0, name: '' },
  qty: 1,
  unit: "pcs",
  price: 0,
  vat: 21,
  supplier: { id: 0, name: '' },
  location: ""
}

const Note = (props: NoteProps) => {

  const
    [note, setNote] = useState(props.note),
    [goods, setGoods] = useState(props.note.goods),

    [actionDate, setActionDate] = useState(props.note.action_date),
    [totalPrice, setTotalPrice] = useState(0),

    [cursorCoords, setCursorCoords] = useState<ICursorCords>({ row: 0, col: 0 }),

    documentNrChanged = val => {
      setNote(old => ({ ...old, document_nr: val }))
    };


  function updateGoodAt(index: int, good: GoodData, sourceCol: int) {
    const newGoods = [...goods.slice(0, index), good, ...goods.slice(index + 1)];
    setGoods(newGoods)

    if (sourceCol === PRODUCT_FIELD_INDEX) {
      setCursorCoords({ row: index, col: QTY_FIELD_INDEX })
    }
    else if (sourceCol === SUPPLIER_FIELD_INDEX) {
      setCursorCoords({ row: index, col: LOCATION_FIELD_INDEX })
    }
  }

  function addGood() {
    const newRowsIx = goods.length;
    setGoods(old => ([...old, newEmptyGood]))
    setCursorCoords({ row: newRowsIx, col: 0 })
  }

  function removeGood(index: number) {
    setGoods(goods.map((line, ix) => ix == index ? { ...line, destroy: 1 } : line))
  }

  function calcTotalPrice(): number {
    const filtered: GoodData[] = goods.filter(g => !g.destroy || g.destroy == 0);
    return filtered.map(g => Number(g.price)).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

  }
  function groupedUnits(): any {
    return goods.filter(g => !g.destroy || g.destroy == 0).groupBy("unit")
  }

  useEffect(() => {
    setTotalPrice(calcTotalPrice());
  }, [goods])

  useEffect(() => {
    if (props.note.id === null) {
      addGood();
    }
  }, []);

  let qtySummary = Object.entries(groupedUnits()).map((u: [string, unknown]) => {
    const qtySum = typeof u[1] == 'object' && Array.isArray(u[1]) ? u[1].map(g => Number(g.qty)).reduce((prev, curr) => {
      return prev + curr;
    }, 0) : 0
    return (
      <React.Fragment key={u[0]}>{qtySum} {u[0]}<br /></React.Fragment>
    )
  })

  let visibleItems = goods.map((n, index) => (
    <Good
      // updateTotalPrice={updateTotalPrice}
      key={(n.product ? n.product.id : ' ') + ' ' + index}
      index={index}
      removeHandler={removeGood}
      good={n}
      readonly={props.readonly}
      lastRow={index === goods.length - 1}
      updateGoodAt={updateGoodAt}
      cursorCords={cursorCoords}
      type={props.type}
      addGood={addGood}
    />
  ))

  return (
    <div className="wrapper wrapper-content">
      <div className="row">
        <div className="col-lg-12 ibox-content animated fadeInRight">

          <div className="row mt-2">
            <div className="col-12 col-md-12">

              <div className="row mt-2">
                <div className="col-6 col-lg-3"><strong>Document nr</strong>
                </div>
                <div className="col-6">
                  {props.readonly && note.document_nr}
                  {!props.readonly &&
                    <TextField
                      name='note[document_nr]'
                      placeholder="Location"
                      value={note.document_nr}
                      onChange={e => documentNrChanged(e.target.value)}
                    />
                  }

                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6 col-lg-3" data-testid="note-typ"><strong>{note.typ === 'R' ? 'Receiver' : 'Issuer'}</strong>
                </div>
                <div className="col-6">
                  {props.readonly && note.user_nice_name}
                  {!props.readonly && <TextField
                    placeholder="User"
                    disabled={true}
                    value={note.user_nice_name}
                    onChange={() => { }}
                  />}

                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6 col-lg-3"><strong> {note.typ === 'R' ? 'Receiving' : 'Issuing'}  Date</strong>
                </div>
                <div className="col-6 col-lg-3">
                  {props.readonly && note.action_date}
                  {!props.readonly &&
                    <TextField
                      type="date"
                      name='note[action_date]'
                      value={actionDate}
                      onChange={e => setActionDate(e.target.value)}
                    />
                  }
                </div>
              </div>

            </div>
          </div>
          <div role="goodsinfo">
            {visibleItems}
          </div>
          <hr />
          <div className="row">
            <div className="col-xl-2 col-md-12">&nbsp;</div>
            <div className="col-12 col-md-12 col-xl-2">&nbsp;</div>
            <div className="col-8 col-md-8 col-xl-2" style={{ fontSize: 14, textAlign: 'center' }}>{qtySummary}</div>
            <div className="col-4 col-md-4 col-xl-1 mt-2">
              {!props.readonly ?
                <TextField
                  disabled
                  name="total_price"
                  label="Total price"
                  value={totalPrice}
                />
                :
                FormatPrice(totalPrice)}
            </div>
            <div className="col-4 col-md-4 col-xl-1">&nbsp;</div>
            <div className="col-12 col-md-12 col-xl-2">&nbsp;</div>
            <div className="col-12 col-md-12 col-xl-1">&nbsp;</div>
            <div className="col-12 col-md-12 col-xl-1">&nbsp;</div>
          </div>


        </div>
        <div className="row mt-2" >
          <div className="col-md-12"  >

            {
              !props.readonly &&
              <Button variant="primary" onClick={() => addGood()}>+Add row</Button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Note

declare global {
  interface Array<T> {
    groupBy: (category: string) => {};
  }

}

Array.prototype.groupBy = function (category: string) {
  return _.mapValues(_.groupBy(this, category),
    arrList => arrList.map(arr => _.omit(arr, category)));

}
