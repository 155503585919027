import React from 'react'
import * as i18n from "i18n-js"
import { ProjectData } from '../Common/types';
import { customerLink, orderLink, projectLink, customSym, stockSym, truckLink, projectState } from '../Common/functions';

declare function formatMoney(number: number, decPlaces: number, decSep: string, thouSep: string): string;

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface ListItemProps {
  project: ProjectData
}

const ListItem = (props: ListItemProps) => {


  return (
    <tr className={`deadline2-${props.project.deadline_color}`}>
      <td>
        {props.project.visible_id}
      </td>
      <td>
        {stockSym(props.project)} {customSym(props.project)} {projectLink(props.project, false, true)}
      </td>
      <td>
        {orderLink(props.project.order, props.project.order_visible_id)}
      </td>
      <td>
        {truckLink(props.project.truck)}
      </td>
      <td className="text-right">
        {formatMoney(props.project.price, 0, ".", ",")}
      </td>
      <td>
        {props.project.order_date}
      </td>
      <td>
        {props.project.deadline}
      </td>
      <td>
        {props.project.code}
      </td>
      <td>
        {customerLink(props.project.customer)}
      </td>
      <td>
        {props.project.sales_manager}
      </td>
      <td>{projectState(props.project)}</td>
      <td>
        <a href={`/projects/${props.project.id}`} className="btn btn-primary">Show</a>
      </td>
    </tr >

  )
}

export default ListItem
