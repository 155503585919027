import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import { ProjectData, PriceChangeData } from '../Common/types';
import toastr from 'toastr'

declare function formatMoney(number: number, decPlaces: number, decSep: string, thouSep: string): string;

export interface ChangePriceButtonProps {
  project_id: number,
  project_state: string,
  project_total_price: number
}

const ChangePriceButton = (props: ChangePriceButtonProps) => {

  const [show, setShow] = React.useState(false),
    [amount, setAmount] = React.useState(props.project_total_price),
    [diff, setDiff] = React.useState(0),
    [message, setMessage] = React.useState(''),
    [comment, setComment] = React.useState(''),
    [priceChanges, setPriceChanges] = useState(new Array<PriceChangeData>());


  function amountChanged(newVal: number): void {
    setAmount(newVal)
    if (isNaN(newVal)) {
      setDiff(0)
    }
    else {
      setDiff(newVal - Number(props.project_total_price))
    }
  }

  function loadChanges() {
    Axios({
      method: 'GET',
      url: `/price_changes.json?project_id=${props.project_id}`,
      headers: {
        accept: 'application/json'
      }
    })
      .then((res) => {
        setPriceChanges(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    loadChanges()
  }, [])

  useEffect(() => {
    if (priceChanges.length > 0) {
      const b = Number(props.project_total_price)
      const c = Number(priceChanges[0].amount)
      setAmount(b + c)
      setDiff(c)
    }

  }, [priceChanges])


  function closeClicked(): void {
    setShow(false);
  }


  function submitForm(): void {

    Axios({
      method: "POST",
      url: `/price_changes.json`,
      data: { price_change: { project_id: props.project_id, amount: diff, comment: comment, message: message } },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        if (res.status === 200) {
          // build new record and append to priceChanges
          toastr.success("Success", "New price saved")
          setShow(false);
        }
        else {
          toastr.error("Truck is NOT closed", res.data['errors'])
        }

      })
      .catch((err) => {
        console.log(err)
      })


  }

  const base_proj_price = formatMoney(props.project_total_price, 2, ".", ",")

  return (
    <div>

      <h2>
        Price:
        {diff == 0.0 && <span>€{base_proj_price}</span>}
        {diff != 0.0 &&
          <div>
            <s> €{base_proj_price}</s> &nbsp;&nbsp;&nbsp;
            <span>€{formatMoney(amount, 2, ".", ",")}</span>
          </div>
        }
      </h2>

      <Button className="btn btn-primary btn-lg" disabled={props.project_state == 'delivered' || props.project_total_price == 0.0} onClick={() => setShow(true)}>
        ChangePrice
      </Button>

      <Modal show={show} >
        <Modal.Header closeButton>
          <Modal.Title>
            Enter new price
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12s">
              <h2>
                Price:
                {diff == 0.0 && <span>€{base_proj_price}</span>}
                {diff != 0.0 &&
                  <div>
                    <s> €{base_proj_price}</s> &nbsp;&nbsp;&nbsp;
                    <span>€{formatMoney(amount, 2, ".", ",")}</span>
                  </div>
                }
              </h2>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-12">
              <form className="form-horizontal">

                <div className="form-row">
                  <div className="form-group col-6">
                    <label>New price</label>

                    <div className="input-group m-b">
                      <div className="input-group-prepend">
                        <span className="input-group-addon">€</span>
                      </div>
                      <input type="text" name="amount" className="form-control" value={amount}
                        onChange={(e) => amountChanged(Number(e.target.value))} />
                    </div>

                  </div>
                  <div className="form-group col-6">
                    {diff != 0.0 && <label>Difference</label>}
                    <br />
                    {diff < 0 && <span className="negative-value">{formatMoney(diff, 2, ".", ",")}</span>}
                    {diff > 0 && <span className="positive-value">+{formatMoney(diff, 2, ".", ",")}</span>}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-6">
                    <label>Comment </label>
                    <textarea rows={4} required={true} className="form-control" value={comment}
                      onChange={(e) => setComment(e.target.value)}>
                    </textarea>
                  </div>
                  <div className="form-group col-6">
                    <label>Message <span title="Required">*</span></label>
                    <textarea rows={4} className="form-control" value={message}
                      onChange={(e) => setMessage(e.target.value)}>
                    </textarea>
                  </div>
                </div>
              </form>


            </div>
          </div>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={closeClicked}>
            Cancel
          </Button>

          <Button variant="primary" disabled={diff == 0 || message == ""} onClick={() => submitForm()}>
            Save
          </Button>

        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ChangePriceButton
