import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import Axios from 'axios'
import toastr from 'toastr'
import * as i18n from "i18n-js"
import PackingItem from "./PackingItem"
import { PackingItemData } from "../Common/types"
import AddPackingItemButton from './AddPackingItemButton'
import _ from "lodash";


export interface PackingItemListProps {
  project_id: number,
  continuation_of_id: number,
  continuation_id: number,
  pack_finished: boolean,
}
//@ts-ignore
i18n.translations = I18n.translations || {};

const PackingItemList = (props: PackingItemListProps) => {

  const
    isContinuation = (props.continuation_of_id !== undefined && props.continuation_of_id != null),
    [packFinished, setPackFinished] = useState(props.pack_finished),
    [packingItems, setPackingItems] = useState(new Array<PackingItemData>()),
    [sackedItems, setSackedItems] = useState(new Array<PackingItemData>()),
    handleTrashClick = (item: PackingItemData) => {
      if (window.confirm(i18n.t('are_you_sure'))) destroyItem(item, 'delete')
    },
    handleSackClick = (item: PackingItemData) => {
      if (window.confirm(i18n.t('are_you_sure'))) destroyItem(item, 'sack')
    },
    handleUnsackClick = (item: PackingItemData) => {
      if (window.confirm(i18n.t('are_you_sure'))) destroyItem(item, 'unsack')
    },
    handleDoneClick = (item: PackingItemData) => {
      if (window.confirm(i18n.t('are_you_sure'))) doneItem(item)
    },
    printLabelsClicked = () => {
      window.open(`/projects/${props.project_id}/labels.pdf`);
    };

  function togglePackingState(): void {
    const newState = !packFinished
    setPackFinished(newState)

    const msg = (newState ? 'Packing is closed' : 'Packing is back open')

    Axios({
      method: 'POST',
      url: `/projects/${props.project_id}/change_packing_state`,
      data: { project: { pack_finished: newState } },
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        // toastr.success(msg, i18n.t('success'))
        window.location.reload()
      })
      .catch((err) => {
        toastr.error(err.response.data.errors, i18n.t('error'))
      })
  }

  function doneItem(item: PackingItemData): void {

    Axios({
      method: 'POST',
      url: `/projects/${item.project_id}/packing_items/${item.id}/done`,
      data: { id: item.id, project_id: item.project_id },
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        toastr.success(res.data.message, i18n.t('success'))
        loadItems();
      })
      .catch((err) => {
        toastr.error(err.response.data.errors, i18n.t('error'))
      })
  }

  function destroyItem(item: PackingItemData, operation: 'delete' | 'sack' | 'unsack'): void {
    Axios({
      method: 'DELETE',
      url: `/projects/${props.project_id}/packing_items/${item.id}.json`,
      data: { operation: operation },
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        if (res.data.status == 'ok') {
          toastr.success(res.data.message, i18n.t('success'))

          loadItems();

        } else {
          toastr.error(res.data.message, i18n.t('error'))
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  function loadItems() {

    let promise1 = new Promise<PackingItemData[]>(function (resolve, reject) {
      Axios({
        method: 'GET',
        url: `/projects/${props.project_id}/packing_items.json`,
        headers: {
          accept: 'application/json'
        }
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })

    });


    let promise2 = new Promise<PackingItemData[]>(function (resolve, reject) {
      Axios({
        method: 'GET',
        url: `/projects/${props.project_id}/packing_items.json?continuation=1`,
        headers: {
          accept: 'application/json'
        }
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })

    });

    Promise.all<PackingItemData[]>([promise1, promise2]).then((values) => {
      if (isContinuation && values[0].length == 0) {
        window.location.href = `/projects/${props.continuation_of_id}`
      }
      else {
        setPackingItems(values[0])
        setSackedItems(values[1])
      }
    });
  }

  useEffect(() => {
    loadItems();
  }, [])


  const items = packingItems.map((n, index) => (
    <PackingItem
      // key={n.id}
      // key must be random, because not re-rendering packing items after reload items
      key={`${_.uniqueId("id-")}`}
      item={n}
      pack_finished={packFinished}
      handleTrashClick={handleTrashClick}
      handleSackClick={isContinuation ? handleUnsackClick : handleSackClick}
      sack_message={isContinuation ? "Bring back to main project" : "Move into Continuation project"}
      position={index + 1}
      total_packs={packingItems.length}
      handleDoneClick={handleDoneClick}
    />
  ))

  const sacked_items = sackedItems.map((n, index) => (
    <PackingItem
      // key={n.id}
      // key must be random, because not re-rendering packing items after reload items
      key={`${_.uniqueId("id-")}`}
      item={n}
      pack_finished={packFinished}
      handleTrashClick={handleTrashClick}
      handleSackClick={handleUnsackClick}
      sack_message="Bring back to main project"
      position={index + 1}
      total_packs={packingItems.length}
      handleDoneClick={handleDoneClick}
    />
  ))

  const totalWeight = packingItems.reduce((sum, current) => sum + current.weight, 0);

  const totalWeightRounded = Number(totalWeight.toFixed(3));

  return (
    <div className="wrapper wrapper-content">
      <div className="row">
        <div className="col-lg-12 animated fadeInRight">
          <div>
            <div className="pull-left">

              {!packFinished &&
                <button onClick={(e) => togglePackingState()}
                  className="btn btn-primary"
                  title={'Finish packaging'}
                  disabled={packFinished}
                > <i className="fa fa-unlock"></i> Finish
                </button>
              }

              {packFinished &&
                <button onClick={() => togglePackingState()}
                  disabled={sackedItems.length > 0}
                  className="btn btn-danger"
                  title={'Unlock packaging and continue'}
                > <i className="fa fa-lock"></i> Unlock
                </button>
              }

              {packFinished && sackedItems.length > 0 &&
                <i className="fa fa-exclamation-circle text-warning" title="You are not allowed to Unlock if continuation exist"></i>
              }

            </div>

            <div className="pull-right">
              <AddPackingItemButton pack_finished={packFinished} project_id={props.project_id} onItemAdded={() => loadItems()} />
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="text-right">Length (cm)</th>
                  <th className="text-right">Height (cm)</th>
                  <th className="text-right">Depth (cm)</th>
                  <th className="text-right">Weight (kg)</th>
                  <th className="text-right">Shipping marks</th>
                </tr>
              </thead>
              <tbody>
                {items}

                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className="text-right">{totalWeightRounded}</th>
                  <th></th>
                  <th></th>
                </tr>
                {sacked_items.length > 0 && <tr><th colSpan={5}>CONTINUATION</th></tr>}
                {sacked_items}
              </tbody>
            </table>


            <button disabled={!props.pack_finished} className="btn btn-info" onClick={() => printLabelsClicked()}  >
              Labels (PDF)
            </button> &nbsp;&nbsp;
            {!props.pack_finished && <span title="Finish packaging first before you print labels"><i className="fa fa-exclamation-circle text-warning"></i></span>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default PackingItemList
