import React, { useState } from "react";
import { CustomerData } from "../Common/types";
import TypeSelectorPlanner, {
  SelectorPlannerData,
} from "./TypeSelectorPlanner";
import axios from "axios";
import toastr from "toastr";
import Rails from "@rails/ujs";

interface PriceAndTypeBoxProps extends SelectorPlannerData {
  dest: string;
  transportCost: string;
  customer?: CustomerData;
  nameOrEmail: any;
  transportCostSymbol: string;
  customersAndTransportCosts: {
    customer: CustomerData;
    transportCost: string;
    nameOrEmail: any;
    id: number;
  }[];
}

interface customersTransportCosts {
  id: number;
  transportCost: number;
}

const PriceAndTypeBox = (props: PriceAndTypeBoxProps) => {
  const defaultPrices = props.customersAndTransportCosts.map((c) => {
    return {
      id: c.id,
      transportCost: Number(c.transportCost),
    };
  });


  const [edited, setEdited] = useState(false),
    [transportCosts, setTransportCosts] =
      useState<customersTransportCosts[]>(defaultPrices),
    [selectedType, setSelectedType] = useState<string>(props.typ)

  const revertChanges = () => {
    setTransportCosts(defaultPrices);
    setEdited(false);
  };

  const replaceTransportCosts = (value: number, id: number) => {
    const newState = transportCosts.map((obj) => {
      if (obj.id === id) {
        return { ...obj, transportCost: value };
      }
      return obj;
    });

    setTransportCosts(newState);
  };

  const patchTransportCost = () => {
    // axios.patch(`/trucks/${props.id}.json`, {typ: val.value}, {
    //   headers: {
    //     "X-CSRF-Token": Rails.csrfToken(),
    //     "Content-Type": "application/json"
    //   }}
    // ).then(() => {
    //   toastr.success("Success", "New truck type saved");
    //   props.history.push("/trucks/"+ props.id +"/"+ val.value)
    // })
    axios
      .patch(
        `/trucks/${props.id}.json`,
        {
          truck: {
            truck_loads_attributes: transportCosts.map((tc) => {
              return { id: tc.id, transport_cost: tc.transportCost };
            }),
            typ: selectedType,
          },
        },
        {
          headers: {
            "X-CSRF-Token": Rails.csrfToken(),
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        toastr.success("New truck transport cost saved", "Success");
        setEdited(false);
        window.location.assign(`/trucks/${props.id}/${selectedType}`)
      })
      .catch((err) => {
        if (err.response) {
          const data = Object.keys(err.response.data).map(key => err.response.data[key]).flat().join("\n")
          toastr.error(data, "Error");
        }
        console.log("ERROR: ", err.response)
      });
  };

  return (
    <div className="ibox-content">
      <div className="float-right">
        {!!edited && (
          <>
            <button className="btn p-0 mr-4" onClick={() => revertChanges()}>
              <i className="fa fa-undo" aria-hidden="true"></i>
            </button>
            <button
              className="btn p-0 mr-4"
              onClick={() => patchTransportCost()}
            >
              <i className="fa fa-floppy-o" aria-hidden="true"></i>
            </button>
          </>
        )}
        {!edited && (
          <button className="btn p-0" onClick={() => setEdited(true)}>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
        )}
      </div>
      <div className="form-group row">
        <label className="col-lg-4 ">Destination</label>
        <div className="col-lg-8">{props.dest}</div>
      </div>
      {props.customersAndTransportCosts.map((truck_load) => {
        return (
          <>
            <div className="form-group row mb-1">
              <label className="col-lg-4 ">Customer</label>
              <div className="col-lg-8">
                {!!truck_load.customer && (
                  <div
                    dangerouslySetInnerHTML={{ __html: truck_load.nameOrEmail }}
                  />
                )}
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-lg-4 ">Transport cost</label>
              <div className="col-lg-8">
                {edited ? (
                  <div className="input-group truck_transport_cost">
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      value={Number(
                        transportCosts.find((tc) => tc.id === truck_load.id)
                          ?.transportCost
                      )}
                      id="truck_transport_cost"
                      onChange={(e) =>
                        replaceTransportCosts(
                          +e.target.value.replace(",", "."),
                          truck_load.id
                        )
                      }
                    />
                    <div className="input-group-append">
                      <span className="input-group-addon">Eur</span>
                    </div>
                  </div>
                ) : (
                  `${
                    transportCosts.find((tc) => tc.id === truck_load.id)
                      ?.transportCost
                  } ${props.transportCostSymbol}`
                )}
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #e7eaec" }} className="my-2" />
          </>
        );
      })}
      <div className="form-group row">
        <label className="col-lg-4 ">Sum costs</label>
        <div className="col-lg-8">
          {`${transportCosts.reduce((accumulator, currentValue) => accumulator + currentValue.transportCost, 0)} ${props.transportCostSymbol}`}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-4 ">Type</label>
        <div className="col-lg-8">
          {edited ? (
            <TypeSelectorPlanner
              view="selector"
              id={props.id}
              typ={props.typ}
              setSelectedType={setSelectedType}
            />
          ) : (
            <TruckLabel typ={props.typ} />
          )}
        </div>
      </div>
    </div>
  );
};

const TruckLabel = (props: {
  typ: "truck_90" | "truck_120" | "container_20" | "container_40";
}) => {
  switch (props.typ) {
    case "truck_90":
      return (
        <label className="form-check-label">
          Truck 90m3 <i className="fa fa-truck mr-1"></i>
        </label>
      );
    case "truck_120":
      return (
        <label className="form-check-label">
          Truck 120m3 <i className="fa fa-truck mr-1"></i>
        </label>
      );
    case "container_20":
      return (
        <label className="form-check-label">
          Container 20 <i className="fa fa-archive mr-1"></i>
        </label>
      );
    case "container_40":
      return (
        <label className="form-check-label">
          Container 40 <i className="fa fa-archive mr-1"></i>
        </label>
      );
    default:
      return <></>;
  }
};
export default PriceAndTypeBox;
