import { ISelectOption } from './types';

export class URLParamsReader {
  private urlParams: URLSearchParams;

  constructor(search: string, defaultSearch: string = '') {
    this.urlParams = search
      ? new URLSearchParams(search)
      : new URLSearchParams(defaultSearch);
  }

  getRows(): number {
    const result = parseInt(this.urlParams.get('rows'));
    return result > 0 && [10, 20, 40, 60, 80].indexOf(result) > -1
      ? result
      : 20;
  }

  getSidx(defaultVal: string): string {
    const result = this.urlParams.get('sidx');
    //    return (['sent_at', 'read_at', 'subject'].indexOf(result) > -1) ? result : 'sent_at'
    return result ? result : defaultVal;
  }

  getSord(): string {
    const result = this.urlParams.get('sord');
    return ['desc', 'asc'].indexOf(result) > -1 ? result : 'desc';
  }

  getPage(): number {
    const result = parseInt(this.urlParams.get('page'));
    return result > 0 ? result : 1;
  }

  getISelect(name: string, defaultVal: ISelectOption = null): ISelectOption {
    const txt = this.urlParams.get(name);
    if (!txt) {
      return defaultVal;
    }
    return this.parseISelect(txt);
  }

  getTaskTypeISelect(
    name: string,
    defaultVal: ISelectOption = null
  ): ISelectOption {
    const txt = this.urlParams.get(name);
    if (!txt) {
      return defaultVal;
    }
    const arr = txt.split(',');
    const label = arr.pop();
    return { label: label, value: arr.join(',') };
  }

  private parseISelect(txt: string): ISelectOption {
    const arr = txt.split(',');
    return arr.length > 1 ? { label: arr[1], value: arr[0] } : null;
  }

  getISelectArray(name: string): ISelectOption[] {
    const txt = this.urlParams.get(name);
    if (!txt) {
      return [];
    }
    const arr = txt.split(':');
    const result = arr.map((el) => this.parseISelect(el));
    return result;
  }

  getString(name: string, defaultValue: string = ''): string {
    const result = this.urlParams.get(name);
    return result ? result : defaultValue;
  }

  getInt(name: string): number {
    return parseInt(this.urlParams.get(name));
  }
}
