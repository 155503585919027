

import * as React from "react"
import { Provider } from "react-redux"
import { loadProjects, loadTrucks, VenueState, VenueAction, venueStore } from "./AppReducer"
import TaskColumn from "./TaskColumn"
import Filters from "./Filters"
import { ISelectOption, ROLES_LIST, TruckData } from "../Common/types";
import { hasAnyRole } from "../Common/functions"

export interface IsVenueContext {
  state: VenueState
  dispatch: React.Dispatch<VenueAction>
}

interface ProductionQueueProps {
  roles: string[]
}

export const STATUSES = ['confirmed', 'waiting', 'in_production', 'ready_for_delivery'];

export const VenueContext = React.createContext<IsVenueContext>(null)

class App extends React.Component<ProductionQueueProps> {

  constructor(props) {
    super(props);
    this.state = {
      truck: null,
      project_dragged_dropped: false,
      columns: STATUSES
    };
  }

  onTruckChange(newTruck: TruckData) {
    if (this.state['project_dragged_dropped']) {
      console.log('Hard reload')
      window.location.reload();
    }
    else {
      console.log('Soft reload')
      this.setState({ ... this.state, truck: newTruck });
      loadProjects();
      loadTrucks();
    }
  }

  projectMoved(event, ui) {
    //it is Jquery function, defined in application.js
    //@ts-ignore
    production_queue_project_moved(event, ui);
    // console.log('Drag & drop made')
    this.setState({ ... this.state, project_dragged_dropped: true });
  }

  handleChangeColumns = (items: ISelectOption[]) => {
    const itemsColumns = items.map(i => i.value);
    this.setState({ columns: itemsColumns })
  }

  calculateColWidth = () => {
    return Math.ceil(12 / (this.state["columns"].length || 1))
  }

  componentDidMount() {
    loadProjects();
    loadTrucks();
    if (hasAnyRole(this.props.roles, [])) {
      //@ts-ignore
      $("#confirmed, #waiting, #in_production, #ready_for_delivery").sortable({ connectWith: ".connectList", receive: (event, ui) => $(".agile-list").map(function() {$(this).css("min-height", "")}) , update: (event, ui) => this.projectMoved(event, ui) }).disableSelection();
    }
  }

  render() {
    const columns = this.state['columns'].map(
      row => {
        return <div key={row} className={`col-lg-${this.calculateColWidth()}`}>
          <TaskColumn state_name={row} onTruckChange={(newtruck) => this.onTruckChange(newtruck)} />
        </div>
      }
    )

    return (
      <Provider store={venueStore} >

        <Filters changeColumns={this.handleChangeColumns}></Filters>

        <div className="wrapper wrapper-content  animated fadeInRight" id="agile_list">
          <div className="row">
            {columns}
          </div>
        </div>


      </Provider>
    );
  }

}

export default App;



