import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { int, SupplierData } from '../../Common/types';
import axios from 'axios';

interface SupplierSelectProps {
  index: number,
  htmlFieldName: string,
  supplier: SupplierData,
  error: boolean,
  helperText: string,
  defineNewHandler: (index: int) => void,
  changeHandler: (item: SupplierData) => void,
  defineOnBlur: () => void
}

const filter = createFilterOptions<SupplierData>();

export default function SupplierSelect(props: SupplierSelectProps) {
  // const mountedRef = React.useRef(true);

  const
    [value, setValue] = React.useState(props.supplier),
    [inputValue, setInputValue] = React.useState(''),
    [suppliers, setSuppliers] = React.useState(new Array<SupplierData>(props.supplier)),
    [isLoading, setIsLoading] = React.useState(false),

    handleChange = (item: SupplierData) => {
      if (item && item.id == 0) {
        props.defineNewHandler(props.index)
      }
      else if (item) {
        // setValue(item);
        props.changeHandler(item)
      }
    },

    getSuppliers = () => {
      setIsLoading(true);
      axios({
        method: 'GET',
        url: `/suppliers.json?${inputValue.length > 0 ? `&q=${inputValue}` : ''}`,
        headers: {
          accept: 'application/json'
        }
      })
        .then((res) => {
          setIsLoading(false);
          if (res.data.length > 0) {
            setSuppliers(res.data);
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }

  React.useEffect(() => {

    if (inputValue && inputValue.length > 2) {
      getSuppliers()
    }

  }, [inputValue]);

  // React.useEffect(() => {
  //   return () => {
  //     mountedRef.current = false
  //   }
  // }, [])

  return (
    <div>
      <input type="hidden" name={props.htmlFieldName} value={value.id} />
      <Autocomplete
        onBlur={props.defineOnBlur}
        options={suppliers}
        getOptionLabel={(option) => (option ? option.name : '')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        includeInputInList
        filterSelectedOptions
        filterOptions={(options, params) => {

          if (params.inputValue !== '') {
            const filtered = filter(options, params);
            filtered.push({
              id: 0,
              name: "Add new"
            });
            return filtered;
          } else {
            return options
          }

        }}
        value={props.supplier}
        onChange={(event: any, newValue: any) => {
          handleChange(newValue);
        }}
        onInputChange={(e, newInputValue, reason) => {
          // console.log('onInputChange ', e && e.constructor.name);
          // e.constructor.name is 't' on production ??? Why???
          // e && e.constructor.name === 'SyntheticBaseEvent' &&
          // console.log('XXXXXXXXXX', newInputValue, reason)
          setInputValue(newInputValue)
          if (reason === 'clear') {
            const emptySup = { id: 0, name: '' };
            setValue(emptySup);
            props.changeHandler(emptySup)
          }
        }
        }
        renderInput={(params) => (
          <TextField {...params}
            error={props.error}
            helperText={props.helperText}
            label="Supplier" fullWidth />
        )}
        renderOption={(props, option) => {

          return (
            <li {...props} key={option.id} value={option.id}>
              {option.name}
            </li>
          );
        }}


      />
    </div>
  );



}
