import React from 'react'
import PlannerMenu from './PlannerMenu'

export interface PlannerMenuRouterProps {
  truck_id: string,
  typ: "truck_90" | "truck_120" | "container_20" | "container_40",
  history: any;
}

const PlannerMenuRouter = (props: PlannerMenuRouterProps) => {
  return (
    <div>
        {/* <BrowserRouter> */}
            <PlannerMenu history={props.history} truck_id={props.truck_id} typ={props.typ} />
        {/* </BrowserRouter> */}
    </div>
  )
}

export default PlannerMenuRouter
