import React from 'react';
import { SearchContext } from './List';

interface HeaderSortableColumnProps {
  column: string;
  label: string;
}

const HeaderSortableColumn = (props: HeaderSortableColumnProps) => {
  const searchContext = React.useContext(SearchContext);

  return (
    <th
      className="mail-subject footable-visible footable-sortable"
      onClick={() => searchContext.sortBy(props.column)}
    >
      {props.label}
      <span>
        {' '}
        &nbsp;&nbsp;&nbsp;
        <i className={searchContext.getSortIcon(props.column)}></i>
      </span>
    </th>
  );
};

export default HeaderSortableColumn;
