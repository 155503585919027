import { ProductData } from "../../Common/types";
import { SearchProductData } from "./ProductField";

export function fieldName(index, attr) {
  return (`note[goods_attributes][${index}][${attr}]`)
}


export function productName(product: ProductData) {
  const n = product.name
  const v = product.variant_label;

  return (v ? n + " - " + v : n)
}


export function convertToSearchProduct(prod: ProductData): SearchProductData {
  return {
    id: prod.id,
    name: prod.name,
    barcode: prod.barcode,
    variant_label: prod.variant_label,
    unit_sym: prod.unit_sym,
    invoice_code: prod.invoice_code,
    unit_weight: prod.unit_weight.toString(),
    on_stock: prod.on_stock,
    url: prod.url,
    image: prod.image,
    price: 0,
    vat: 0,
    supplier: { id: 0, name: '' },
    location: ""
  }
}
