import { CheckBox, Link } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Chip } from '@mui/material';
import * as React from 'react';
import { useState } from "react"
import { int, OperatorshipData } from '../Common/types';
import OperatorAutoComplete from "./OperatorAutoComplete"
import OperatorField from "./OperatorField"

interface OperatorsSectionProps {
  operatorships: OperatorshipData[]
}

export default function OperatorsSection(props: OperatorsSectionProps) {
  const [operators, setOperators] = useState(props.operatorships);
  const [open, setOpen] = useState(false);

  const removeOperator = (index: int) => {
    const rows = [...operators];
    if (rows[index].id) {
      rows[index]['_destroy'] = true
    } else {
      rows.splice(index, 1);
    }
    setOperators(rows);
  }

  const addOperator = (user: OperatorshipData) => {
    setOperators([...operators, user]);
  }

  const handleTempAwayChange = (index: int, isChecked: boolean) => {
    const rows = [...operators];
    rows[index].temp_away = isChecked;
    setOperators(rows);
  }

  return <div className="container">
    <div className="row">
      <div className="col-sm-8">
        {
          operators.map((operator, index) =>
            <OperatorField
              key={operator.user_id ? operator.user_id : `index_${index}`}
              index={index}
              operatorship={operator}
              tempAwayClicked={handleTempAwayChange}
              removeOperator={removeOperator}
            />
          )
        }
        <div className="row">
          <div className="col-sm-12 col-md-6">

            {!open &&
              <Button variant="outlined" onClick={(ev) => setOpen(true)}>Add New</Button>}

            {open &&
              <OperatorAutoComplete
                clearClicked={() => setOpen(false)}
                changeHandler={(item: OperatorshipData) => addOperator(item)} />}

          </div>
          <div className="col-sm-12 col-md-6">
            {open &&
              <a href="#" onClick={() => setOpen(false)} >Cancel</a>
            }
          </div>
        </div>
      </div>
    </div>
    <div className="col-sm-4">
    </div>
  </div>


}
