
import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { colorTaskStatus } from '../Common/functions';
import { ISelectOption } from '../Common/types';


interface StatusFieldProps {
  status: string,
  taskId: number,
  onChange: (taskId: number, opt: ISelectOption) => void
}

export const AllProgressStatuses: ISelectOption[] = [
  { value: 'todo', label: 'TODO' },
  { value: 'in_progress', label: 'IN PROGRESS' },
  { value: 'waiting', label: 'WAITING' },
  { value: 'done', label: 'DONE' }]


const StatusField = (props: StatusFieldProps) => {

  const [status, setStatus] = useState<ISelectOption>({
    label: props.status.toUpperCase(),
    value: props.status
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChange(opt: ISelectOption) {
    setStatus(opt);
    props.onChange(props.taskId, opt)
    handleClose()
  }

  React.useEffect(() => {
    setStatus({
      label: props.status.toUpperCase(),
      value: props.status
    })
  }, [props.status])

  return (


    <>
      <a href="#" onClick={handleClick} > {colorTaskStatus(status.value)} </a>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        anchorReference={'anchorEl'}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          AllProgressStatuses.map((ps) =>
            <MenuItem key={ps.value} onClick={() => handleChange(ps)}>{colorTaskStatus(ps.value)}</MenuItem>
          )
        }
      </Menu>
    </>


  )
}

export default StatusField
