import React, { useEffect, useCallback, useState, useRef } from 'react'

import axios from 'axios';
import { int, ProductData, SupplierData } from '../../Common/types';
import ProductSelect from "./ProductSelect"
import { convertToSearchProduct, fieldName } from './CommonFuncs';
import { productName } from './CommonFuncs';
import DefineProd from '../DefineProd';

export const PRODUCT_SEARCH_AFTER_CHAR = 1;

export interface SearchProductData {
  id: number;
  barcode: string;
  name: string;
  variant_label: string;
  unit_sym: string;
  invoice_code: string;
  unit_weight: string;
  price: number;
  vat: number;
  image: string;
  on_stock: number;
  supplier: SupplierData;
  url: string;
  location: string
}

interface ProductFieldProps {
  index: number,
  barcode: string,
  product: ProductData,
  changeProduct: (newProduct: SearchProductData) => void,
  readonly: boolean,
  autoValidate: boolean
}

const ProductField = (props: ProductFieldProps) => {

  const [product, setProduct] = useState(props.product),
    [showDefine, setShowDefine] = useState(false),
    [errorText, setErrorText] = useState(''),
    validate = (prod: ProductData) => {
      if (prod && prod.id > 0) {
        setErrorText("")
      } else {
        setErrorText("cannot be empty")
      }
    },

    changeProductHandler = (prod: ProductData): void => {

      axios({
        method: 'GET',
        url: `/products.json?prod_id=${prod.id}`,
        headers: {
          accept: 'application/json'
        }
      })
        .then((res) => {
          if (res.data.length > 0) {
            // const newProd = res.data[0] as SearchProductData;
            props.changeProduct(res.data[0] as SearchProductData);
          }
        })
        .catch((err) => {
          console.log(err)
        })

    },
    defineNewProductHandler = (index: int): void => {
      setShowDefine(true)
    },
    productAddedHandler = (newProduct: ProductData): void => {
      setProduct(newProduct);
      setShowDefine(false);
      props.changeProduct(convertToSearchProduct(newProduct));
    };


  useEffect(() => {
    if (props.autoValidate) {
      validate(product);
    }
  }, [props.autoValidate])


  useEffect(() => {
    // skip at entrance
    if (props.product !== product) {
      validate(product);
    }
  }, [product])

  return (
    <div className="col-12 col-md-12 col-xl-2">
      {props.readonly &&
        <a href={`/products/${product.id}`} target='_blank' >{productName(product)}</a>
      }

      {!props.readonly &&
        <ProductSelect
          barcode={props.barcode}
          index={props.index}
          product={product}
          defineNewHandler={defineNewProductHandler}
          changeHandler={changeProductHandler}
          key={product ? product.id : 0}
          htmlFieldName={fieldName(props.index, 'product_id')}
          defineOnBlur={() => validate(product)}
          error={errorText !== ''}
          helperText={errorText}
        />

      }

      <DefineProd
        barcode={props.barcode}
        show={showDefine}
        handleClose={() => { setShowDefine(false) }}
        productAdded={productAddedHandler} />
    </div>

  )
}

export default ProductField
