import React, { useState } from 'react'
import { Button } from '@mui/material';

import { int, GoodData, SupplierData } from '../Common/types';
import BarcodeTextField from './fields/BarcodeTextField';
import QtyTextField from './fields/QtyTextField';
import UnitSelect from './fields/UnitSelect';
import PriceTextField from './fields/PriceTextField';
import VatTextField from './fields/VatTextField';
import LocationTextField from './fields/LocationTextField';
import { fieldName } from './fields/CommonFuncs';
import ProductField, { SearchProductData } from './fields/ProductField';
import SupplierField from './fields/SupplierField';
import { ICursorCords, PRODUCT_FIELD_INDEX, SUPPLIER_FIELD_INDEX } from './Note';

export const PRODUCT_SEARCH_AFTER_CHAR = 1;

interface GoodProps {
  index: number,
  good: GoodData,
  readonly: boolean,
  lastRow: boolean,
  cursorCords: ICursorCords,
  type: "Inv" | "R" | "I" | "?",
  removeHandler: (index: int) => void,
  updateGoodAt: (index: int, good: GoodData, sourceCol: int) => void,
  addGood: () => void
}

const Good = (props: GoodProps) => {

  const [barcode, setBarcode] = useState((props.good.product && props.good.product.barcode) ? props.good.product.barcode : '');

  const autoValidate = (!props.lastRow && !props.readonly),
    removeMe = () => {
      props.removeHandler(props.index)
    },
    changeSupplier = (newSupplier: SupplierData) => {
      props.updateGoodAt(props.index, { ...props.good, supplier: newSupplier }, SUPPLIER_FIELD_INDEX);
    },
    changeProduct = (newProduct: SearchProductData) => {
      // console.log('changeProduct I am in')
      const prod = newProduct ? {
        id: newProduct.id,
        name: newProduct.name,
        barcode: newProduct.barcode,
        variant_label: newProduct.variant_label,
        unit_sym: newProduct.unit_sym,
        invoice_code: newProduct.invoice_code,
        unit_weight: newProduct.unit_weight,
        on_stock: newProduct.on_stock,
        url: newProduct.url,
        image: newProduct.image
      } : {
        id: 0, name: ''
      }

      const good = {
        ...props.good,
        product: prod,
        qty: 1,
        vat: newProduct.vat,
        unit: newProduct.unit_sym,
        price: newProduct.price,
        supplier: (newProduct.supplier ? newProduct.supplier : { id: 0, name: '' }),
        location: newProduct.location
      } as GoodData;

      props.updateGoodAt(props.index, good, PRODUCT_FIELD_INDEX);
    },

    barcodeChanged = (newBarcode) => {
      setBarcode(newBarcode)
    },
    qtyChanged = (newQty) => {
      props.updateGoodAt(props.index, { ...props.good, qty: newQty }, 2);
    },
    unitChanged = (newUnit) => {
      props.updateGoodAt(props.index, { ...props.good, unit: newUnit }, 3);
    },
    priceChanged = (newPrice) => {
      props.updateGoodAt(props.index, { ...props.good, price: newPrice }, 4);
    },
    vatChanged = (newVat) => {
      //  props.updateGoodAt(props.index, { ...props.good, vat: newVat });
    },
    supplierChanged = (newSupplier) => {
      //  props.updateGoodAt(props.index, { ...props.good, supplier: newSupplier });
    },
    locationChanged = (newLocation) => {
      // props.updateGoodAt(props.index, { ...props.good, location: newLocation });
    };


  // console.log('Good ' + props.index + " rerender", props.cursorCords)

  return (
    <div className={`row mt-2 ${props.good.destroy == 1 ? 'd-none' : ''}`}>
      {false &&
        <h1> {props.lastRow ? 1 : 0}- {props.readonly ? 1 : 0}- {autoValidate ? 1 : 0}</h1>
      }
      <input
        type="hidden"
        name={fieldName(props.index, '_destroy')}
        defaultValue={props.good.destroy} />

      <input
        type="hidden"
        name={fieldName(props.index, 'id')}
        defaultValue={props.good.id > 0 ? props.good.id : ''} />

      <BarcodeTextField
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        changeProduct={changeProduct}
        barcode={barcode}
        product_id={props.good.product ? props.good.product.id : 0}
        onChange={barcodeChanged}
        addGood={props.addGood}
        autoFocus={props.cursorCords.row === props.index && props.cursorCords.col === 0} />

      <ProductField
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        barcode={barcode}
        product={props.good.product}
        changeProduct={changeProduct}
      />

      <QtyTextField
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        onChange={qtyChanged}
        qty={props.good.qty}
        autoFocus={props.cursorCords.row === props.index && props.cursorCords.col === 2} />

      <UnitSelect
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        onChange={unitChanged}
        unit={props.good.unit}
      />


      <PriceTextField
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        onChange={priceChanged}
        price={props.good.price}
      />


      <VatTextField
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        onChange={vatChanged}
        vat={props.good.vat}
      />


      <SupplierField
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        onChange={supplierChanged}
        changeSupplier={changeSupplier}
        supplier={props.good.supplier}
        autoFocus={props.cursorCords.row === props.index && props.cursorCords.col === 6} />


      <LocationTextField
        index={props.index}
        autoValidate={autoValidate}
        readonly={props.readonly}
        onChange={locationChanged}
        addGood={props.addGood}
        location={props.good.location}
        autoFocus={props.cursorCords.row === props.index && props.cursorCords.col === 7} />


      <div className="col-12 col-md-12 col-xl-1">
        {!props.readonly &&
          <Button onClick={() => removeMe()} fullWidth variant="outlined" color="error">
            <i className="fa fa-trash"></i>
          </Button>
        }
      </div>

    </div >
  )
}

export default Good
