import React, { useState, useEffect } from 'react'
import { TextField, } from '@mui/material';
import { fieldName } from './CommonFuncs';

interface VatTextFieldProps {
  index: number,
  vat: number,
  readonly: boolean,
  autoValidate: boolean,
  onChange: (newVat: number) => void
}

const VatTextField = (props: VatTextFieldProps) => {

  const [vat, setVat] = useState(props.vat),
    [errorText, setErrorText] = useState(''),
    vatChanged = val => {
      const newVal = Number(String(val).replace(/,/g, '.'))
      if (!(newVal < 0)) {
        setVat(newVal)
        props.onChange(newVal)
      }
    },

    validate = () => {
      if (vat === NaN) {
        setErrorText("improper format")
      } else if (vat < 0) {
        setErrorText("cannot be negative")
      } else if (vat > 50) {
        setErrorText("cannot be greater than 50")
      } else {
        setErrorText("")
      }
    },
    onKeyPressed = (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
      }
    };


  useEffect(() => {
    if (props.autoValidate) {
      validate();
    }
  }, [props.autoValidate])


  return (

    <div className="col-4 col-md-4 col-xl-1">
      {props.readonly && props.vat}
      {!props.readonly &&
        <TextField
          onBlur={validate}
          name={fieldName(props.index, 'vat')}
          label="VAT"
          value={vat}
          onChange={e => vatChanged(e.target.value)}
          onKeyPress={onKeyPressed}
          error={errorText !== ''}
          helperText={errorText}
          InputProps={{
            sx: {
              "& input": {
                textAlign: "right"
              }
            }
          }}
        />
      }
    </div>
  )
}

export default VatTextField
