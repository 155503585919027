import React, { ChangeEvent, useEffect, useState } from 'react'
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import TextField from '@mui/material/TextField/TextField';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import Axios, { AxiosResponse } from 'axios';
import Rails from "@rails/ujs"
import Slider from '@mui/material/Slider/Slider';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';
import toastr from 'toastr';
import * as i18n from "i18n-js";

export interface CanvasObject {
  type: string;
  version: string;
  originX: string;
  originY: string;
  left: number;
  top: number;
  width: number;
  height: number;
  fill: string;
  stroke?: any;
  strokeWidth: number;
  strokeDashArray?: any;
  strokeLineCap: string;
  strokeDashOffset: number;
  strokeLineJoin: string;
  strokeUniform: boolean;
  strokeMiterLimit: number;
  scaleX: number;
  scaleY: number;
  angle: number;
  flipX: boolean;
  flipY: boolean;
  opacity: number;
  shadow?: any;
  visible: boolean;
  backgroundColor: string;
  fillRule: string;
  paintFirst: string;
  globalCompositeOperation: string;
  skewX: number;
  skewY: number;
  fontFamily: string;
  fontWeight: string;
  fontSize: number;
  text: string;
  underline: boolean;
  overline: boolean;
  linethrough: boolean;
  textAlign: string;
  fontStyle: string;
  lineHeight: number;
  textBackgroundColor: string;
  charSpacing: number;
  styles: CanvasObjectStyle;
  direction: string;
  path?: any;
  pathStartOffset: number;
  pathSide: string;
  pathAlign: string;
}

export interface CanvasJSON {
  json: {
    version: string;
    objects: CanvasObject[];
  };
  settings: {
    width: number,
    height: number,
    unit: string
  },
}

export interface CanvasObjectStyle {

}

export interface LabelEditorProps {
  shipping_label: string;
  logo_image_url: string;
}

const DEFAULTS = {
  width: "280",
  height: "80",
  unit: "mm"
}

//@ts-ignore
i18n.translations = I18n.translations || {};

const LabelEditor = (props: LabelEditorProps) => {
  const [width, setWidth] = useState<string>(DEFAULTS.width),
    [height, setHeight] = useState<string>(DEFAULTS.height),
    [unit, setUnit] = useState(DEFAULTS.unit),
    [canvaWidth, setCanvaWidth] = useState<number>(fabric.util.parseUnit(`${width}${unit}`)),
    [canvaHeight, setCanvaHeight] = useState<number>(fabric.util.parseUnit(`${height}${unit}`)),
    { editor, onReady } = useFabricJSEditor(),
    [isFirstLoad, setIsFirstLoad] = useState(true),
    [opacity, setOpacity] = useState(100),
    [objectFontSize, setObjectFontSize] = useState(16),
    handleChange = (event: SelectChangeEvent) => {
      setUnit(event.target.value as string);
    },
    widthChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setWidth(event.target.value);
    },
    heightChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setHeight(event.target.value);
    },
    objectFontSizeChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setObjectFontSize(parseInt(event.target.value, 0));
    },
    addText = (text = "customer_name", fontFamily = "Comic Sans") => {
      editor.canvas.add(new fabric.Text(text, { fontFamily: fontFamily, fontSize: 16, lockScalingY: true, lockScalingX: true }));
    },
    addImage = () => {
      new fabric.Image.fromURL(props.logo_image_url, (img: any) => {
        // console.log(img)
        editor.canvas.add(img)
      })
    },
    clear = () => {
      // editor.canvas.getActiveObject().getHeightOfLine();
      editor.canvas.remove(editor.canvas.getActiveObject());
    },
    clearAll = () => {
      editor.canvas.clear();
    },
    captureFunction = (event: KeyboardEvent) => {
      if (event.key === "Delete") {
        clear();
      }
    },
    setCanvaSize = () => {
      setCanvaHeight(fabric.util.parseUnit(`${height}${unit}`));
      setCanvaWidth(fabric.util.parseUnit(`${width}${unit}`));
    },
    save = () => {
      const json: CanvasJSON = editor.canvas.toJSON();
      Axios({
        method: "POST",
        url: `/shipping/save_shipping_label.json`,
        data: { json: json, width: width, height: height, unit: unit },
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
          "Content-Type": "application/json"
        }
      })
        .then((res) => {
          if (res.data.status == 'success') {
            toastr.success(res.data.message, i18n.t('success'))
          } else {
            toastr.error(res.data.message, i18n.t('error'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    load = () => {
      Axios({
        method: "GET",
        url: `/shipping/shipping_label.json`,
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
          "Content-Type": "application/json"
        }
      })
        .then((res: AxiosResponse<CanvasJSON>) => {
          setWidth(res.data.settings.width ? res.data.settings.width.toString() : DEFAULTS.width);
          setHeight(res.data.settings.height ? res.data.settings.height.toString() : DEFAULTS.height);
          setUnit(res.data.settings.unit ? res.data.settings.unit.toString() : DEFAULTS.unit);
          editor.canvas.loadFromJSON(res.data.json, function() {
            editor.canvas.renderAll();
          },function(o,object){
          })
        })
        .catch((err) => {
          console.log(err)
        })
    };

  function canvasSelectionHandler(o) {
    const obj = editor.canvas.getActiveObject();
    // obj.set('lockScalingX', true);
    // obj.set('lockScalingY', true);
    console.log(obj);
    setObjectFontSize(obj.fontSize)
  }

  useEffect(() => {
    if (editor) {
      editor.canvas.setHeight(canvaHeight);
      editor.canvas.setWidth(canvaWidth);
      if (isFirstLoad) {
        load();
        editor.canvas.on('selection:created', (obj) => canvasSelectionHandler(obj))
        editor.canvas.on('selection:updated', (obj) => canvasSelectionHandler(obj))
        // editor.canvas.getObjects().map(o => {
        //   o.lockScalingX = o.lockScalingY = true;
        // })
        // editor.canvas.on('object:modified', (options) => {
        //   options.
        //   options.target.set({ opacity: 1 });
        //   var newWidth = Math.round(options.target.width);
        //   var newHeight = Math.round(options.target.height);

        //   console.log("OPTIONS: ", options.target.width, options.target.height, newWidth, newHeight)

        //   if (options.target.width !== newWidth) {
        //     options.target.set({ width: newWidth, height: newHeight, scaleX: 1, scaleY: 1, });
        //     console.log("OPTIONS 2: ", options.target.width)
        //   }

        // });
        setIsFirstLoad(false)
      }
    }
  }, [onReady])

  useEffect(() => {
    if (editor) {
      editor.canvas.setHeight(canvaHeight);
      editor.canvas.setWidth(canvaWidth);
    }
  }, [canvaWidth, canvaHeight]);

  useEffect(() => {
    if (editor) {
      const obj = editor.canvas.getActiveObject();
      obj.set('fontSize', objectFontSize);
    }
  }, [objectFontSize])

  useEffect(() => {
    if (editor) {
      setCanvaSize()
    }
  }, [width, height, unit]);

  useEffect(() => {
    document.addEventListener("keydown", captureFunction, false);

    return () => {
      document.removeEventListener("keydown", captureFunction, false);
    };
  }, [editor]);

  useEffect(() => {
    if (editor && editor.canvas.getActiveObject()) {
      editor.canvas.getActiveObject().set("opacity", opacity / 100)
    }
  }, [opacity]);

  return (
    <div>
      <h1>Shipping label editor</h1>
      <div style={{ display: "flex" }}>
        <FormControl size="small">
          <InputLabel id="unit-select">Unit</InputLabel>
          <Select
            labelId="unit-select"
            id="unit-select"
            value={unit}
            label="Unit"
            onChange={handleChange}
          >
            <MenuItem value="mm">mm</MenuItem>
            <MenuItem value="px">px</MenuItem>
            <MenuItem value="in">in</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="outlined-number"
          size="small"
          label="Width"
          type="number"
          value={width}
          onChange={(e) => widthChanged(e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        &nbsp; x &nbsp;
        <TextField
          id="outlined-number"
          size="small"
          label="Height"
          type="number"
          value={height}
          onChange={(e) => heightChanged(e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <button className="btn btn-primary btn-sm ml-2" onClick={setCanvaSize}>Set</button>
        <button className="btn btn-primary btn-sm ml-2" onClick={clear}>Delete obj (del)</button>
        <button className="btn btn-primary btn-sm ml-2" onClick={clearAll}>Clear</button>
        <button className="btn btn-primary btn-sm ml-2" onClick={load}>Load</button>
        <button className="btn btn-primary btn-sm ml-2" onClick={save}>Save</button>
      </div>
      <div>
        <button className="btn btn-primary btn-sm my-3 ml-2" onClick={() => addText("customer_name")}>Add customer name</button>
        <button className="btn btn-primary btn-sm my-3 ml-2" onClick={() => addText("order_id: id")}>Add id</button>
        <button className="btn btn-primary btn-sm my-3 ml-2" onClick={() => addText("p_id: id")}>Add project id</button>
        <button className="btn btn-primary btn-sm my-3 ml-2" onClick={() => addText("project_name")}>Add project name</button>
        <button className="btn btn-primary btn-sm my-3 ml-2" onClick={() => addText("package_size")}>Add package size</button>
        <button className="btn btn-primary btn-sm my-3 ml-2" onClick={() => addText("pck_num")}>Add package number</button>
        <button className="btn btn-primary btn-sm my-3 ml-2" onClick={() => addImage()}>Add logo</button>
      </div>
      <div style={{ display: 'flex' }}>
        <Box sx={{ width: 320 }}>
          <Typography gutterBottom>Opacity</Typography>
          <Slider
            min={0}
            step={0.01}
            max={100}
            value={opacity}
            onChange={(_e, val) => setOpacity(numberHelper(val))}
            defaultValue={100}
            aria-label="Opacity"
            valueLabelDisplay="on"
          />
        </Box>
        <TextField
          style={{ marginLeft: 50 }}
          id="outlined-number"
          size="small"
          label="Font size"
          type="number"
          value={objectFontSize}
          onChange={(e) => objectFontSizeChanged(e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <FabricJSCanvas className="shipping-label-canvas" onReady={onReady} />
      <div className="test"></div>
    </div>
  );
}

function numberHelper(num: number | number[]): number {
  if (typeof num == "number") {
    return num;
  } else {
    return num[0];
  }
}

export default LabelEditor;
