import * as React from "react"
import Rails from "@rails/ujs"
import { TruckData } from "../Common/types"
import { truckDisplayColor, truckSpan } from "../Common/functions"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'

declare function formatMoney(number: number, decPlaces: number, decSep: string, thouSep: string): string;


interface TruckInfoProps {
  truck: TruckData,
  onTruckUpdated: () => void
}

const TruckInfo = (props: TruckInfoProps) => {

  const [show, setShow] = React.useState(false);
  const [truck, setTruck] = React.useState(props.truck);
  const [modified, setModified] = React.useState(false);

  const handleClose = () => {
    if (modified) {
      props.onTruckUpdated()
      setModified(false);
    }
    setShow(false);
  }
  const handleShow = () => {
    fetchTruckData();
    setShow(true);
  }

  const fetchTruckData = async () => {
    const response = await fetch(`/trucks/${props.truck.id}.json`)
    const json = await response.json()
    setTruck(json)
  }

  function moveX(proj_id: number, direction: string): void {
    const url = `/projects/${direction}/${proj_id}.json`
    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json",
      },
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      } else {
        fetchTruckData()
      }
    })
  }

  function moveUp(proj_id: number): void {
    moveX(proj_id, "higher")
  }

  function moveDown(proj_id: number): void {
    moveX(proj_id, "lower")
  }

  function removeFromTruck(proj_id: number): void {
    Axios({
      method: "POST",
      url: `trucks/${props.truck.id}/del_project/${proj_id}`,
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then(() => {
        setModified(true);
        fetchTruckData();
      })
      .catch((err) => {
        console.log(err)
      })
  }

  let typIcon = <i className="fa fa-truck"></i>;

  if (truck.typ === 'container_20') {
    typIcon = <i className="fa fa-archive"></i>;
  }
  else if (truck.typ === 'container_40') {
    typIcon = <i className="fa fa-archive"></i>;
  }

  return (
    <div>

      <a href="#" className="btn btn-xs  assign-truck" onClick={handleShow} >
        {truckSpan(props.truck)}
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className={truckDisplayColor(truck.payload_pct)}>
              {truck.multi_addresses ? <i className="fa fa-map-marker" aria-hidden="true"></i> : ""}
              {typIcon} {truck.id}
            </span> {truck.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <br />Pct:    <span className={truckDisplayColor(truck.payload_pct)}>{truck.payload_pct} %</span>
          <br />Price: € {formatMoney(truck.payload_price, 0, ".", ",")}
          <br />Dest: {truck.dest}

          <br />Status: {truck.status}
          <br />Dispatch Date: {truck.dispatch_date}

          <br /> <h3>Projects</h3>
          <table className="table">
            <thead>
              <tr><th>#</th><th>Name</th><th>Price</th><th></th><th></th></tr>
            </thead>
            <tbody>
              {truck.projects && truck.projects.map(function (proj, i) {
                let upButton;
                if (i > 0) {
                  upButton = <a onClick={() => moveUp(proj.id)}><i title="Move Up" className="fa fa-chevron-up" ></i></a>;
                }

                let downButton;
                if (i < truck.projects.length - 1) {
                  downButton = <a onClick={() => moveDown(proj.id)}><i title="Move Down" className="fa fa-chevron-down" ></i></a>;
                }

                return <tr key={proj.id}>
                  <td>{proj.id}</td>
                  <td><a href={proj.url} >{proj.name}</a> </td>
                  <td>{proj.price}</td>
                  <td>{upButton} </td>
                  <td>{downButton}</td>
                  <td><a onClick={() => removeFromTruck(proj.id)} style={{ color: "#ffffff" }} className="btn btn-danger btn-xs">Remove </a>
                  </td>
                </tr>;
              })}
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <a href={`/trucks/${props.truck.id}`} className="btn btn-success">Details</a>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default TruckInfo
