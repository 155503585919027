import React, { useEffect, useState } from 'react'
import { TextField, Button } from '@mui/material';
import { int } from '../../Common/types';
import { fieldName } from './CommonFuncs';

interface QtyTextFieldProps {
  index: number,
  qty: int,
  readonly: boolean,
  onChange: (qty: int) => void,
  autoFocus: boolean,
  autoValidate: boolean
}

const QtyTextField = (props: QtyTextFieldProps) => {
  const
    [qty, setQty] = useState(props.qty),
    [errorText, setErrorText] = useState(''),

    qtyRef = React.useRef(null),

    qtyChanged = val => {
      const newVal = parseInt(val);

      if (newVal) {
        setQty(newVal)
        props.onChange(newVal);
        validate(newVal)
      }

    },

    validate = (val) => {
      if (val > 0) {
        setErrorText("")
      } else if (val === 0) {
        setErrorText("cannot be zero")
      } else {
        setErrorText("cannot be negative")
      }
    },
    onKeyPressed = (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
      }
    },
    doFocus = () => {
      if (qtyRef && qtyRef.current) {
        console.log('Qty doFocus')
        qtyRef.current.focus();
      }
    };

  useEffect(() => {
    if (props.autoValidate) {
      validate(qty);
    }
  }, [props.autoValidate])

  useEffect(() => {
    if (props.autoFocus) doFocus();
  }, [props.autoFocus])

  return (

    <div className="col-4 col-md-4 col-xl-1">
      {props.readonly && qty}
      {!props.readonly &&
        <>
          <TextField
            onBlur={() => validate(qty)}
            type="text"
            label="Qty"
            name={fieldName(props.index, 'qty')}
            value={qty}
            inputRef={qtyRef}
            onChange={e => qtyChanged(e.target.value)}
            onKeyPress={onKeyPressed}
            error={errorText !== ''}
            helperText={errorText}
          />
        </>

      }
    </div>
  )
}

export default QtyTextField
