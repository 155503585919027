import React from 'react';
import HeaderSortableColumn from './HeaderSortableColumn';

interface TableHeaderProps {
  isChecked: boolean;
  onCheckboxAllClick: () => void;
}

const TableHeader = (props: TableHeaderProps) => {
  const [isChecked, setIsChecked] = React.useState(props.isChecked);

  function toggleChecked(): void {
    props.onCheckboxAllClick();
  }

  React.useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props.isChecked]);

  return (
    <thead>
      <tr>
        {true && (
          <th>
            <td onClick={() => toggleChecked()}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => toggleChecked()}
                className="i-checks"
              />
            </td>
          </th>
        )}
        <HeaderSortableColumn column={'task_id'} label={'Task ID'} />
        <th></th>
        <th title="Order ID">OrdID</th>
        <th title="Project ID">ProjID</th>
        <th title="Project Name">ProjName</th>
        <th title="Customer">Customer</th>
        <HeaderSortableColumn column={'task_type'} label={'Task Type'} />
        <HeaderSortableColumn column={'machine'} label={'Machine'} />
        <HeaderSortableColumn
          column={'operator_name'}
          label={'Operator Name'}
        />
        <HeaderSortableColumn column={'deadline'} label={'Deadline'} />
        <HeaderSortableColumn column={'priority'} label={'Priority'} />
        <HeaderSortableColumn column={'status'} label={'Status'} />
        <th>Task Completion Duration</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
