import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material';

import { FormatPrice } from '../../Common/functions'
import { fieldName } from './CommonFuncs';

interface PriceTextFieldProps {
  index: number,
  price: number,
  readonly: boolean,
  autoValidate: boolean,
  onChange: (price: number) => void,
}

const PriceTextField = (props: PriceTextFieldProps) => {

  const
    [price, setPrice] = useState(props.price),
    [errorText, setErrorText] = useState(''),

    priceChanged = newVal => {
      // const valText2 = String(valText).replace(/,/g, '.');
      setPrice(newVal)
      validate(newVal)

      //  const newVal = Number(valText2)
      if (!isNaN(newVal)) {
        props.onChange(newVal)
      }
    },

    validate = (priceNum: number) => {
      if (isNaN(priceNum)) {
        setErrorText("improper format")
      }
      else if (priceNum > 0) {
        setErrorText("")
      } else if (priceNum === 0) {
        setErrorText("cannot be zero")
      } else {
        setErrorText("cannot be negative")
      }
    },
    onKeyPressed = (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
      }
    };

  useEffect(() => {
    if (props.autoValidate) {
      validate(price);
    }
  }, [props.autoValidate])

  return (
    <div className="col-4 col-md-4 col-xl-1">
      {props.readonly && FormatPrice(price)}
      {!props.readonly &&
        <TextField
          onBlur={() => validate(price)}
          name={fieldName(props.index, 'price')}
          label="Price"
          value={price}
          onChange={e => priceChanged(e.target.value)}
          onKeyPress={onKeyPressed}
          error={errorText !== ''}
          helperText={errorText}
          InputProps={{
            inputMode: 'numeric',
            sx: {
              "& input": {
                textAlign: "right"
              }
            }
          }}
        />
      }
    </div>

  )
}

export default PriceTextField
