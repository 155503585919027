import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import * as i18n from "i18n-js"
import { NotificationData } from "../Common/types"
import { projectLink, truckLink } from '../Common/functions'

export interface TopListItemProps {
  notification: NotificationData,
  onReadClick: (id: number) => void
}

const TopListItem = (props: TopListItemProps) => {

  const textColor = item => {
    if (item.read_at && item.confirmed_at) {
      return '#28a74a'
    } else if (item.read_at && !item.confirmed_at) {
      return '#007bff'
    }
  };

  return (
    <>
      <li>
        <div className="dropdown-messages-box">

          <div className="media-body">
            <small className="pull-right font-weight-bold" style={{ color: textColor(props.notification) }}>{props.notification.created_at}</small>
            <br />
            {ReactHtmlParser(props.notification.subject)}
            <br />
            <small>
              {ReactHtmlParser(props.notification.message)}
            </small>
            {props.notification.project && <small><br />{projectLink(props.notification.project, true, true)}</small>}
            {props.notification.truck && <small><br />{truckLink(props.notification.truck)}</small>}
            <small className="pull-right" >
              {!props.notification.read_at &&
                <button
                  className={`btn btn-xs btn-white`}
                  onClick={() => props.onReadClick(props.notification.id)}
                  title={i18n.t('mark_as_read')}
                >
                  <i className="fa fa-eye"></i>
                </button>
              }
            </small>
          </div>
        </div>
      </li>
      <li className="dropdown-divider"></li>
    </>
  )
}

export default TopListItem
