import * as React from "react"
import Rails from "@rails/ujs"
import Axios from 'axios'
import toastr from 'toastr'

declare function formatMoney(number: number, decPlaces: number, decSep: string, thouSep: string): string;

export interface PayCheckboxProps {
  project_id: number,
  amount: number,
  field_name: string,
  checked: boolean,
  paid_date: string
}

const PayCheckbox = (props: PayCheckboxProps) => {

  const [checked, setChecked] = React.useState(props.checked);
  const [paid_date, setPaidDate] = React.useState(props.paid_date);

  function checkboxClicked(): void {
    if (!checked) {
      setChecked(true);
      submitForm();
    }
  }

  function submitForm() {
    Axios({
      method: "POST",
      url: `/projects/${props.project_id}/mark_paid`,
      data: { field_name: props.field_name },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        if (res.data['status'] === 'ok') {
          setPaidDate(res.data['paid_date'])
          toastr.success("Success")
        }
        else {
          toastr.error("Errors", res.data['errors'])
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      €{formatMoney(props.amount, 2, ".", ",")}
      <input
        type="checkbox"
        style={{ marginLeft: "10px" }}
        checked={checked}
        disabled={checked}
        onChange={() => checkboxClicked()}
        title={checked ? `Paid at: ${paid_date}` : "Click to mark paid"}
        className="i-checks"
      />
    </div >
  )
}
export default PayCheckbox
