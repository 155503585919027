
import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"

import Axios from 'axios'
import { ProductData, int } from '../Common/types';
import toastr from 'toastr'
import LaddaButton from 'react-ladda';
import XL from 'react-ladda';
import SLIDE_UP from 'react-ladda';
import { Box, FormControl, InputAdornment, MenuItem, Select, Stack, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Button } from 'react-bootstrap';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

export interface DefineProdProps {
  show: boolean,
  barcode: string | null,
  handleClose: () => void;
  productAdded: (product: ProductData) => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DefineProd = (props: DefineProdProps) => {

  const [product, setProduct] = useState({
    id: 0, name: "",
    barcode: "",
    variant_label: "",
    qty: 0,
    unit_sym: "",
    invoice_code: "",
    unit_weight: 0,
    unit_quantity: 0
  }),

    [laddaState, setLaddaState] = React.useState({ loading: false, progress: 0 }),

    nameChanged = val => {
      setProduct(old => ({ ...old, name: val }))
    },
    barcodeChanged = val => {
      setProduct(old => ({ ...old, barcode: val }))
    },
    variantLabelChanged = val => {
      setProduct(old => ({ ...old, variant_label: val }))
    },
    unitSymChanged = val => {
      setProduct(old => ({ ...old, unit_sym: val }))
    },
    invoiceCodeChanged = val => {
      setProduct(old => ({ ...old, invoice_code: val }))
    },
    unitWeightChanged = val => {
      setProduct(old => ({ ...old, unit_weight: val }))
    };

  function submitForm(): void {
    laddaOn();

    Axios({
      method: "POST",
      url: `/products.json`,
      data: {
        product: {
          name: product.name,
          barcode: product.barcode,
          variant_label: product.variant_label,
          unit_sym: product.unit_sym,
          invoice_code: product.invoice_code,
          unit_weight: product.unit_weight

        }
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();

        if (res.status === 201) {
          toastr.success("Product added")
          console.log('res.data', res.data)
          props.productAdded(res.data);
        }
        else {
          toastr.error(res.data['errors'], "Product error")
        }
      })
      .catch((err) => {
        laddaOff();
        console.log(err)
      })

  }


  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  useEffect(() => {
    setProduct({
      id: 0, name: "",
      barcode: props.barcode ?? "",
      variant_label: "",
      qty: 0,
      unit_sym: "pcs",
      invoice_code: "",
      unit_weight: 0,
      unit_quantity: 0
    })
  }, [props.show])


  return (
    <Modal
      open={props.show}
      onClose={() => props.handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          New Product
        </Typography>

        <FormControl fullWidth>


          <Stack spacing={2}>
            <TextField
              type="text"
              label="Name"
              name="name"
              fullWidth
              onChange={e => nameChanged(e.target.value)}
              value={product.name}
            />
            <TextField
              type="text"
              name="barcode"
              label="Barcode"
              fullWidth
              onChange={e => barcodeChanged(e.target.value)}
              value={product.barcode}
            />

            <TextField
              type="text"
              label="Variant"
              name="variant_label"
              fullWidth
              onChange={e => variantLabelChanged(e.target.value)}
              value={product.variant_label}
            />
            <TextField
              type="text"
              label="Invoice code"
              name="invoice_code"
              data-testid="invoice-code-label"
              fullWidth
              onChange={e => invoiceCodeChanged(e.target.value)}
              value={product.invoice_code}
            />


            <Select
              name="unit_sym"
              label="Unit Sym"
              className="col-6"
              value={product.unit_sym}
              onChange={event => unitSymChanged(event.target.value)}
            >
              <MenuItem value={'pcs'}>Pcs</MenuItem>
              <MenuItem value={'m'}>m</MenuItem>
              <MenuItem value={'m2'}>m2</MenuItem>
              <MenuItem value={''}>[Not selected]</MenuItem>
            </Select>


            <TextField
              type="text"
              label="Unit weight"
              name="unit_weight"

              className="col-6"
              onChange={e => unitWeightChanged(e.target.value)}
              value={product.unit_weight}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                inputMode: 'numeric',
                sx: {
                  "& input": {
                    textAlign: "right"
                  }
                }
              }}
            />
            <Stack direction="row" spacing={2}
              justifyContent="space-between"
              alignItems="center">

              <Button variant="contained" onClick={() => props.handleClose()} >Cancel </Button>
              {!laddaState.loading && <Button onClick={() => submitForm()} >OK</Button>}
              {laddaState.loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500]
                  }}
                />
              )}
            </Stack>
          </Stack>
        </FormControl>
      </Box>
    </Modal>
  )
}
export default DefineProd
