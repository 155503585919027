
import React from 'react'

export interface SimpleButtonProps {

}

const SimpleButton = (props: SimpleButtonProps) => {

  function clickHandler(): void {
    console.log('clickHandler working')
  }

  return (
    <div>
      <a href="#" className="btn btn-primary" onClick={() => clickHandler()}>
        Hello
      </a>
    </div>
  )
}
export default SimpleButton
