import React from 'react'
import { ProjectData } from '../Common/types';
import { customerLink, orderLink, projectLink, customSym, stockSym, swapSym, projectState, taskState, projectLinkDecorated, taskStateById } from '../Common/functions';
import TruckInfo from '../Project/TruckInfo'
import { TT_FLOOR_BEARERS, TT_INSTALLATION, TT_INSULATION, TT_PURLINS, TT_ROOF, TT_STAIRS, TT_WALL_BEAMS, TT_WINBOARDS_TRIMS, TT_WINDOWS, TT_WOODEN_PARTS } from './List';

interface TableRowWoodPartsProps {
  isChecked: boolean,
  project: ProjectData,
  onCheckboxClick: (project: ProjectData, checked: boolean) => void
  onTruckUpdated: () => void
}

const TableRowWoodParts = (props: TableRowWoodPartsProps) => {

  function toggleChecked(): void {
    props.onCheckboxClick(props.project, !props.isChecked)
  }

  return (
    <tr className={`deadline2-${props.project.deadline_color}`}>
      <td onClick={() => toggleChecked()}>
        <input
          type="checkbox"
          checked={props.isChecked}
          onChange={() => toggleChecked()}
          className="i-checks"
        />
      </td>
      <td>
        {props.project.visible_id}
      </td>
      <td>
        {orderLink(props.project.order, props.project.order_visible_id)}
      </td>
      <td>
        <>
          {props.project.xls_file1_wood && <i className="fa fa-check text-navy" title="Uploaded"></i>}
          {!props.project.xls_file1_wood && <i className="fa fa-exclamation text-warning pl-1" title="Wood Parts file is required"></i>}
        </>
      </td>

      <td>{projectLinkDecorated(props.project, false, true)}</td>
      <td>{props.project.deadline} ({props.project.days_to_deadline})</td>
      <td>{projectState(props.project)}</td>
      <td>{props.project.tasks_progress}%</td>
      <td>
        {props.project.truck && <TruckInfo truck={props.project.truck} onTruckUpdated={props.onTruckUpdated} ></TruckInfo>}
      </td>
      <td>
        {customerLink(props.project.customer)}
      </td>
      <td>
        {props.project.sales_manager}
      </td>


      <td>{taskStateById(props.project.task_statuses, parseInt(TT_WALL_BEAMS))}</td>
      <td>{taskStateById(props.project.task_statuses, parseInt(TT_PURLINS))}</td>
      <td>{taskStateById(props.project.task_statuses, parseInt(TT_ROOF))}</td>
      <td>{taskStateById(props.project.task_statuses, parseInt(TT_WINBOARDS_TRIMS))}</td>
      <td>{taskStateById(props.project.task_statuses, parseInt(TT_FLOOR_BEARERS))}</td>
      <td>{taskStateById(props.project.task_statuses, parseInt(TT_STAIRS))}</td>
      <td>?</td>

      <td>
        {props.project.order_date}
      </td>

    </tr >

  )
}

export default TableRowWoodParts
