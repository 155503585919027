import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import {
  Link,
  useLocation
} from "react-router-dom";
import { int } from '../Common/types';
import { PlannerMenuRouterProps } from './PlannerMenuRouter';


const PlannerMenuItem = ( props: PlannerMenuRouterProps ) => {

  let location = useLocation();

  const trucksItem = [
    {
      id: 1,
      truckName: 'Truck 90m3 13,6x2,7',
      img: '/planner/assets/truck_90.png',
      link: `/trucks/${props.truck_id}/truck_90`
    },
    {
      id: 2,
      truckName: 'Truck 120m3 2,9x7,3',
      img: '/planner/assets/truck_120.png',
      link: `/trucks/${props.truck_id}/truck_120`
    },
    {
      id: 3,
      truckName: 'Container 20',
      img: '/planner/assets/container_20.jpeg',
      link: `/trucks/${props.truck_id}/container_20`
    },
    {
      id: 4,
      truckName: 'Container 40',
      img: '/planner/assets/container_40.jpeg',
      link: `/trucks/${props.truck_id}/container_40`
    },

  ]

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const pathToTruckIndex = (path: string): int => {

    if (path.includes("truck_90")) {
      return 1;
    }
    else if (path.includes("truck_120")) {
      return 2;
    }
    else if (path.includes("container_20")) {
      return 3;
    }
    else if (path.includes("container_40")) {
      return 4;
    }
    else {
      return 0;
    }

  }

  const [truckIndex, setTruckIndex] = useState(pathToTruckIndex(props.typ));

  const handleClick = (truck) => {
    setTruckIndex(truck.id);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {
            trucksItem.map((truck, index) => {
              return (
                <Grid item md={3} xs={12} key={index}>
                  <Link to={truck.link} >
                    <Item
                      style={{
                        backgroundColor: truck.id === truckIndex ? '#a6b2ba' : '',
                        color: truck.id === truckIndex ? 'white' : ''
                      }}
                      onClick={() => handleClick(truck)}>
                      <img src={truck.img} style={{ width: '60%', maxWidth: '250px', height: '100px', margin: '20px auto' }} />
                      <Divider className="w-48 my-24 border-1" light />
                      <Typography variant="h5" className="text-10 font-medium my-2 ">{truck.truckName}</Typography>
                      <Divider className="w-48 my-24 border-1" light />
                      <Button variant="contained" endIcon={<SendIcon />} style={{ width: '150px', margin: '20px auto', backgroundColor: '#1ab394' }}>Use</Button>
                    </Item>
                  </Link>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
    </>
  )
}

export default PlannerMenuItem
