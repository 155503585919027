import Magnet from 'magnet.js/libs/magnet';
import { ItemsHeightTable, PackingItemData, PackingPlanData, Project } from '../Common/types';

export class Wheel {

  magnet;
  truck: PackingPlanData;
  lastTopDistance: number;
  lastLeftDistance: number;
  projects: Project[];

  newItems: PackingItemData[];
  itemsHeightTable: {
    left: ItemsHeightTable[],
    right: ItemsHeightTable[]
  }
  colors;
  collide;
  saved;

  public truckLeftDivId = "truck-120-left";
  public truckRightDivId = "truck-120-right";
  public plannerDivId = "planner";
  public trailerLeftDivId = "trailer-120-left";
  public trailerRightDivId = "trailer-120-right";
  public truckHeight = 0;
  public truckWidth = 0;

  constructor(public simple: boolean) {
    this.magnet = new Magnet();
    this.lastTopDistance = 0;
    this.lastLeftDistance = 0;
    this.projects = [];
    this.newItems = [];
    this.itemsHeightTable = { left: [], right: [] };
    this.colors = ["#7fffd4", "#9966cc", "#fefe33", "#4169e1", "#964b00", "#ffcc99", "#e96b39", "#00ffff", "#4b0082"];
    this.saved = false;
  }

  get itemsParent(): HTMLElement | null{
    return document.getElementById('items');
  }

  get leftBox(): HTMLElement | null {
    return document.getElementById('box-left');
  }

  get rightBox(): HTMLElement | null {
    return document.getElementById('box-right');
  }

  get items(): NodeListOf <Element> {
    return this.itemsParent.querySelectorAll('#item');
  }

  get magnetItems(): NodeListOf <Element> {
    return document.querySelectorAll('.magnet');
  }

  get truckLeft(): HTMLElement | null {
    return document.getElementById(this.truckLeftDivId)
  }

  get truckRight(): HTMLElement | null {
    return document.getElementById(this.truckRightDivId)
  }

  addPackageToNewItem(pack){
    if (!this.newItems.includes(pack)) {
      this.newItems.push(pack);
    }
  }

  loadPosition(packingPlan: PackingPlanData) {
    const plan = this.getPackingPlan()
    packingPlan = plan != null? plan : packingPlan
    let loaded: boolean = true;
    if (packingPlan !== undefined && packingPlan !== null && packingPlan.top1 !== 0 && packingPlan.top2 !== 0) {
      this.truckLeft.style.top = String(packingPlan.top1) + 'px';
      this.truckLeft.style.left = String(packingPlan.left1) + 'px';
      this.truckRight.style.top = String(packingPlan.top2) + 'px';
      this.truckRight.style.left = String(packingPlan.left2) + 'px';

      const trailerLeft: any = document.getElementById(this.trailerLeftDivId);
      const trailerRight = document.getElementById(this.trailerRightDivId);
      if (trailerLeft != null && trailerRight != null) {
        // const truckWidth = truckLeft.getBoundingClientRect().width;
        trailerLeft.style.top = String(packingPlan.top1) + 'px';
        trailerLeft.style.left = String(packingPlan.left2) + 'px';
        trailerRight.style.top = String(packingPlan.top2) + 'px';
        trailerRight.style.left = String(packingPlan.left1) + 'px';
      }
      packingPlan.packing_items_attributes.forEach((pack, i) => {
        if (this.items[i]){
          if (pack !== undefined && pack.top !== null && pack.left !== null ) {
            let truckTopDifference = 0;
            let planTopDifference = 0;
            let packTop = pack.top
            const trailer = document.getElementById("trailer-box-right");
            let truckTop = this.leftBox?.getBoundingClientRect().top;
            let truckLeft = this.leftBox?.getBoundingClientRect().left;
            if (pack.top < 0) {
              truckTopDifference = this.rightBox?.getBoundingClientRect().top - this.leftBox?.getBoundingClientRect().top;
              planTopDifference = packingPlan.top2 - packingPlan.top1;
              let topDifference = truckTopDifference - planTopDifference;
              packTop = pack.top + truckTopDifference - topDifference
              truckLeft = trailer != null ? Number(trailer.getBoundingClientRect().left) : this.rightBox?.getBoundingClientRect().left;
            }
            this.items[i].style.top = String((truckTop + this.truckHeight + truckTopDifference) - packTop) + 'px';
            this.items[i].style.left = String(truckLeft + pack.left) + 'px';
            if (!this.isPackageOnAnyBox(this.items[i])){
              this.addPackageToNewItem(pack);
              loaded = false;
            }
          }
          else {
            this.addPackageToNewItem(pack);
            loaded = false;
          }
        }
      })
    }
    else {
      if (this.truck !== undefined && this.truck !== null && this.truck.top1 !== 0 && this.truck.top2 !== 0 && this.truck.left1 !== 0 && this.truck.left2 !== 0) {
        this.loadPosition(this.truck);
      } else {
        if (packingPlan && packingPlan.packing_items_attributes.length > 0) {
          packingPlan.packing_items_attributes.forEach(it => {
            if (this.newItems && !this.newItems.includes(it)) {
              this.newItems.push(it);
            }
          });
          loaded = false;
        }
      }
    }
    this.startedItemsTopPositions(!loaded);
    return loaded;
  }

  getPackingPlan() {
    if (!this.collide) {
      const { height, left1, left2, top1, top2, truck_id, width,
        items_height_left_1, items_height_left_2, items_height_left_3,
        items_height_left_4, items_height_left_5, items_height_left_6,
        items_height_right_1, items_height_right_2, items_height_right_3,
        items_height_right_4, items_height_right_5, items_height_right_6} = this.truck;
      let {packing_items_attributes} = this.truck
      const packing_items = packing_items_attributes.map(pi => {
        return {
          depth: pi.depth,
          height: pi.height,
          id: pi.id,
          left: pi.left,
          top: pi.top,
          width: pi.width,
          position: pi.position,
          project_id: pi.project_id
        }
      })
      const truck = { height, left1, left2, top1, top2, truck_id, width,
        items_height_left_1, items_height_left_2, items_height_left_3,
        items_height_left_4, items_height_left_5, items_height_left_6,
        items_height_right_1, items_height_right_2, items_height_right_3,
        items_height_right_4, items_height_right_5, items_height_right_6, packing_items_attributes: packing_items};
      return truck;
    } else {
      return null;
    }
  }

  getBoxThatContainsPackage(item) {
    const trailerLeft = document.getElementById('trailer-box-left');
    const trailerRight = document.getElementById('trailer-box-right');
    if (this.leftBox != null && this.rightBox != null){
      const boxLeft = this.leftBox.getBoundingClientRect();
      const boxRight = this.rightBox.getBoundingClientRect();
      if (trailerLeft != null && trailerRight != null){
        const trailerBoxLeft = trailerLeft?.getBoundingClientRect();
        const trailerBoxRight = trailerRight?.getBoundingClientRect();
        if (this.isPackageOnBox(item, trailerBoxLeft)) return trailerBoxLeft
        if (this.isPackageOnBox(item, trailerBoxRight)) return trailerBoxRight
      }
      if (this.isPackageOnBox(item, boxLeft)) return boxLeft
      if (this.isPackageOnBox(item, boxRight)) return boxRight
    }
    return null
  }

  getBoxesRectList() {
    const rectList: DOMRect[] = []
    const trailerLeft = document.getElementById('trailer-box-left');
    const trailerRight = document.getElementById('trailer-box-right');
    rectList.push(this.leftBox.getBoundingClientRect());
    if (trailerLeft) {
      rectList.push(trailerLeft.getBoundingClientRect())
      if (trailerRight) {
        rectList.push(trailerRight.getBoundingClientRect())
      }
    }
    rectList.push(this.rightBox.getBoundingClientRect());
    return rectList
  }

  isPackageOnAnyBox(item): boolean {
    let isOnTruck = false;
    let isOnTrailer = false;
    const trailerLeftBox = document.getElementById('trailer-box-left');
    const trailerRightBox = document.getElementById('trailer-box-right');
    if (this.leftBox != null && this.rightBox != null){
      const box1 = this.leftBox.getBoundingClientRect();
      const box2 = this.rightBox.getBoundingClientRect();
      if (trailerLeftBox != null && trailerRightBox != null){
        const box3 = trailerLeftBox?.getBoundingClientRect();
        const box4 = trailerRightBox?.getBoundingClientRect();
        isOnTrailer = this.isPackageOnBox(item, box3) || this.isPackageOnBox(item, box4)
      }
      if (this.isPackageOnBox(item, box1) || this.isPackageOnBox(item, box2)) {
        isOnTruck = true;
      }
    }
    return isOnTruck || isOnTrailer
  }

  savePackingPlan() {
    if (this.truck.created_at !== undefined) {
      this.truck.created_at = new Date(Date.now()).toISOString();
    } else {
      this.truck.updated_at = new Date(Date.now()).toISOString();
    }
    const trailer = document.getElementById("trailer-120-right")
    const trailerBox = document.getElementById("trailer-box-right")
    this.truck.height = this.truckHeight;
    this.truck.width = this.truckWidth;
    this.truck.top1 = Number(this.truckLeft.getBoundingClientRect().top)
    this.truck.left1 = Number(this.truckLeft.getBoundingClientRect().left);
    this.truck.top2 = Number(this.truckRight.getBoundingClientRect().top);
    this.truck.left2 = trailer != null ? Number(trailer.getBoundingClientRect().left) : Number(this.truckRight.getBoundingClientRect().left);
    this.truck.items_height_left_1 = this.itemsHeightTable.left[0].itemsHeight;
    this.truck.items_height_left_2 = this.itemsHeightTable.left[1].itemsHeight;
    this.truck.items_height_left_3 = this.itemsHeightTable.left[2].itemsHeight;
    this.truck.items_height_right_1 = this.itemsHeightTable.right[0].itemsHeight;
    this.truck.items_height_right_2 = this.itemsHeightTable.right[1].itemsHeight;
    this.truck.items_height_right_3 = this.itemsHeightTable.right[2].itemsHeight;
    if (trailer != null){
      this.truck.items_height_left_4 = trailer != null ? this.itemsHeightTable.left[3].itemsHeight : 0;
      this.truck.items_height_left_5 = trailer != null ? this.itemsHeightTable.left[4].itemsHeight : 0;
      this.truck.items_height_left_6 = trailer != null ? this.itemsHeightTable.left[5].itemsHeight : 0;
      this.truck.items_height_right_4 = trailer != null ? this.itemsHeightTable.right[3].itemsHeight : 0;
      this.truck.items_height_right_5 = trailer != null ? this.itemsHeightTable.right[4].itemsHeight : 0;
      this.truck.items_height_right_6 = trailer != null ? this.itemsHeightTable.right[5].itemsHeight : 0;
    }
    this.items.forEach((item, i) => {
      let truckTop = this.leftBox?.getBoundingClientRect().top;
      let truckLeft = this.leftBox?.getBoundingClientRect().left;
      // const box = this.getBoxThatContainsPackage(this.items[i])
      if (this.isPackageOnAnyBox(item)) {
        const itemTop = Number(item.getBoundingClientRect().top);
        if (itemTop > (this.truckHeight + this.truck.top1)) {
          truckLeft = trailerBox != null ? Number(trailerBox.getBoundingClientRect().left) : this.rightBox?.getBoundingClientRect().left;
        }
        this.truck.packing_items_attributes[i].top = Number(this.truckHeight - (item.getBoundingClientRect().top - truckTop));
        this.truck.packing_items_attributes[i].left = Number(item.getBoundingClientRect().left - truckLeft);
      } else {
        this.truck.packing_items_attributes[i].top = null;
        this.truck.packing_items_attributes[i].left = null;
      }
    })
    this.saved = true;
  }

  async updateItemsPositionTop(distance: number) {
    const distanceDiff = distance - this.lastTopDistance;
    await this.magnetItems.forEach(item => {
      if (distanceDiff > 0) {
        item.style.top = String(item.getBoundingClientRect().top - distanceDiff) + 'px';
      }
      else {
        item.style.top = String(item.getBoundingClientRect().top + Math.abs(distanceDiff)) + 'px';
      }
    });
  }

  updateItemsPositionLeft(distance: number) {
    const distanceLeftDiff = distance - this.lastLeftDistance;
    this.magnetItems.forEach(item => {
      if (distanceLeftDiff > 0) {
        item.style.left = String(item.getBoundingClientRect().left - distanceLeftDiff) + 'px';
      }
      else {
        item.style.left = String(item.getBoundingClientRect().left + Math.abs(distanceLeftDiff)) + 'px';
      }
    });
  }

  setEvents() {
    if (!this.simple) {
      const t = this;
      window.addEventListener('scroll', async function (evt) {
        const distance_from_top: number = document.documentElement.scrollTop;
        await t.updateItemsPositionTop(distance_from_top);
        t.lastTopDistance = distance_from_top;
      });
      const planner = document.getElementById(this.plannerDivId);
      planner.addEventListener('scroll', function (evt) {
        const distance_from_left: number = planner.scrollLeft;
        t.updateItemsPositionLeft(distance_from_left);
        t.lastLeftDistance = distance_from_left;
      });
      planner.addEventListener('scroll', function (evt) {
        const distance_from_top: number = document.documentElement.scrollTop;
        t.updateItemsPositionTop(distance_from_top);
        t.lastTopDistance = distance_from_top;
      });

      const ro = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.setBoxFillPosition();
          this.loadPosition(t.getPackingPlan())
        }
      });
      ro.observe(document.querySelector('.ibox'));
    }
  }

  setView() {
    const trailerBoxLeft: any = document.getElementById('trailer-box-left');
    const gridVHozRight: any = document.querySelectorAll('#hoz-meter-right');
    const gridBox: any = document.getElementById('grid-box');
    const gridVHoz: any = document.querySelectorAll('#hoz-meter');
    const gridVert: any = document.querySelectorAll('#ver-meter');

    this.leftBox.style.height = this.truckHeight + 'px';
    this.leftBox.style.width = this.truckWidth + 'px';
    this.rightBox.style.height = this.truckHeight + 'px';
    this.rightBox.style.width = this.truckWidth + 'px';

    const trailerBoxRight: any = document.getElementById('trailer-box-right');
    const trailerGridBox: any = document.getElementById('trailer-grid-box');

    const trailerLeft: any = document.getElementById(this.trailerLeftDivId);
    const trailerRight: any = document.getElementById(this.trailerRightDivId);

    this.truckLeft.style.height = (this.truckHeight * 1.4) + 40 + 24 + 'px';
    this.truckLeft.style.width = (this.truckWidth * 1.15) + 40 + 'px';
    this.truckRight.style.height = (this.truckHeight * 1.4) + 40 + 24 + 'px';
    this.truckRight.style.width = (this.truckWidth * 1.15) + 40 + 'px';

    if(trailerLeft != null) {
      trailerBoxLeft.style.height = this.truckHeight + 'px';
      trailerBoxLeft.style.width = this.truckWidth + 'px';
      trailerBoxRight.style.height = this.truckHeight + 'px';
      trailerBoxRight.style.width = this.truckWidth + 'px';
      trailerLeft.style.height = (this.truckHeight * 1.4) + 40 + 24 + 'px';
      trailerLeft.style.width = this.truckWidth + 70 + 'px';
      trailerRight.style.height = (this.truckHeight * 1.4) + 40 + 24 + 'px';
      trailerRight.style.width = this.truckWidth + 70 + 'px';
      trailerGridBox.style.height = this.truckHeight + 40 + 'px';
      trailerGridBox.style.width = this.truckWidth + 40 + 'px';
    }

    gridBox.style.height = this.truckHeight + 40 + 'px';
    gridBox.style.width = this.truckWidth + 40 + 'px';

    gridVHoz.forEach(grid => {
      grid.style.width = this.truckWidth + 'px';
    });
    gridVHozRight.forEach(grid => {
      grid.style.width = this.truckWidth + 'px';
    });
    gridVert.forEach(grid => {
      grid.style.height = this.truckHeight + 'px';
    });
  }

  setBoxFillPosition() {
    const boxFillLeft: any = document.getElementById('box-fill-left');
    const boxFillRight: any = document.getElementById('box-fill-right');
    boxFillLeft.style.top = this.leftBox.getBoundingClientRect().top + 'px';
    boxFillLeft.style.left = this.leftBox.getBoundingClientRect().left + 'px';
    boxFillRight.style.top = this.rightBox.getBoundingClientRect().top + 'px';
    boxFillRight.style.left = this.rightBox.getBoundingClientRect().left + 'px';

    const trailerboxLeft: any = document.getElementById('trailer-box-left');
    const trailerboxRight: any = document.getElementById('trailer-box-right');
    const trailerboxFillLeft: any = document.getElementById('trailer-box-fill-left');
    const trailerboxFillRight: any = document.getElementById('trailer-box-fill-right');
    if (trailerboxLeft != null && trailerboxRight != null) {
      trailerboxFillLeft.style.top = trailerboxLeft.getBoundingClientRect().top + 'px';
      trailerboxFillLeft.style.left = trailerboxLeft.getBoundingClientRect().left + 'px';
      trailerboxFillRight.style.top = trailerboxRight.getBoundingClientRect().top + 'px';
      trailerboxFillRight.style.left = trailerboxRight.getBoundingClientRect().left + 'px';
    }
  }

  addItemsToDiv() {
    this.itemsParent.classList.add('align-items-center');
    for (let i = 0; i < this.truck.packing_items_attributes.length; i++) {
      if (this.projects.find(proj => proj.project_id === this.truck.packing_items_attributes[i].project_id) === undefined) {
        let newColor = true;
        let j = 0;
        while (newColor && j < this.colors.length) {
          const randomColor = this.colors[j];
          if (this.projects.find(project => project.color === randomColor) === undefined) {
            this.projects.push({ project_id: this.truck.packing_items_attributes[i].project_id, color: randomColor, name: this.truck.packing_items_attributes[i].project_name });
            newColor = false;
          } else {
            j++;
          }
        };
      }
      const div = document.createElement('div');
      div.classList.add('btn-outline-dark');
      div.classList.add('magnet');
      div.classList.add('id' + this.truck.packing_items_attributes[i].id);
      if (this.truck.packing_items_attributes[i].full_truck_depth) {
        div.classList.add('fullDepth');
      }
      div.id = 'item';
      div.style.zIndex = '0';
      div.style.position = 'relative';
      div.style.width = this.truck.packing_items_attributes[i].width + 'px';
      div.style.height = this.truck.packing_items_attributes[i].height + 'px';
      let color: string = '';
      div.addEventListener('click', (evt) => {
        this.checkCollidable();
        this.updateItemsHeightTable();
      }, false);
      const project = this.projects.find(proj => proj.project_id === this.truck.packing_items_attributes[i].project_id);
      if (project !== undefined) {
        color = project.color;
      }
      div.style.backgroundColor = color;
      const beforeDiv = document.createElement('div');
      beforeDiv.id = 'beforediv';
      beforeDiv.classList.add('justify-content-center');
      beforeDiv.classList.add('align-items-center');
      beforeDiv.classList.add('d-flex');
      const pack_item = this.truck.packing_items_attributes[i]
      const item_name = pack_item.name ? pack_item.name : `${pack_item.position}/?`
      const width_height = document.createTextNode(pack_item.project_visible_id + " " + item_name + ' - (' + pack_item.width + 'x' + pack_item.height + 'x' + pack_item.depth + ') ');
      const proj_name = document.createTextNode(project.name);
      if (!this.isPackageSmall(this.truck.packing_items_attributes[i])) {
        const h5 = document.createElement('h5');
        h5.appendChild(width_height);
        h5.appendChild(document.createElement('br'));
        h5.appendChild(proj_name);
        beforeDiv.appendChild(h5);
      } else {
        const span = document.createElement('span');
        span.classList.add("btn");
        span.classList.add("btn-sm");
        span.classList.add("btn-info");
        span.classList.add("d-flex");
        span.classList.add('justify-content-center');
        span.classList.add('align-items-center');
        span.setAttribute("data-toggle", "tooltip");
        span.setAttribute("data-placement", "top");
        span.setAttribute("data-html", "true");
        const text: string = pack_item.project_visible_id + " " + item_name + ' - (' + pack_item.width + 'x' + pack_item.height + 'x' + pack_item.depth + ') ' + project.name;
        span.setAttribute("title", text);
        const spanText = document.createTextNode("?");
        span.appendChild(spanText);
        span.style.paddingTop = "0";
        span.style.paddingBottom = "0";
        beforeDiv.appendChild(span);
      }
      div.appendChild(beforeDiv);
      this.itemsParent.appendChild(div);


      setTooltips();
    }
  }

  isPackageSmall(pack: any) {
    const textLength = 360;
    const textHeight = 30;
    for (let i = 1; i < 5; i++) {
      if (pack.width >= textLength / i) {
        if (pack.height >= textHeight * i) {
          return false;
        }
      }
    }
    return true;
  }

  show(element) {
    element.style.display = "blok";
    console.log(`SHOW: ${element}`);
  }

  hide(element) {
    element.style.display = "";
    console.log(`Hide: ${element}`);
  }

  isPackageOnBox(item, object_2) {
    let object = item.getBoundingClientRect();
    if (object.left + object.width <= object_2.left + object_2.width + 2 && object.left >= object_2.left - 2 &&
      object.top + object.height <= object_2.top + object_2.height + 2 && object.top >= object_2.top - 2) {
      return true
    }
    return false
  }

  checkCollidable() {
    this.saved = false
    this.collide = false;
    let beforeDiv;
    const reflectionsLeft: any = document.getElementsByClassName("reflections-left")[0];
    if (reflectionsLeft !== undefined) {
      this.leftBox.removeChild(reflectionsLeft);
    }
    let trailerLeftBoxes = document.querySelectorAll('#trailer-box-left');
    let trailerRightBoxes = document.querySelectorAll('#trailer-box-right');
    const cs = getComputedStyle(this.rightBox);
    const width = parseInt(cs.getPropertyValue('width'), 10);
    const height = parseInt(cs.getPropertyValue('height'), 10);
    let canvasLeft = document.createElement('canvas');
    canvasLeft.classList.add('reflections-left');
    canvasLeft.width = width;
    canvasLeft.height = height;
    const reflectionsRight: any = document.getElementsByClassName("reflections-right")[0];
    if (reflectionsRight !== undefined) {
      this.rightBox.removeChild(reflectionsRight);
    }
    let canvasRight = document.createElement('canvas');
    canvasRight.classList.add('reflections-right');
    canvasRight.width = width;
    canvasRight.height = height;
    const reflectionsTrailerLeft: any = document.getElementsByClassName("trailer-reflections-left")[0];
    if (reflectionsTrailerLeft !== undefined) {
      trailerLeftBoxes[0].removeChild(reflectionsTrailerLeft);
    }
    const reflectionsTrailerRight: any = document.getElementsByClassName("trailer-reflections-right")[0];
    if (reflectionsTrailerRight !== undefined) {
      trailerRightBoxes[0].removeChild(reflectionsTrailerRight);
    }
    let trailerCanvasLeft;
    let trailerCanvasRight;
    if (trailerLeftBoxes.length > 0) {
      trailerCanvasLeft = document.createElement('canvas');
      trailerCanvasLeft.classList.add('trailer-reflections-left');
      trailerCanvasLeft.width = width;
      trailerCanvasLeft.height = height;
      trailerCanvasRight = document.createElement('canvas');
      trailerCanvasRight.classList.add('trailer-reflections-right');
      trailerCanvasRight.width = width;
      trailerCanvasRight.height = height;
    }
    let ctxl = canvasLeft.getContext('2d');
    let ctxr = canvasRight.getContext('2d');
    for (let i = 0; i < this.items.length; i++) {
      let isInTheBox = false;
      beforeDiv = this.items[i].querySelectorAll('#beforediv');
      if (this.items[i] !== this.leftBox) {
        let object = this.items[i].getBoundingClientRect();
        let object_2 = this.leftBox.getBoundingClientRect();
        if (this.isPackageOnBox(this.items[i], object_2)) {
          isInTheBox = true;
          let y = object.top - object_2.top;
          let x = (canvasRight.width - (object.left - object_2.left)) - object.width;
          ctxr.globalAlpha = 0.6;
          if (this.items[i].classList.contains("fullDepth")) {
            ctxr.fillStyle = this.items[i].style.backgroundColor;
            ctxr.fillRect(x, y, object.width, object.height);
            ctxr.globalAlpha = 1;
            ctxr.fillStyle = "#000000";
            if (!this.isPackageSmall(object)) {
              const lineHeight = 15;
              const text: string[] = this.getLines(ctxr, beforeDiv[0].textContent, object.width);
              for (let i = 0; i < text.length; i++) {
                ctxr.fillText(text[i], x, y + (object.height / 2) + (i * lineHeight));
              }
            }
            ctxr.fillStyle = "#cccccc";
            ctxr.fill();
          } else {
            ctxr.fillStyle = "#cccccc";
            ctxr.fillRect(x, y, object.width, object.height);
            ctxr.fill();
          }
        }
      }
      if (this.items[i] !== this.rightBox) {
        let object = this.items[i].getBoundingClientRect()
        let object_2 = this.rightBox.getBoundingClientRect();
        if (this.isPackageOnBox(this.items[i], object_2)) {
          isInTheBox = true;
          let y = object.top - object_2.top;
          let x = (canvasLeft.width - (object.left - object_2.left)) - object.width;
          ctxl.globalAlpha = 0.6;
          if (this.items[i].classList.contains("fullDepth")) {
            ctxl.fillStyle = this.items[i].style.backgroundColor;
            ctxl.fillRect(x, y, object.width, object.height);
            ctxl.fillStyle = "#000000";
            if (!this.isPackageSmall(object)) {
              const lineHeight = 15;
              const text: string[] = this.getLines(ctxl, beforeDiv[0].textContent, object.width);
              for (let i = 0; i < text.length; i++) {
                ctxl.fillText(text[i], x, y + (object.height / 2) + (i * lineHeight));
              }
            }
            ctxl.fillStyle = "#cccccc";
            ctxl.fill();
          } else {
            ctxl.fillStyle = "#cccccc";
            ctxl.fillRect(x, y, object.width, object.height);
            ctxl.fill();
          }
        }
      }
      if (trailerLeftBoxes.length > 0) {
        let ctxtl = trailerCanvasLeft.getContext('2d');
        let ctxtr = trailerCanvasRight.getContext('2d');
        let object_2 = trailerLeftBoxes[0].getBoundingClientRect();
        if (this.items[i] !== trailerLeftBoxes[0]) {
          let object = this.items[i].getBoundingClientRect();
          if (this.isPackageOnBox(this.items[i], object_2)) {
            isInTheBox = true;
            let y = object.top - object_2.top;
            let x = (canvasRight.width - (object.left - object_2.left)) - object.width;
            ctxtr.globalAlpha = 0.6;
            if (this.items[i].classList.contains("fullDepth")) {
              ctxtr.fillStyle = this.items[i].style.backgroundColor;
              ctxtr.fillRect(x, y, object.width, object.height);
              ctxtr.globalAlpha = 1;
              ctxtr.fillStyle = "#000000";
              if (!this.isPackageSmall(object)) {
                const lineHeight = 15;
                const text: string[] = this.getLines(ctxtr, beforeDiv[0].textContent, object.width);
                for (let i = 0; i < text.length; i++) {
                  ctxtr.fillText(text[i], x, y + (object.height / 2) + (i * lineHeight));
                }
              }
              ctxtr.fillStyle = "#cccccc";
              ctxtr.fill();
            } else {
              ctxtr.fillStyle = "#cccccc";
              ctxtr.fillRect(x, y, object.width, object.height);
              ctxtr.fill();
            }
          }
        }
        if (this.items[i] !== trailerRightBoxes[0]) {
          let object = this.items[i].getBoundingClientRect()
          let object_2 = trailerRightBoxes[0].getBoundingClientRect();
          if (this.isPackageOnBox(this.items[i], object_2)) {
            isInTheBox = true;
            let y = object.top - object_2.top;
            let x = (canvasLeft.width - (object.left - object_2.left)) - object.width;
            ctxtl.globalAlpha = 0.6;
            if (this.items[i].classList.contains("fullDepth")) {
              ctxtl.fillStyle = this.items[i].style.backgroundColor;
              ctxtl.fillRect(x, y, object.width, object.height);
              ctxtl.fillStyle = "#000000";
              if (!this.isPackageSmall(object)) {
                const lineHeight = 15;
                const text: string[] = this.getLines(ctxtl, beforeDiv[0].textContent, object.width);
                for (let i = 0; i < text.length; i++) {
                  ctxtl.fillText(text[i], x, y + (object.height / 2) + (i * lineHeight));
                }
              }
              ctxtl.fillStyle = "#cccccc";
              ctxtl.fill();
            } else {
              ctxtl.fillStyle = "#cccccc";
              ctxtl.fillRect(x, y, object.width, object.height);
              ctxtl.fill();
            }
          }
        }
      }
      if (isInTheBox) {
        beforeDiv[0].style.border = '1px solid black';
      } else {
        beforeDiv[0].style.border = '2px solid red';
       this.collide = true;
      }
    }
    if (trailerLeftBoxes.length > 0) {
      trailerLeftBoxes[0].appendChild(trailerCanvasLeft);
      trailerRightBoxes[0].appendChild(trailerCanvasRight);
    }
    this.leftBox.appendChild(canvasLeft);
    this.rightBox.appendChild(canvasRight);
    for (let i = 0; i < this.items.length; i++) {
      let collidable = false;
      let object_1 = this.items[i].getBoundingClientRect();
      beforeDiv = this.items[i].querySelectorAll('#beforediv');
      for (let j = 0; j < this.items.length; j++) {
        if (this.items[i].childNodes[0] !== this.items[j].childNodes[0]) {
          let object_2 = this.items[j].getBoundingClientRect();
          if (object_1.left < object_2.left + object_2.width - 2 && object_1.left + object_1.width > object_2.left + 2 &&
            object_1.top < object_2.top + object_2.height - 2 && object_1.top + object_1.height > object_2.top + 2) {
            collidable = true;
          }
        }
      }
      if (collidable) {
        beforeDiv[0].style.border = '3px solid red';
        this.collide = true;
      }
    }
    if (!this.saved){
      this.savePackingPlan();
    }
    return this.collide;
    // }
  }

  getLines(ctx, text, maxWidth) {
    let words = text.split(" ");
    let lines: string[] = [];
    let currentLine: string = words[0];
    for (let i = 1; i < words.length; i++) {
      let word = words[i];
      let width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines
  }

  startedItemsTopPositions(isNew = false) {
    let items = [];
    if (isNew) {
      const its = this.itemsParent.querySelectorAll('#item');
      its.forEach(it => {
        this.newItems.forEach(ni => {
          if (it.classList.contains("id" + ni.id)) {
            items.push(it);
          }
        });
      });
    } else {
      items = this.itemsParent.querySelectorAll('#item');
    }
    if (items.length > 0) {
      let itemsWidth: number = 0;
      let itemsHeight: number = 0;
      let maxItemHeight: number = this.truck.packing_items_attributes[0].height;
      const parentRect = this.itemsParent.getBoundingClientRect();
      for (let i = 0; i < this.truck.packing_items_attributes.length; i++) {
        if (this.newItems.includes(this.truck.packing_items_attributes[i])) {
          items.forEach(it => {
            if (it.classList.contains("id" + this.truck.packing_items_attributes[i].id)) {
              if ((itemsWidth + this.truck.packing_items_attributes[i].width)/(parentRect.width - 30) >= 1){
                itemsHeight = itemsHeight + maxItemHeight + 5;
                itemsWidth = 0;
                this.itemsParent.style.height = itemsHeight + 'px';
                maxItemHeight = maxItemHeight < this.truck.packing_items_attributes[i].height? this.truck.packing_items_attributes[i].height : maxItemHeight;
              }
              it.style.top = String((parentRect.top + itemsHeight)) + 'px';
              it.style.left = String((parentRect.left + itemsWidth)) + 'px';
              itemsWidth = itemsWidth + this.truck.packing_items_attributes[i].width +5;
            }
          })
        }
      }
    }
  }

  updateItemsHeightTable() {
    const truckLeftRect = document.getElementById('box-fill-left').getBoundingClientRect();
    const truckRightRect = document.getElementById('box-fill-right').getBoundingClientRect();
    const trailerLeftRect = document.getElementById('trailer-box-fill-left')?.getBoundingClientRect();
    const trailerRightRect = document.getElementById('trailer-box-fill-right')?.getBoundingClientRect();
    this.itemsHeightTable = {
      left: [
        {
          side: "left",
          left: truckLeftRect.left,
          top: truckLeftRect.top,
          width: truckLeftRect.width / 3,
          height: truckLeftRect.height,
          itemsHeight: 0
        },
        {
          side: "left",
          left: truckLeftRect.left + truckLeftRect.width / 3,
          top: truckLeftRect.top,
          width: truckLeftRect.width / 3,
          height: truckLeftRect.height,
          itemsHeight: 0
        },
        {
          side: "left",
          left: truckLeftRect.left + (truckLeftRect.width / 3) * 2,
          top: truckLeftRect.top,
          width: truckLeftRect.width / 3,
          height: truckLeftRect.height,
          itemsHeight: 0
        },
      ],
      right: [
        {
          side: "right",
          left: truckRightRect.left,
          top: truckRightRect.top,
          width: truckRightRect.width / 3,
          height: truckRightRect.height,
          itemsHeight: 0
        },
        {
          side: "right",
          left: truckRightRect.left + truckRightRect.width / 3,
          top: truckRightRect.top,
          width: truckRightRect.width / 3,
          height: truckRightRect.height,
          itemsHeight: 0
        },
        {
          side: "right",
          left: truckRightRect.left + (truckRightRect.width / 3) * 2,
          top: truckRightRect.top,
          width: truckRightRect.width / 3,
          height: truckRightRect.height,
          itemsHeight: 0
        }
      ]
    }
    if (trailerLeftRect != null && trailerRightRect != null){
      this.itemsHeightTable.left.push(
        {
          side: "left",
          left: trailerLeftRect.left,
          top: trailerLeftRect.top,
          width: trailerLeftRect.width / 3,
          height: trailerLeftRect.height,
          itemsHeight: 0
        },
        {
          side: "left",
          left: trailerLeftRect.left + trailerLeftRect.width / 3,
          top: trailerLeftRect.top,
          width: trailerLeftRect.width / 3,
          height: trailerLeftRect.height,
          itemsHeight: 0
        },
        {
          side: "left",
          left: trailerLeftRect.left + (trailerLeftRect.width / 3) * 2,
          top: trailerLeftRect.top,
          width: trailerLeftRect.width / 3,
          height: trailerLeftRect.height,
          itemsHeight: 0
        },
      )
      this.itemsHeightTable.right.push(
        {
          side: "right",
          left: trailerRightRect.left,
          top: trailerRightRect.top,
          width: trailerRightRect.width / 3,
          height: trailerRightRect.height,
          itemsHeight: 0
        },
        {
          side: "right",
          left: trailerRightRect.left + trailerRightRect.width / 3,
          top: trailerRightRect.top,
          width: trailerRightRect.width / 3,
          height: trailerRightRect.height,
          itemsHeight: 0
        },
        {
          side: "right",
          left: trailerRightRect.left + (trailerRightRect.width / 3) * 2,
          top: trailerRightRect.top,
          width: trailerRightRect.width / 3,
          height: trailerRightRect.height,
          itemsHeight: 0
        }
      )
    }
    this.setItemsHeightTable()
    document.getElementById('plh-1').innerHTML = this.itemsHeightTable.left[0].itemsHeight.toFixed(0);
    document.getElementById('plh-2').innerHTML = this.itemsHeightTable.left[1].itemsHeight.toFixed(0);
    document.getElementById('plh-3').innerHTML = this.itemsHeightTable.left[2].itemsHeight.toFixed(0);
    document.getElementById('prh-1').innerHTML = this.itemsHeightTable.right[0].itemsHeight.toFixed(0);
    document.getElementById('prh-2').innerHTML = this.itemsHeightTable.right[1].itemsHeight.toFixed(0);
    document.getElementById('prh-3').innerHTML = this.itemsHeightTable.right[2].itemsHeight.toFixed(0);
    if (trailerLeftRect != null){
      document.getElementById('prh-1-trailer-left').innerHTML = this.itemsHeightTable.left[3].itemsHeight.toFixed(0);
      document.getElementById('prh-2-trailer-left').innerHTML = this.itemsHeightTable.left[4].itemsHeight.toFixed(0);
      document.getElementById('prh-3-trailer-left').innerHTML = this.itemsHeightTable.left[5].itemsHeight.toFixed(0);
      document.getElementById('prh-1-trailer-right').innerHTML = this.itemsHeightTable.right[3].itemsHeight.toFixed(0);
      document.getElementById('prh-2-trailer-right').innerHTML = this.itemsHeightTable.right[4].itemsHeight.toFixed(0);
      document.getElementById('prh-3-trailer-right').innerHTML = this.itemsHeightTable.right[5].itemsHeight.toFixed(0);
    }
  }

  setItemsHeightTable() {
    const items = document.querySelectorAll('#item');
    if (this.itemsHeightTable.left !== undefined) {
      this.itemsHeightTable.left.forEach(ihtp => {
        let lastTopItemHeight = 0;
        let truckPartRect = ihtp;
        for (let i = 0; i < items.length; i++) {
          let itemRect = items[i].getBoundingClientRect();
          for (let lastHeight = 0; lastHeight < truckPartRect.height; lastHeight += 10) {
            if (itemRect.left <= truckPartRect.left + truckPartRect.width && itemRect.left + itemRect.width >= truckPartRect.left &&
              itemRect.top <= truckPartRect.top + lastHeight + 2 && itemRect.top + itemRect.height > truckPartRect.top) {
              lastTopItemHeight = truckPartRect.height - lastHeight;
              if (lastTopItemHeight > ihtp.itemsHeight) {
                ihtp.itemsHeight = lastTopItemHeight;
              }
            }
          }
        }
      });
    };
    if (this.itemsHeightTable.right !== undefined) {
      this.itemsHeightTable.right.forEach(ihtp => {
        let lastTopItemHeight = 0;
        let truckPartRect = ihtp;
        for (let i = 0; i < items.length; i++) {
          let itemRect = items[i].getBoundingClientRect();
          for (let lastHeight = 0; lastHeight < truckPartRect.height; lastHeight += 10) {
            if (itemRect.left <= truckPartRect.left + truckPartRect.width && itemRect.left + itemRect.width >= truckPartRect.left &&
              itemRect.top <= truckPartRect.top + lastHeight + 2 && itemRect.top + itemRect.height > truckPartRect.top) {
              lastTopItemHeight = truckPartRect.height - lastHeight;
              if (lastTopItemHeight > ihtp.itemsHeight) {
                ihtp.itemsHeight = lastTopItemHeight;
              }
            }
          }
        }
      });
    };
  }

  async hello(packingData: PackingPlanData) {
    this.setEvents();
    function sort(a, b) {
      return a.project_id - b.project_id;
    }
    packingData.packing_items_attributes.sort(sort);
    this.truck = packingData;
    this.updateItemsHeightTable();

    this.setView()
    if (!this.simple) {
      this.addItemsToDiv();

      this.magnet.add(this.magnetItems);
      this.magnet.distance(15);
      this.magnet.enabledAlignOuter(true);

      const loaded = this.loadPosition(packingData);

      this.checkCollidable();
      this.setItemsHeightTable();
      this.updateItemsHeightTable();

      this.startedItemsTopPositions(!loaded);
    }
  }
}


