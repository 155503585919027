import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { RouteComponentProps } from "react-router-dom";
import Axios from 'axios'
import toastr from 'toastr'
import Select from 'react-select'
import * as i18n from "i18n-js"

import ListItem from './ListItem'
import Actions from './Actions'
import { NotificationData, ISelectOption } from "../Common/types"

interface TextSearchFieldProps {
  placeholder: string,
  inputText: string,
  searchBy: (newVal: string) => void
}

//@ts-ignore
i18n.translations = I18n.translations || {};

const TextSearchField = (props: TextSearchFieldProps) => {


  const [inputText, setInputText] = useState(props.inputText);


  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      props.searchBy(inputText);
    }
  };

  function cleanup() {
    setInputText('');
    props.searchBy('');
  }


  return (
    <div className="form-group input-group">
      <input type="text"
        className="form-control"
        onChange={(e) => setInputText(e.target.value)}
        onBlur={() => props.searchBy(inputText)}
        onKeyPress={handleKeypress}
        placeholder={props.placeholder}
        value={inputText}
      />
      {inputText.length > 0 &&
        <span className="input-group-btn">
          <button className="btn btn-default" onClick={() => { cleanup() }} ><strong>x</strong></button>
        </span>
      }
    </div>
  )
}

export default TextSearchField
