import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { RouteComponentProps } from "react-router-dom";
import ReactPaginate from "react-paginate"
import Axios from 'axios'
import Select from 'react-select'
import * as i18n from "i18n-js"

import ListItem from './ListItem'
import Paging from '../Notifications/Paging'
import { ProjectData, ISelectOption } from "../Common/types"
import { URLParamsReader } from "../Common/URLParamsReader"
import TextSearchField from '../Notifications/TextSearchField';

interface ListProps extends RouteComponentProps<any> {
  history: string[],
  location: { pathname: string, search: string }
}

//@ts-ignore
i18n.translations = I18n.translations || {};

const List = (props: ListProps) => {

  const urlParams = new URLParamsReader(props.location.search, "status=5,DELIVERED");

  const [settings, setSettings] = useState({
    sidx: urlParams.getSidx('created_at'),
    sord: urlParams.getSord(),
    rows: urlParams.getRows(),
    page: urlParams.getPage(),
    customer: urlParams.getISelect('customer'),
    manager: urlParams.getISelect('manager'),
    query: urlParams.getString('query'),
    order_id: urlParams.getString('order_id'),
    status: urlParams.getISelectArray('status')
  });

  const
    [totalLength, setTotalLength] = useState(0),
    [projects, setProjects] = useState(new Array<ProjectData>()),
    [lastPage, setLastPage] = useState(1),
    [customers, setCustomers] = useState(new Array<ISelectOption>()),
    [managers, setManagers] = useState(new Array<ISelectOption>()),
    [statuses, setStatuses] = useState(new Array<ISelectOption>()),
    handleRefreshClick = () => {
      loadData()
    },
    handleNextPageClick = () => {
      if (settings.page < lastPage) {
        setSettings({ ...settings, page: settings.page + 1 })
      }
    },
    handlePrevPageClick = () => {
      if (settings.page > 1) {
        setSettings({ ...settings, page: settings.page - 1 })
      }
    },
    onRowsChange = (newRows: number) => {
      setSettings({ ...settings, page: 1, rows: newRows })
    };

  function sortBy(column: string): void {
    if (settings.sidx === column) {
      const newdir = (settings.sord === 'desc' ? 'asc' : 'desc')
      setSettings({ ...settings, sord: newdir })
    }
    else {
      setSettings({ ...settings, sidx: column })
    }
    handleRefreshClick();
  }

  function getSortIcon(column: string): string {
    if (column === settings.sidx) {
      return (settings.sord === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up');
    }
    else {
      return 'fa fa-sort';
    }
  }

  function recalcLastPage(): void {
    setLastPage(Math.ceil(totalLength / settings.rows))
  }

  function loadFiltersData() {
    Axios({ url: `/projects/fdata.json` })
      .then((res) => {
        setCustomers(res.data.customers)
        setManagers(res.data.managers)
        setStatuses(res.data.statuses)

      })
      .catch((err) => {
        console.log(err)
      })
  }

  function loadData() {
    let url_params = `rows=${settings.rows}&page=${settings.page}&sidx=${settings.sidx}&sord=${settings.sord}`;
    if (settings.customer) {
      url_params = `${url_params}&customer=${settings.customer.value},${settings.customer.label}`
    }
    if (settings.manager) {
      url_params = `${url_params}&manager=${settings.manager.value},${settings.manager.label}`
    }
    if (settings.status.length > 0) {
      const st_all = settings.status.map((st) => `${st.value},${st.label}`).join(":")
      url_params = `${url_params}&status=${st_all}`
    }
    if (`${settings.order_id}`.length > 0) {
      url_params = `${url_params}&order_id=${settings.order_id}`
    }
    if (`${settings.query}`.length > 0) {
      url_params = `${url_params}&query=${settings.query}`
    }
    props.history.push(`${props.location.pathname}?${url_params}`)

    Axios({
      method: 'GET',
      url: `/projects/data.json?${url_params}`,
      headers: {
        accept: 'application/json'
      }
    })
      .then((res) => {
        if (res.data.projects.length > 0) {
          setProjects(res.data.projects)
          setTotalLength(res.data.records)
        } else {
          setProjects([])
          setTotalLength(0)
        }

        // if (settings.page > 1 && settings.page > lastPage) {
        //   setSettings({ ...settings, page: 1 })
        // }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlePageClick = (event) => {
    setSettings({ ...settings, page: event.selected + 1 })
  }


  function searchByOrderId(newVal: string) {
    if (settings.order_id !== newVal) {
      setSettings({ ...settings, order_id: newVal })
    }
  }

  function searchByQuery(newVal: string) {
    if (settings.query !== newVal) {
      setSettings({ ...settings, query: newVal })
    }
  }

  function visibleItems(): React.ReactNode[] {
    return projects.map((n) => (
      <ListItem
        key={n.id}
        project={n}
      />
    ))
  }

  function handleCustomerChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, customer: opt })
    } else {
      setSettings({ ...settings, customer: null })
    }
  }

  function handleManagerChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, manager: opt })
    } else {
      setSettings({ ...settings, manager: null })
    }
  }

  function handleStatusChange(opt: ISelectOption[]): void {
    if (opt) {
      setSettings({ ...settings, status: opt })
    } else {
      setSettings({ ...settings, status: null })
    }
  }

  useEffect(() => {
    recalcLastPage()
  }, [totalLength, settings])


  useEffect(() => {
    loadData();
  }, [settings])

  useEffect(() => {
    loadFiltersData();
  }, [])

  return (
    <div className="wrapper wrapper-content">

      <div className="ibox-content m-b-sm border-bottom">
        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <h2>Projects ({totalLength})</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <TextSearchField
              inputText={settings.query}
              placeholder={"Project ID or name"}
              searchBy={searchByQuery} ></TextSearchField>
          </div>
          <div className="col-sm-1">
            <TextSearchField
              inputText={settings.order_id}
              placeholder={"Order Id"}
              searchBy={searchByOrderId} ></TextSearchField>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Customer"
                options={customers}
                defaultValue={settings.customer}
                onChange={val => handleCustomerChange(val)}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Sales manager"
                options={managers}
                defaultValue={settings.manager}
                onChange={val => handleManagerChange(val)}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <Select
                isMulti
                placeholder="Status"
                options={statuses}
                defaultValue={settings.status}
                onChange={val => handleStatusChange(val)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <Paging
              onRowsChange={onRowsChange}
              onNextClick={handleNextPageClick}
              onPrevClick={handlePrevPageClick}
              rows={settings.rows}
              page={settings.page}
              lastPage={lastPage}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 animated fadeInRight">
          <div className="mail-box">
            <table className="footable table table-hover table-mail footable-loaded ">
              <thead>
                <tr>
                  <th>#
                  </th>
                  <th className="mail-subject footable-visible footable-sortable" onClick={() => sortBy('name')}>
                    Name
                    <span> &nbsp;&nbsp;&nbsp;
                      <i className={getSortIcon('name')}></i>
                    </span>
                  </th>
                  <th className="mail-subject footable-visible footable-sortable" onClick={() => sortBy('order_id')}>
                    Order ID
                    <span> &nbsp;&nbsp;&nbsp;
                      <i className={getSortIcon('order_id')}></i>
                    </span>
                  </th>
                  <th>Truck</th>
                  <th>Price</th>
                  <th className="mail-date footable-visible footable-sortable" onClick={() => sortBy('order_date')}>
                    Order date &nbsp;&nbsp;&nbsp;
                    <span>
                      <i className={getSortIcon('order_date')}></i>
                    </span>
                  </th>
                  <th className="mail-date footable-visible footable-sortable" onClick={() => sortBy('deadline')}>
                    Deadline &nbsp;&nbsp;&nbsp;
                    <span>
                      <i className={getSortIcon('deadline')}></i>
                    </span>
                  </th>
                  <th>Code</th>
                  <th>Customer</th>
                  <th>Sales Manager</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {visibleItems()}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={12} className="footable-visible">
                    <ReactPaginate id="react-paginate"
                      // labels

                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      breakLabel={'…'}

                      disabledClassName={'disabled'}

                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination pull-right'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}

                      // functionality
                      initialPage={settings.page - 1}
                      pageCount={lastPage} //total pages
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      //   forcePage={5}
                      onPageChange={(event) => { handlePageClick(event) } /*Fxn when page clicked*/}
                      disableInitialCallback={true}
                    />

                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default List
