import React, { useState } from "react";
import axios from "axios";
import toastr from "toastr";
import Rails from "@rails/ujs";

interface TruckInfo {
  forwarder: string;
  plate_license: string;
  vehicle: string;
  driver: string;
  driver_phone: string;
  forklift: string;
  id: number;
}

interface TruckInfoProps {
  truck: TruckInfo;
}

const TruckInfo = (props: TruckInfoProps) => {
  const [edited, setEdited] = useState(false),
    [truckInfo, setTruckInfo] = useState(props.truck),
    patchTruckInfo = () => {
      axios
        .patch(`/trucks/${props.truck.id}.json`, truckInfo, {
          headers: {
            "X-CSRF-Token": Rails.csrfToken(),
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          toastr.success("New truck info saved", "Success");
          setEdited(false);
        });
    },
    updateTruckInfo = (name: string, value: string) => {
      const obj = {
        ...truckInfo,
      };
      obj[name] = value;
      setTruckInfo(obj);
    },
    revertChanges = () => {
      setTruckInfo(props.truck);
      setEdited(false);
    };

  return edited ? (
    <div className="ibox-content">
      <div className="float-right mb-2">
        <button className="btn p-0 mr-4" onClick={() => revertChanges()}>
          <i className="fa fa-undo" aria-hidden="true"></i>
        </button>
        <button className="btn p-0 mr-4" onClick={() => patchTruckInfo()}>
          <i className="fa fa-floppy-o" aria-hidden="true"></i>
        </button>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 text-sm-right">
          <dt>Forwarder:</dt>
        </label>
        <div className="col-lg-3">
          <input
            value={truckInfo.forwarder}
            onChange={(e) => updateTruckInfo(e.target.name, e.target.value)}
            className="form-control"
            type="text"
            name="forwarder"
            id="truck_forwarder"
          />
        </div>
        <label className="col-lg-3 text-sm-right">
          <dt>Plate license:</dt>
        </label>
        <div className="col-lg-3">
          <input
            value={truckInfo.plate_license}
            onChange={(e) => updateTruckInfo(e.target.name, e.target.value)}
            className="form-control"
            type="text"
            name="plate_license"
            id="truck_plate_license"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 text-sm-right">
          <dt>Vehicle:</dt>
        </label>
        <div className="col-lg-3">
          <input
            value={truckInfo.vehicle}
            onChange={(e) => updateTruckInfo(e.target.name, e.target.value)}
            className="form-control"
            type="text"
            name="vehicle"
            id="truck_vehicle"
          />
        </div>
        <label className="col-lg-3 text-sm-right">
          <dt>Driver:</dt>
        </label>
        <div className="col-lg-3">
          <input
            value={truckInfo.driver}
            onChange={(e) => updateTruckInfo(e.target.name, e.target.value)}
            className="form-control"
            type="text"
            name="driver"
            id="truck_driver"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 text-sm-right">
          <dt>Driver phone:</dt>
        </label>
        <div className="col-lg-3">
          <input
            value={truckInfo.driver_phone}
            onChange={(e) => updateTruckInfo(e.target.name, e.target.value)}
            className="form-control"
            type="text"
            name="driver_phone"
            id="truck_driver_phone"
          />
        </div>
        <label className="col-lg-3 text-sm-right">
          <dt>Forklift driver:</dt>
        </label>
        <div className="col-lg-3">
          <input
            value={truckInfo.forklift}
            onChange={(e) => updateTruckInfo(e.target.name, e.target.value)}
            className="form-control"
            type="text"
            name="forklift"
            id="truck_forklift"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="ibox-content">
      <div className="float-right mb-2">
        <button className="btn p-0" onClick={() => setEdited(true)}>
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </button>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 text-sm-right">
          <dt>Forwarder:</dt>
        </label>
        <div className="col-lg-3">{truckInfo.forwarder}</div>
        <label className="col-lg-3 text-sm-right">
          <dt>Plate license:</dt>
        </label>
        <div className="col-lg-3">{truckInfo.plate_license}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 text-sm-right">
          <dt>Vehicle:</dt>
        </label>
        <div className="col-lg-3">{truckInfo.vehicle}</div>
        <label className="col-lg-3 text-sm-right">
          <dt>Driver:</dt>
        </label>
        <div className="col-lg-3">{truckInfo.driver}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 text-sm-right">
          <dt>Driver phone:</dt>
        </label>
        <div className="col-lg-3">{truckInfo.driver_phone}</div>
        <label className="col-lg-3 text-sm-right">
          <dt>Forklift driver:</dt>
        </label>
        <div className="col-lg-3">{truckInfo.forklift}</div>
      </div>
    </div>
  );
};
export default TruckInfo;
