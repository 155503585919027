import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import TopListItem from './TopListItem'
import Axios from 'axios'
import toastr from 'toastr'
import * as i18n from "i18n-js"

import { NotificationData } from "../Common/types"

//@ts-ignore
i18n.translations = I18n.translations || {};

const TopList = () => {
  const [unread, setUnread] = useState(null),
    [notifications, setNotifications] = useState(new Array<NotificationData>()),

    handleReadClick = id => {
      manage(id, 'read')
    };

  function manage(id, action) {
    Axios({
      method: 'PUT',
      url: `/notifications/manage_selected`,
      data: { ids: id, operation: action },
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        if (res.data.status == 'success') {
          // toastr.success(res.data.message, I18n.t('admin.success'))
          loadNotifications()
        } else {
          toastr.error(res.data.message, i18n.t('error'))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function loadNotifications() {
    Axios({
      method: 'GET',
      url: `/notifications/top_data.json`,
      headers: {
        accept: 'application/json'
      }
    })
      .then((res) => {
        if (res.data.notifications.length > 0) {
          setNotifications(res.data.notifications)
          setUnread(res.data.unread)
        } else {
          setNotifications([])
          setUnread(null)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    loadNotifications()
  }, [])

  return (
    <li className="dropdown d-flex">
      <a className="dropdown-toggle count-info" data-toggle="dropdown" href="#" aria-expanded="true">
        <i className="fa fa-bell" style={{ fontSize: 18, color: '#999c9e' }}></i>
        {unread > 0 && <span className="label label-success" style={{ marginLeft: 5 }}>{unread}</span>}
      </a>
      <ul className="dropdown-menu dropdown-messages dropdown-menu-right">
        <div style={{ height: 'auto', maxHeight: 350, overflowX: 'hidden' }}>
          {notifications.length
            ?
            notifications.map((n) => (
              <TopListItem
                key={n.id}
                notification={n}
                onReadClick={handleReadClick}
              />
            ))
            :
            <li>
              <div className="dropdown-messages-box text-center">
                {i18n.t('no_notifications_to_show')}
              </div>
            </li>
          }
        </div>
        <li>
          <div className="text-center link-block">
            <a href="/notifications" className="dropdown-item"><strong>{i18n.t('show_all_notifications')}</strong></a>
          </div>
        </li>
      </ul>
    </li>
  )
}

export default TopList
