import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { RouteComponentProps } from "react-router-dom";
import ReactPaginate from "react-paginate"
import Axios from 'axios'
import toastr from 'toastr'
import Select from 'react-select'
import * as i18n from "i18n-js"

import ListItem from './ListItem'
import Actions from './Actions'
import Paging from './Paging'
import { NotificationData, ISelectOption } from "../Common/types"
import { URLParamsReader } from "../Common/URLParamsReader"
import TextSearchField from './TextSearchField';

interface ListProps extends RouteComponentProps<any> {
  history: string[],
  location: { pathname: string, search: string }
}

//@ts-ignore
i18n.translations = I18n.translations || {};

const List = (props: ListProps) => {

  const urlParams = new URLParamsReader(props.location.search);

  const [settings, setSettings] = useState({
    sidx: urlParams.getSidx('sent_at'),
    sord: urlParams.getSord(),
    rows: urlParams.getRows(),
    page: urlParams.getPage(),
    unread: urlParams.getInt('unread'),
    customer: urlParams.getISelect('customer'),
    manager: urlParams.getISelect('manager'),
    proj_state: urlParams.getISelect('proj_state'),
    truck: urlParams.getString('truck'),
    project: urlParams.getString('project'),
    query: urlParams.getString('query'),
  });

  const [allChecked, setAllChecked] = useState(false),
    [totalLength, setTotalLength] = useState(0),
    [notifications, setNotifications] = useState(new Array<NotificationData>()),
    [selectedItems, setSelectedItems] = useState(new Array<number>()),
    [lastPage, setLastPage] = useState(1),
    [customers, setCustomers] = useState(new Array<ISelectOption>()),
    [managers, setManagers] = useState(new Array<ISelectOption>()),
    [proj_states, setProjStates] = useState(new Array<ISelectOption>()),
    handleCheckboxClick = (notification: NotificationData, checked: boolean) => {
      if (checked) {
        setSelectedItems([...selectedItems, notification.id])
      } else {
        setSelectedItems(selectedItems.filter(n => n != notification.id))
      }
    },
    handleReadClick = () => {
      manageSelected('read')
    },
    handleCheckClick = () => {
      if (window.confirm(i18n.t('are_you_sure'))) manageSelected('confirm')
    },
    handleTrashClick = () => {
      if (window.confirm(i18n.t('are_you_sure'))) manageSelected('destroy')
    },
    handleRefreshClick = () => {
      loadNotifications()
      setSelectedItems([])
      setAllChecked(false)
    },
    handleNextPageClick = () => {
      if (settings.page < lastPage) {
        setSettings({ ...settings, page: settings.page + 1 })
      }
    },
    handlePrevPageClick = () => {
      if (settings.page > 1) {
        setSettings({ ...settings, page: settings.page - 1 })
      }
    },
    onRowsChange = (newRows: number) => {
      setSettings({ ...settings, page: 1, rows: newRows })
    };

  function sortBy(column: string): void {
    if (settings.sidx === column) {
      const newdir = (settings.sord === 'desc' ? 'asc' : 'desc')
      setSettings({ ...settings, sord: newdir })
    }
    else {
      setSettings({ ...settings, sidx: column })
    }
    handleRefreshClick();
  }

  function getSortIcon(column: string): string {
    if (column === settings.sidx) {
      return (settings.sord === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up');
    }
    else {
      return 'fa fa-sort';
    }
  }

  function recalcLastPage(): void {
    setLastPage(Math.ceil(totalLength / settings.rows))
  }

  function manageSelected(action: string): void {
    Axios({
      method: 'PUT',
      url: `/notifications/manage_selected`,
      data: { ids: selectedItems, operation: action },
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        if (res.data.status == 'success') {
          toastr.success(res.data.message, i18n.t('success'))
          setTimeout(() => {
            // handleRefreshClick()
            window.location.reload()
          }, 1000)
        } else {
          toastr.error(res.data.message, i18n.t('error'))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function loadFiltersData() {
    Axios({ url: `/notifications/fdata.json` })
      .then((res) => {
        setCustomers(res.data.customers)
        setManagers(res.data.managers)
        setProjStates(res.data.proj_states)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function loadNotifications() {
    let url_params = `rows=${settings.rows}&page=${settings.page}&sidx=${settings.sidx}&sord=${settings.sord}`;
    if (settings.unread > 0) {
      url_params = `${url_params}&unread=1`
    }
    if (settings.customer) {
      url_params = `${url_params}&customer=${settings.customer.value},${settings.customer.label}`
    }
    if (settings.manager) {
      url_params = `${url_params}&manager=${settings.manager.value},${settings.manager.label}`
    }
    if (settings.proj_state) {
      url_params = `${url_params}&proj_state=${settings.proj_state.value},${settings.proj_state.label}`
    }
    if (`${settings.query}`.length > 0) {
      url_params = `${url_params}&query=${settings.query}`
    }
    if (`${settings.project}`.length > 0) {
      url_params = `${url_params}&project=${settings.project}`
    }
    if (`${settings.truck}`.length > 0) {
      url_params = `${url_params}&truck=${settings.truck}`
    }
    props.history.push(`/notifications?${url_params}`)

    Axios({
      method: 'GET',
      url: `/notifications/data.json?${url_params}`,
      headers: {
        accept: 'application/json'
      }
    })
      .then((res) => {
        if (res.data.notifications.length > 0) {
          setNotifications(res.data.notifications)
          setTotalLength(res.data.records)
        } else {
          setNotifications([])
          setTotalLength(0)
        }

        // if (settings.page > 1 && settings.page > lastPage) {
        //   setSettings({ ...settings, page: 1 })
        // }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlePageClick = (event) => {
    setSettings({ ...settings, page: event.selected + 1 })
  }

  function searchByQuery(newVal: string) {
    if (settings.query !== newVal) {
      setSettings({ ...settings, query: newVal })
    }
  }

  function searchByProject(newVal: string) {
    if (settings.project !== newVal) {
      setSettings({ ...settings, project: newVal })
    }
  }

  function searchByTruck(newVal: string) {
    if (settings.truck !== newVal) {
      setSettings({ ...settings, truck: newVal })
    }
  }

  function notificationsListItems(): React.ReactNode[] {
    return notifications.map((n) => (
      <ListItem
        key={n.id}
        notification={n}
        isChecked={selectedItems.find(i => { return i === n.id || false }) !== undefined}
        onCheckboxClick={handleCheckboxClick}
      />
    ))
  }

  function handleCustomerChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, customer: opt })
    } else {
      setSettings({ ...settings, customer: null })
    }
  }

  function handleManagerChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, manager: opt })
    } else {
      setSettings({ ...settings, manager: null })
    }
  }

  function handleProjStateChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, proj_state: opt })
    } else {
      setSettings({ ...settings, proj_state: null })
    }
  }

  useEffect(() => {
    recalcLastPage()
  }, [totalLength, settings])


  useEffect(() => {
    if (selectedItems.length) {
      setSelectedItems([])
      setAllChecked(false)
    } else {
      if (allChecked) {
        let ids = notifications.map(n => { return n.id })
        setSelectedItems(ids)
      } else {
        setSelectedItems([])
      }

      setAllChecked(allChecked)
    }
  }, [allChecked])

  useEffect(() => {
    loadNotifications();
  }, [settings])

  useEffect(() => {
    loadFiltersData();
  }, [])

  return (
    <div className="wrapper wrapper-content">

      <div className="ibox-content m-b-sm border-bottom">
        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <h2>{i18n.t('notifications')} ({totalLength})</h2>
          </div>
        </div>


        <div className="row">
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Customer"
                options={customers}
                defaultValue={settings.customer}
                onChange={val => handleCustomerChange(val)}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Sales manager"
                options={managers}
                defaultValue={settings.manager}
                onChange={val => handleManagerChange(val)}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Proj State"
                options={proj_states}
                defaultValue={settings.proj_state}
                onChange={val => handleProjStateChange(val)}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <TextSearchField
              inputText={settings.project}
              placeholder={"Project ID or name"}
              searchBy={searchByProject} ></TextSearchField>

          </div>
          <div className="col-sm-2">
            <TextSearchField
              inputText={settings.truck}
              placeholder={"Truck ID or name"}
              searchBy={searchByTruck} ></TextSearchField>
          </div>
          <div className="col-sm-2">

            <TextSearchField
              inputText={settings.truck}
              placeholder={"Subject or message"}
              searchBy={searchByQuery} ></TextSearchField>
          </div>
        </div>


        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <div className="mail-tools tooltip-demo m-t-md">

              <Actions
                onReadClick={handleReadClick}
                onCheckClick={handleCheckClick}
                onTrashClick={handleTrashClick}
                onRefreshClick={handleRefreshClick}
                selectedItems={selectedItems.length}
              />

              <Paging
                onRowsChange={onRowsChange}
                onNextClick={handleNextPageClick}
                onPrevClick={handlePrevPageClick}
                rows={settings.rows}
                page={settings.page}
                lastPage={lastPage}
              />
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-lg-12 animated fadeInRight">
          <div className="mail-box">
            <table className="footable footable-loaded table table-hover table-mail">
              <thead>
                <tr>
                  <th className="check-mail" onClick={() => setAllChecked(!allChecked)}>
                    <input
                      type="checkbox"
                      checked={allChecked}
                      className="i-checks"
                      onChange={() => setAllChecked(!allChecked)}
                    />
                  </th>
                  <th className="mail-subject" onClick={() => sortBy('subject')}>
                    {i18n.t('subject')}
                    <span> &nbsp;&nbsp;&nbsp;
                      <i className={getSortIcon('subject')}></i>
                    </span>
                  </th>
                  <th> {i18n.t('message')}</th>
                  <th>Project</th>
                  <th>State</th>
                  <th>Order</th>
                  <th>Customer</th>
                  <th>Truck</th>
                  <th className="mail-date footable-visible footable-sortable" onClick={() => sortBy('sent_at')}>
                    {i18n.t('sent_at')}&nbsp;&nbsp;&nbsp;
                    <span>
                      <i className={getSortIcon('sent_at')}></i>
                    </span>
                  </th>
                  <th className="mail-date" onClick={() => sortBy('read_at')}>
                    {i18n.t('read_at')} &nbsp;&nbsp;&nbsp;

                    <span>
                      <i className={getSortIcon('read_at')}></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {notificationsListItems()}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={11} className="footable-visible">

                    <ReactPaginate id="react-paginate"
                      // labels

                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      breakLabel={'…'}

                      disabledClassName={'disabled'}

                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination float-right'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}

                      // functionality
                      initialPage={settings.page - 1}
                      pageCount={lastPage} //total pages
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      //   forcePage={5}
                      onPageChange={(event) => { handlePageClick(event) } /*Fxn when page clicked*/}
                      disableInitialCallback={true}
                    />

                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>



    </div>
  )
}

export default List
