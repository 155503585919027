import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Rails from "@rails/ujs";
import axios from 'axios';
import * as i18n from "i18n-js";
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import { CategoryData, int } from "../Common/types";

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface CategoryItemProps {
  category: CategoryData,
  categories: CategoryData[],
  depth: int,
  onAddClick: (cat: CategoryData) => void,
  onDeleteClick: (cat: CategoryData) => void
}

const CategoryItem = (props: CategoryItemProps) => {
  const [subcategories, setSubcategories] = useState(new Array<CategoryData>()),
    [hasSubcategories, setHasSubcategories] = useState(false),
    [edited, setEdited] = useState(props.category.id === 0),
    [name, setName] = useState(props.category.name);

  useEffect(() => {
    const newSubs = props.categories.filter((c) => c.parent_category_id === props.category.id)
    setSubcategories(newSubs);
    setHasSubcategories(newSubs.length > 0);
  }, [props.categories])

  function updateName(newName: string) {
    setName(newName)
    setEdited(false)

    const headers = {
      "X-CSRF-Token": Rails.csrfToken(),
      "Content-Type": "application/json"
    }

    if (props.category.id) {
      axios.put(`/categories/${props.category.id}.json`, {
        category: { name: newName }
      }, { headers })
        .then((res) => {
        });
    } else {
      axios.post('/categories.json', {
        category:
        {
          name: newName,
          parent_category_id: props.category.parent_category_id
        }
      }, { headers })
        .then((res) => {
          props.onAddClick(res.data.category)
        });
    }
  }


  function destroyItem(): void {

    axios({
      method: 'DELETE',
      url: `/categories/${props.category.id}.json`,
      data: {},
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        if (res.data.status == 'success') {
          toastr.success("Deleted", i18n.t('success'))
          props.onDeleteClick(props.category);
        } else {
          toastr.error(res.data.message, i18n.t('error'))
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  function addNewSub() {
    setSubcategories([...subcategories, { id: 0, name: '', parent_category_id: props.category.id }])
    setHasSubcategories(true);
  }

  return (<React.Fragment key={props.category.id}>
    <ListItem disablePadding sx={{ paddingLeft: props.depth * 16 }} >


      {!edited && <ListItemText primary={name} />}
      {edited && <TextField id="outlined-basic"
        variant="outlined"
        autoFocus={true}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          updateName(event.target.value);
        }}
        value={name} />}

      {!edited &&
        <div style={{ paddingRight: (1 / (props.depth + 1)) * 160 }}>
          <IconButton edge="start" aria-label="add" onClick={addNewSub}>
            <AddIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={destroyItem}>
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="edit" onClick={() => setEdited(true)}>
            <EditIcon />
          </IconButton>
        </div>
      }

    </ListItem>
    {
      hasSubcategories ? (
        <List component="div" disablePadding>
          {subcategories.map((c) =>
            <CategoryItem
              key={c.id}
              categories={props.categories}
              category={c}
              depth={props.depth + 1}
              onAddClick={props.onAddClick}
              onDeleteClick={props.onDeleteClick}
            />
          )}
        </List>
      ) : null
    }
  </React.Fragment >
  );
}

export default CategoryItem
