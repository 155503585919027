import { Button } from '@mui/material';
import Rails from '@rails/ujs';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { ISelectOption, ProjectTaskData } from '../Common/types';
import { URLParamsReader } from '../Common/URLParamsReader';
import Paging from '../Notifications/Paging';
import TextSearchField from '../Notifications/TextSearchField';
import { AllPriorities } from './PriorityField';
import { AllProgressStatuses } from './StatusField';
import TableHeader from './TableHeader';
import TaskRow from './TaskRow';

interface ISearchContext {
  sortBy: (column: string) => void;
  getSortIcon: (column: string) => string;
}

interface IRoleContext {
  hasRole: (roleName: string) => boolean;
}

// export const SearchContext = React.createContext(
//   [{ sortBy: (column: string) => { }, getSortIcon: (column: string) => '' }, (c: ISearchContext) => { }] as [ISearchContext, Dispatch<SetStateAction<ISearchContext>>]);

export const SearchContext = React.createContext<ISearchContext>(
  {} as ISearchContext
);

export const RoleContext = React.createContext<IRoleContext>(
  {} as IRoleContext
);

interface ListProps extends RouteComponentProps<any> {
  history: string[];
  location: { pathname: string; search: string };
  roles: string[];
}

const List = (props: ListProps) => {
  const urlParams = new URLParamsReader(
    props.location.search,
    'status=todo,TODO'
  );

  const [settings, setSettings] = useState({
    tasktype: urlParams.getTaskTypeISelect('tasktype'),
    sidx: urlParams.getSidx('task_id'),
    sord: urlParams.getSord(),
    rows: urlParams.getRows(),
    page: urlParams.getPage(),
    priority: urlParams.getISelect('priority'),
    machine: urlParams.getString('machine'),
    operator: urlParams.getString('operator'),
    timeline: urlParams.getString('timeline'),
    status: urlParams.getISelect('status'),
  });

  const [selectedItems, setSelectedItems] = useState(new Array<number>()),
    [totalLength, setTotalLength] = useState(0),
    [projectsTasks, setProjectsTasks] = useState(new Array<ProjectTaskData>()),
    [lastPage, setLastPage] = useState(1),
    [taskTypes, setTaskTypes] = useState(new Array<ISelectOption>()),
    [machines, setMachines] = React.useState<ISelectOption[]>([]),
    [allChecked, setAllChecked] = useState(false),
    handleCheckboxClick = (projectTask: ProjectTaskData, checked: boolean) => {
      if (checked) {
        setSelectedItems([...selectedItems, projectTask.id]);
      } else {
        setSelectedItems(selectedItems.filter((n) => n != projectTask.id));
      }
    },
    onCheckboxAllClick = () => {
      if (selectedItems.length === projectsTasks.length) {
        setSelectedItems([]);
      } else {
        setSelectedItems(projectsTasks.map((pt) => pt.id));
      }
    },
    handleRefreshClick = () => {
      loadData();
    },
    handleNextPageClick = () => {
      if (settings.page < lastPage) {
        setSettings({ ...settings, page: settings.page + 1 });
      }
    },
    handlePrevPageClick = () => {
      if (settings.page > 1) {
        setSettings({ ...settings, page: settings.page - 1 });
      }
    },
    onRowsChange = (newRows: number) => {
      setSettings({ ...settings, page: 1, rows: newRows });
    };

  function loadMachines() {
    Axios({ url: `/machines.json` })
      .then((res) => {
        setMachines(
          res.data.map((m) => ({ label: m.name, value: m.id.toString() }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function sortBy(column: string): void {
    if (settings.sidx === column) {
      const newdir = settings.sord === 'desc' ? 'asc' : 'desc';
      setSettings({ ...settings, sord: newdir });
    } else {
      setSettings({ ...settings, sidx: column });
    }
    handleRefreshClick();
  }

  function getSortIcon(column: string): string {
    if (column === settings.sidx) {
      return settings.sord === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up';
    } else {
      return 'fa fa-sort';
    }
  }

  function recalcLastPage(): void {
    setLastPage(Math.ceil(totalLength / settings.rows));
  }

  function loadFiltersData() {
    Axios({ url: `/task_management/fdata.json` })
      .then((res) => {
        setTaskTypes(res.data.tasktypes);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function prepareQuery(): string {
    let url_params = `rows=${settings.rows}&page=${settings.page}&sidx=${settings.sidx}&sord=${settings.sord}`;

    if (settings.tasktype) {
      url_params = `${url_params}&tasktype=${settings.tasktype.value},${settings.tasktype.label}`;
    }

    if (settings.status) {
      url_params = `${url_params}&status=${settings.status.value},${settings.status.label}`;
    }

    if (settings.priority) {
      url_params = `${url_params}&priority=${settings.priority.value},${settings.priority.label}`;
    }

    if (`${settings.operator}`.length > 0) {
      url_params = `${url_params}&operator=${settings.operator}`;
    }
    if (`${settings.machine}`.length > 0) {
      url_params = `${url_params}&machine=${settings.machine}`;
    }
    if (`${settings.timeline}`.length > 0) {
      url_params = `${url_params}&timeline=${settings.timeline}`;
    }

    return url_params;
  }

  function exportData() {
    const url_params = prepareQuery();
    Axios({
      method: 'POST',
      url: `/task_management/export_to_xls.xlsx?${url_params}`,
      responseType: 'blob',
      data: {
        project_tasks_ids: selectedItems,
        tasktype: settings.tasktype ? settings.tasktype.value : '',
      },
      headers: {
        accept: 'application/xlsx',
        'X-CSRF-Token': Rails.csrfToken(),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tasks.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function loadData() {
    let url_params = prepareQuery();
    props.history.push(`${props.location.pathname}?${url_params}`);

    Axios({
      method: 'GET',
      url: `/task_management/data.json?${url_params}`,
      headers: {
        accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.data.project_tasks.length > 0) {
          setProjectsTasks(res.data.project_tasks);
          setTotalLength(res.data.records);
        } else {
          setProjectsTasks([]);
          setTotalLength(0);
        }

        // if (settings.page > 1 && settings.page > lastPage) {
        //   setSettings({ ...settings, page: 1 })
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handlePageClick = (event) => {
    setSettings({ ...settings, page: event.selected + 1 });
  };

  function searchByOperator(newVal: string) {
    if (settings.operator !== newVal) {
      setSettings({ ...settings, operator: newVal });
    }
  }

  function searchByMachine(newVal: string) {
    if (settings.machine !== newVal) {
      setSettings({ ...settings, machine: newVal });
    }
  }

  function searchByTimeline(newVal: string) {
    if (settings.timeline !== newVal) {
      setSettings({ ...settings, timeline: newVal });
    }
  }

  function hasRole(roleName: string) {
    return props.roles.includes(roleName);
  }

  function visibleItems(): React.ReactNode[] {
    return projectsTasks.map((n) => (
      <TaskRow
        key={n.id}
        projectTask={n}
        allMachines={machines}
        isChecked={
          selectedItems.find((i) => {
            return i === n.id || false;
          }) !== undefined
        }
        onCheckboxClick={handleCheckboxClick}
        onTruckUpdated={loadData}
      />
    ));
  }

  function handlePriorityChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, priority: opt });
    } else {
      setSettings({ ...settings, priority: null });
    }
  }

  function handleTaskTypeChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, tasktype: opt });
    } else {
      setSettings({ ...settings, tasktype: null });
    }
  }

  function handleStatusChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, status: opt });
    } else {
      setSettings({ ...settings, status: null });
    }
  }

  useEffect(() => {
    setAllChecked(selectedItems.length === projectsTasks.length);
  }, [selectedItems, projectsTasks]);

  useEffect(() => {
    recalcLastPage();
  }, [totalLength, settings]);

  useEffect(() => {
    loadData();
  }, [settings]);

  useEffect(() => {
    loadFiltersData();
    loadMachines();
  }, []);

  return (
    <div className="wrapper wrapper-content">
      <div className="ibox-content m-b-sm border-bottom">
        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <h2>Tasks ({totalLength})</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Task Type"
                options={taskTypes}
                defaultValue={settings.tasktype}
                onChange={(val) => handleTaskTypeChange(val)}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <TextSearchField
              inputText={settings.machine}
              placeholder={'Machine'}
              searchBy={searchByMachine}
            ></TextSearchField>
          </div>
          <div className="col-sm-2">
            <TextSearchField
              inputText={settings.operator}
              placeholder={'Operator'}
              searchBy={searchByOperator}
            ></TextSearchField>
          </div>
          <div className="col-sm-2">
            <TextSearchField
              inputText={settings.timeline}
              placeholder={'Timeline'}
              searchBy={searchByTimeline}
            ></TextSearchField>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Priority"
                options={AllPriorities}
                defaultValue={settings.priority}
                onChange={(val) => handlePriorityChange(val)}
              />
            </div>
          </div>

          <div className="col-sm-2">
            <div className="form-group">
              <Select
                placeholder="Status"
                options={AllProgressStatuses}
                defaultValue={settings.status}
                onChange={(val) => handleStatusChange(val)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <Paging
              onRowsChange={onRowsChange}
              onNextClick={handleNextPageClick}
              onPrevClick={handlePrevPageClick}
              rows={settings.rows}
              page={settings.page}
              lastPage={lastPage}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 animated fadeInRight">
          <div className="mail-box">
            <table className="footable table table-hover table-mail footable-loaded ">
              <SearchContext.Provider
                value={{ sortBy: sortBy, getSortIcon: getSortIcon }}
              >
                <TableHeader
                  isChecked={allChecked}
                  onCheckboxAllClick={onCheckboxAllClick}
                />
              </SearchContext.Provider>
              <tbody>
                <RoleContext.Provider value={{ hasRole: hasRole }}>
                  {visibleItems()}
                </RoleContext.Provider>
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={9}>
                    <Button
                      variant="outlined"
                      disabled={
                        selectedItems.length === 0 ||
                        settings.tasktype === null ||
                        settings.tasktype === undefined
                      }
                      onClick={exportData}
                    >
                      Export to XLS{' '}
                      {selectedItems.length > 0
                        ? `(${selectedItems.length})`
                        : ''}
                    </Button>
                  </td>
                  <td colSpan={9} className="footable-visible">
                    <ReactPaginate
                      id="react-paginate"
                      // labels

                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      breakLabel={'…'}
                      disabledClassName={'disabled'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination pull-right'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}
                      // functionality
                      initialPage={settings.page - 1}
                      pageCount={lastPage} //total pages
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      //   forcePage={5}
                      onPageChange={
                        (event) => {
                          handlePageClick(event);
                        } /*Fxn when page clicked*/
                      }
                      disableInitialCallback={true}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
