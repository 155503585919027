export type int = number;
export type Nullable<T> = T | null;

export interface Project {
  color: string,
  project_id: number
  name: string
};

export interface CategoryData {
  id: int,
  name: string,
  full_name?: string,
  level?: int,
  description?: string,
  parent_category_id?: int
}

export interface CategoryshipData {
  id: number,
  category_id: number,
  product_id?: number,
  _destroy?: boolean
}

export interface UserData {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  nice_name: string
}

export interface OperatorshipData {
  id: number,
  user_id: number,
  name: string,
  temp_away: boolean,
  _destroy?: boolean
}

export interface MachineData {
  id: number,
  name: string,
  operational: boolean,
  task_types?: TaskTypeData[]
}

export interface ProductData {
  id: number,
  name: string,
  barcode?: string,
  variant_label?: string,
  qty?: number,
  unit_sym?: string,
  invoice_code?: string,
  unit_weight?: number,
  unit_quantity?: number,
  img?: string,
  on_stock?: number,
  url?: string,
  image?: string
}

export interface SupplierData {
  id: number,
  name: string,
  created_at?: string,
  updated_at?: string
}

export interface NoteData {
  id: number,
  document_nr: string,
  typ: string,
  user_nice_name: string,
  action_date: string,
  goods?: GoodData[]
}

export interface GoodData {
  id: number,
  note?: NoteData,
  //  product_id: number,
  product?: ProductData,
  qty: number,
  unit: string,
  price: number,
  vat: number,
  //  supplier_id: number,
  supplier: SupplierData,
  location: string,
  destroy?: number
}

export interface ItemsHeightTable {
  side: string,
  left: number,
  top: number,
  width: number,
  height: number,
  itemsHeight: number
}
export interface PackingPlanData {
  truck_id: number,
  height?: number,
  width?: number,
  top1: number,
  left1: number,
  top2: number,
  left2: number,
  updated_at: string,
  created_at: string,
  url: string,
  packing_items_attributes: PackingItemData[],
  items_height_left_1?: number,
  items_height_left_2?: number,
  items_height_left_3?: number,
  items_height_left_4?: number,
  items_height_left_5?: number,
  items_height_left_6?: number,
  items_height_right_1?: number,
  items_height_right_2?: number,
  items_height_right_3?: number,
  items_height_right_4?: number,
  items_height_right_5?: number,
  items_height_right_6?: number,
}

export interface PackingItemData {
  id: number,
  project_id: number,
  position: number,
  name: string,
  project_name: string,
  project_visible_id: string,
  top: number,
  depth: number,
  left: number,
  width: number,
  height: number,
  weight: number,
  full_truck_depth: boolean,
  shipping_marks: ShippingMark[],
  done_at: number,
  user: UserData
}

export interface ShippingMark {
  id: number,
  name: string,
  url?: string,
}

export interface NotificationData {
  id: number,
  project_id: number,
  subject: string,
  message: string,
  read_at: string,
  typ: number,
  url: string,
  created_at: string,
  customer?: CustomerData,
  project?: ProjectData,
  truck?: TruckData,
  order?: OrderData
}

export interface OrderData {
  id: number,
  ref_no: string,
  cp_order_id: number,
  visible_id: string,
  url: string,
  order_date: string,
  deadline: string,
  sales_manager: string,
  customer?: CustomerData,
  domain: string,
  country: string,
  project_count: number,
  total_price: number,
  shipping_price: number,
  comments: string,
  deadline_color: string,
  days_to_deadline: number
}

export interface DocFileData {
  id: number,
  type: string,
  filename: string,
  project_id: number,
  order_id: number,
  truck_id: number,
  ext: string,
  counter: number,
  created_at: string,
  updated_at: string,
  url: string,
  truck?: TruckData,
  order?: OrderData,
  project?: ProjectData,
  customer?: CustomerData,
  sales_manager: string
}

export interface ActivityData {
  id: number,
  file_type: string,
  filename: string,
  project_id: number,
  order_id: number,
  truck_id: number,
  created_at: string,
  updated_at: string,
  body: string,
  subject: string,
  user_email: string,
  user: string
}
export interface PriceChangeData {
  id: number,
  project_id: number,
  user_id: number,
  amount: number,
  comment: string,
  message: string,
  created_at: string,
  updated_at: string
}

export interface TaskStatusVal {
  id: number,
  status: string
}

export interface ProjectData {
  id: number,
  cp_project_id: number,
  visible_id: string,
  order_visible_id: string,
  state: string,
  is_standard: boolean,
  is_swap: boolean,
  progress_xls_pct: number,
  progress_tasks_pct: number,
  progress_bar: Array<number>,
  price: number,
  advanced_payment: number,
  advanced_paid: boolean,
  advanced_paid_date: string,
  left_payment: number,
  full_paid: boolean,
  full_paid_date: string,
  was_on_stock: boolean,
  truck_id: number,
  truck?: TruckData,
  order?: OrderData,
  xls_file1_wood: string,
  xls_file1_wood_required: boolean,
  xls_file2_items: string,
  xls_file2_items_required: boolean,
  xls_file3_instal: string,
  xls_file3_instal_required: boolean,
  xls_file4_insul: string,
  xls_file4_insul_required: boolean,
  pack_finished: boolean,
  windows_quality?: string,
  doors_quality?: string,
  task_statuses?: TaskStatusVal[],
  tasks_progress?: number,
  name: string,
  url: string,
  order_date: string,
  deadline: string,
  deadline_color: string,
  days_to_deadline: number,
  code: string,
  customer: CustomerData,
  sales_manager: string,
  notes: string,
  project_type: number,
  xls_filename?: string, // it does not exist in db - contains xls_file1_wood/xls_file2_items
  packing_items?: PackingItemData[],
}

export interface TruckData {
  id: number,
  name: string,
  customer?: CustomerData,
  consec_number: string,
  payload_pct: number,
  payload_price: number,
  payload_status: string,
  multi_addresses: boolean,
  sales_manager: string,
  dest: string,
  countries: string,
  status: string,
  typ: string,
  dispatch_date: string,
  created_at: string,
  updated_at: string,
  url: string,
  transport_cost: string,
  projects?: ProjectData[]
}

export interface CustomerData {
  id: number,
  cp_user_id: number,
  name: string,
  email: string
  domain_url: string,
  country: string,
  url: string
}

export interface TaskTypeData {
  id: number,
  name: string
}

export interface OperatorData {
  id: number,
  name: string
}

export interface ProjectTaskData {
  id: number,
  parent_id?: number,
  order_id?: number,
  position?: number,
  task_type?: TaskTypeData,
  operator?: OperatorData,
  machine?: MachineData,
  project?: ProjectData,
  status: string,
  deadline: string,
  priority: string,
  children: ProjectTaskData[],
  completion_duration: number
}

export interface ISelectOption {
  label: string,
  value: string
}

export interface FilterData {
  customers: ISelectOption[],
  selected_customer: string
  managers: ISelectOption[],
  selected_manager: string,
  order_id: string,
  project_id: string,
  project_name: string
}

export interface PopulateProjects {
  type: "populateProjects",
  projects: ProjectData[]
}

export interface PopulateTrucks {
  type: "populateTrucks",
  trucks: TruckData[]
}

export type FilterColumnName =
  | "reseller"
  | "manager"

export interface FilterByColumn {
  type: "filterByColumn",
  key_value:
  | { order_id: string }
  | { project_id: string }
  | { project_name: string }
  | { selected_manager: string }
  | { selected_customer: string }
}

export enum ROLES_LIST {
  SUPER_ADMIN = 'super_admin',
  ARCHITECT = 'architect',
  ARCHITECT_SUPPORT = 'architect_support',
  SALES_MANAGER = 'pm_sales_manager',
  TRUCK_MANAGER = 'pm_truck_manager',
  PRODUCTION_WOODEN = 'pm_production_manager_wooden',
  PRODUCTION_WINDOORS = 'pm_production_manager_windoors',
  PACKING_MANAGER = 'pm_packing_manager',
  ACCOUNTANT = 'pm_accountant',
  WAREHOUSE_WOOD = 'pm_warehouse_manager_wood',
  WAREHOUSE_COMPONENTS = 'pm_warehouse_manager_components',
  WAREHOUSE_WINDOORS = 'pm_warehouse_manager_windoors',
}

declare global {
  namespace I18n {
    function t(s: string): string;
  }
}


