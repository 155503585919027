import { Box, Button, FormControl, Stack, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Rails from '@rails/ujs';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import toastr from 'toastr';
import { customerLink, orderLink } from '../Common/functions';
import { ISelectOption, Nullable, ProjectTaskData } from '../Common/types';
import { RoleContext } from './List';
import PriorityField from './PriorityField';
import StatusField from './StatusField';

interface TableRowProps {
  isChecked: boolean;
  projectTask: ProjectTaskData;
  allMachines: ISelectOption[];
  onCheckboxClick: (project: ProjectTaskData, checked: boolean) => void;
  onTruckUpdated: () => void;
}

const TaskRow = (props: TableRowProps) => {
  const [projectTask, setProjectTask] = useState(props.projectTask);
  const task_type = projectTask.task_type;

  const roleContext = React.useContext(RoleContext);

  const allowedToChangeMachine =
    roleContext.hasRole('super_admin') ||
    roleContext.hasRole('pm_production_manager_wooden');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [machine, setMachine] = useState<Nullable<ISelectOption>>(
      projectTask.machine
        ? ({
            label: projectTask.machine.name,
            value: projectTask.machine.id.toString(),
          } as ISelectOption)
        : null
    ),
    [operator, setOperator] = useState<Nullable<ISelectOption>>(
      projectTask.operator
        ? ({
            label: projectTask.operator.name,
            value: projectTask.operator.id.toString(),
          } as ISelectOption)
        : null
    ),
    [operators, setOperators] = useState(new Array<ISelectOption>()),
    [open, setOpen] = useState(false),
    [kids, setKids] = useState(projectTask.children),
    [kidsOpen, setKidsOpen] = useState(false);

  function toggleChecked(): void {
    props.onCheckboxClick(props.projectTask, !props.isChecked);
  }

  function updateProjectTask(taskId: number, data: any): void {
    Axios.put(
      `/task_management/${taskId}.json`,
      { project_task: data },
      {
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => {
        if (res.data.new_parent_status) {
          setProjectTask({
            ...projectTask,
            status: res.data.new_parent_status,
          });
        }
      })
      .catch((err) => {
        toastr.error('Truck is NOT closed', err);
        console.log(err);
      });
  }

  function handleMachineChange(opt: ISelectOption): void {
    setMachine(opt);
    updateProjectTask(projectTask.id, { machine_id: parseInt(opt.value) });
  }

  function handleOperatorChange(opt: ISelectOption): void {
    setOperator(opt);
    updateProjectTask(projectTask.id, { user_id: parseInt(opt.value) });
    const newKids = [...kids];
    newKids.forEach((k) => {
      k.operator = { id: parseInt(opt.value), name: opt.label };
    });
    setKids(newKids);
    setOpen(false);
  }

  function handleStatusChange(taskId: number, opt: ISelectOption): void {
    updateProjectTask(projectTask.id, { status: opt.value });
    const newKids = [...kids];
    newKids.forEach((k) => {
      k.status = opt.value;
    });
    setKids(newKids);
  }

  function handleChildStatusChange(taskId: number, opt: ISelectOption): void {
    updateProjectTask(taskId, { status: opt.value });
    const newKids = [...kids];
    newKids.forEach((k) => {
      if (taskId === k.id) {
        k.status = opt.value;
      }
    });
    setKids(newKids);
  }

  function loadOperators() {
    Axios({ url: `/operatorships.json?machine=${machine.value}` })
      .then((res) => {
        setOperators(
          res.data.map((m) => ({ label: m.name, value: m.user_id.toString() }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (machine) {
      loadOperators();
    }
  }, [machine]);

  return (
    <Fragment>
      <tr>
        {true && (
          <td onClick={() => toggleChecked()}>
            <input
              type="checkbox"
              checked={props.isChecked}
              onChange={() => toggleChecked()}
              className="i-checks"
            />
          </td>
        )}
        <td>{projectTask.id}</td>
        <td>
          {kids.length > 0 && !kidsOpen && (
            <a
              href="#"
              onClick={() => setKidsOpen(true)}
              style={{ fontSize: 20 }}
            >
              +
            </a>
          )}
          {kids.length > 0 && kidsOpen && (
            <a
              href="#"
              onClick={() => setKidsOpen(false)}
              style={{ fontSize: 20 }}
            >
              -
            </a>
          )}
        </td>
        <td>
          {projectTask.project &&
            orderLink(
              projectTask.project.order,
              projectTask.project.order_visible_id
            )}
        </td>
        <td>
          {projectTask.project && (
            <a href={`/projects/${projectTask.project.id}`}>
              {projectTask.project.visible_id}
            </a>
          )}
        </td>
        <td>
          {projectTask.project && (
            <a href={`/projects/${projectTask.project.id}`}>
              {projectTask.project.name}
            </a>
          )}
        </td>
        <td>
          {projectTask.project && customerLink(projectTask.project.customer)}
        </td>
        <td>{task_type ? task_type.name : ''}</td>

        <td>
          {allowedToChangeMachine && (
            <a href="#" onClick={() => setOpen(true)}>
              {machine ? machine.label : '--empty--'}
            </a>
          )}
          {!allowedToChangeMachine && (machine ? machine.label : '--empty--')}
        </td>
        <td>
          <a href="#" onClick={() => setOpen(true)}>
            {operator ? operator.label : '--empty--'}
          </a>
        </td>
        <td>{projectTask.deadline}</td>
        <td>
          <PriorityField
            priority={projectTask.priority}
            taskId={projectTask.id}
            updateField={updateProjectTask}
          />
        </td>
        <td>
          <StatusField
            status={projectTask.status}
            taskId={projectTask.id}
            onChange={handleStatusChange}
          />
        </td>
        <td>{projectTask.completion_duration}</td>
        <td>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography variant="h6" component="h2">
                {allowedToChangeMachine
                  ? 'Assign Machine/Operator'
                  : 'Assign Operator'}
              </Typography>

              <FormControl fullWidth>
                <Stack spacing={2} direction="column">
                  {allowedToChangeMachine && (
                    <div className="wrapper wrapper-content">
                      <Select
                        placeholder="Machine"
                        options={props.allMachines}
                        defaultValue={machine}
                        onChange={(val) => handleMachineChange(val)}
                      />
                    </div>
                  )}
                  <div className="wrapper wrapper-content">
                    <Select
                      placeholder="Operator"
                      options={operators}
                      defaultValue={operator}
                      onChange={(val) => handleOperatorChange(val)}
                    />
                  </div>
                  <Stack direction="row" justifyContent="end">
                    <Button onClick={() => setOpen(false)}>Close</Button>
                  </Stack>
                </Stack>
              </FormControl>
            </Box>
          </Modal>
        </td>
      </tr>
      {kidsOpen && (
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td colSpan={4}>
            <table>
              <thead>
                <tr>
                  <th>OrdID</th>
                  <th>ProjID</th>
                  <th>ProjName</th>
                  <th>Customer</th>
                  <th>Operator Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {kids.map((child) => (
                  <tr key={`child=${child.id}`}>
                    <td>
                      {child.project &&
                        orderLink(
                          child.project.order,
                          child.project.order_visible_id
                        )}
                    </td>
                    <td>
                      {child.project && (
                        <a href={`/projects/${child.project.id}`}>
                          {child.project.visible_id}
                        </a>
                      )}
                    </td>
                    <td>
                      {child.project && (
                        <a href={`/projects/${child.project.visible_id}`}>
                          {child.project.name}
                        </a>
                      )}
                    </td>
                    <td>
                      {child.project && customerLink(child.project.customer)}
                    </td>
                    <td>
                      {child.operator ? child.operator.name : '--empty--'}
                    </td>
                    <td>
                      <StatusField
                        status={child.status}
                        taskId={child.id}
                        onChange={handleChildStatusChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default TaskRow;
