import * as React from "react"
import * as i18n from "i18n-js"
import styled from "styled-components"
import { ProjectData, TruckData } from "../Common/types";
import { projectDetails, cardFileIcon, cardIcon, orderLink, customerLink, projectLink, customSym, stockSym, swapSym } from "../Common/functions";
import TruckButton from "./TruckButton"

const HeightDiv = styled.div`
height: 25px;
`;

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface TaskProps {
  project: ProjectData,
  onTruckChange: (newTruck: TruckData) => void
}

const TaskColumn = (props: TaskProps) => {
  const project = props.project;

  let progress_bar = null;
  if (project.state == "in_production") {
    progress_bar = <div className="col-12 mb-2">
      <div className="progress progress-mini">
        <div className="progress-bar" style={{ width: `${project.progress_bar[2]}%` }} data-toggle="tooltip" title={`${project.progress_bar[2]}% (${project.progress_bar[0]} / ${project.progress_bar[1]})`}>
        <a style={{width: '100%', height: '100%'}} data-toggle="modal" data-target="modal_show_project_tasks" data-remote="true" href={`/projects/${project.id}/tasks`}></a>
        </div>
      </div>
    </div>
  }
  React.useEffect(() => {
    if (project.state == "in_production") {
      //@ts-ignore
      $('.progress-bar[data-toggle="tooltip"]').tooltip({
        animated: 'fade',
        placement: 'bottom'
      });
    }
  }, [])

  return (
    <li key={project.id} id={project.id.toString()}>
      <div className="row">

        <div className="col-9">
          <h3 title={projectDetails(project)}>
            <a href={project.url} title="Project" >
              {stockSym(project)} {customSym(project)} {swapSym(project)}  {project.name}
            </a>
          </h3>
          {orderLink(project.order)}
          &nbsp;&nbsp;&nbsp; {projectLink(project, true, false)}
          <br /> {customerLink(project.customer)}
        </div>


        <div className="col-3">
          <span className="pull-right pt-1 nowrap" title={i18n.t('order_date')} >
            <i className="fa fa-shopping-cart" ></i> {project.order_date}
          </span>

          <span className="pull-right nowrap" title={i18n.t('deadline')} >
            <i className="fa fa-industry"></i>
            <strong>{project.deadline}</strong>
          </span>

        </div>

        {progress_bar}


        <div className="col-12 agile-detail">

          <HeightDiv>

            {project.order && <span className={'pull-right'}>
              <TruckButton
                onTruckChange={props.onTruckChange}
                reassign_mode={false}
                project_id={project.id}
                customer={project.customer}
                truck={project.truck} ></TruckButton></span>}

            <div className="pt-2">
              <a href={`/projects/${project.id}`} title={i18n.t('show_project_details')}>
                <span className="mr-2">
                  <i className="fa fa-search"></i>
                </span>
              </a>

              &nbsp;&nbsp;&nbsp;

              <a href={`/projects/${project.id}#tab-1`}>
                <span className="mr-2">
                  {cardFileIcon(project.xls_file1_wood, project.xls_file1_wood_required, i18n.t('files.xls_file1_wood'), 'tree')}
                </span>
              </a>

              <a href={`/projects/${project.id}#tab-2`}>
                <span className="mr-2">
                  {cardFileIcon(project.xls_file2_items, project.xls_file2_items_required, i18n.t('files.xls_file2_items'), 'list-alt')}
                </span>
              </a>

              <a href={`/projects/${project.id}#tab-3`}>
                <span className="mr-2">
                  {cardFileIcon(project.xls_file3_instal, project.xls_file3_instal_required, i18n.t('files.xls_file3_instal'), 'wrench')}
                </span>
              </a>

              <a href={`/projects/${project.id}#tab-4`}>
                <span className="mr-2">
                  {cardFileIcon(project.xls_file4_insul, project.xls_file4_insul_required, i18n.t('files.xls_file4_insul'), 'thermometer')}
                </span>
              </a>

              <a href={`/projects/${project.id}#tab-5`}>
                <span className="mr-2">
                  {cardIcon(project.pack_finished, true, 'cubes', "Packing is finished", "Packing is required", "Packing is not required")}
                </span>
              </a>

            </div>
          </HeightDiv>
        </div>

      </div>
    </li>
  )
}
export default TaskColumn





