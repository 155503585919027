import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Axios from 'axios';
import * as React from 'react';
import { ISelectOption } from '../Common/types';

interface TaskTypeSelectorItemProps {
  task_type: ISelectOption,
  selectedItems: string[]
}

function TaskTypeSelectorItem(props: TaskTypeSelectorItemProps) {
  const [isChecked, setIsChecked] = React.useState<boolean>(props.selectedItems.includes(props.task_type.value));

  return <FormControlLabel
    key={props.task_type.value}
    //   name={`machine[task_type_attributes][${index}][task_type_id]`}
    name={`machine[task_type_id][]`}
    control={<Checkbox
      checked={isChecked}
      onChange={(e) => setIsChecked(!isChecked)}
      value={props.task_type.value} />}

    label={props.task_type.label} />
}

export interface TaskTypeSelectorProps {
  selectedItems: string[]
}

export default function TaskTypeSelector(props: TaskTypeSelectorProps) {

  const
    [task_types, setTaskTypes] = React.useState<ISelectOption[]>([]);

  function loadTaskTypes() {
    Axios({ url: `/task_types.json` })
      .then((res) => {
        setTaskTypes(res.data.map((u) => ({ label: u.name, value: u.id.toString() })));
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    loadTaskTypes();
  }, [])

  return (

    <FormGroup>
      <div className="row">
        {task_types.map((task_type, index) =>
          <div className="col-4" key={index}>
            <TaskTypeSelectorItem task_type={task_type} selectedItems={props.selectedItems} />
          </div>
        )}
      </div>
    </FormGroup>

  );
}
