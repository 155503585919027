import * as React from "react"
import TypeSelector from "./TypeSelector";
import {Router, Route} from "react-router-dom";
import PlannerMenuRouter from "../Planner/PlannerMenuRouter";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from 'history';

const newHistory = createBrowserHistory();

export interface SelectorPlannerData {
  view: string,
  id: string ,
  typ: "truck_90" | "truck_120" | "container_20" | "container_40",
  setSelectedType: (string) => any
}


const TypeSelectorPlanner = (props: SelectorPlannerData) => {
  return (
    <div>
      <BrowserRouter>
        <Router history={newHistory}>
          {props.view === "selector" ? <TypeSelector id={props.id} typ={props.typ} setSelectedType={props.setSelectedType} />  : <PlannerMenuRouter history={newHistory} truck_id={props.id} typ={props.typ}/>}
        </Router>
      </BrowserRouter>
    </div>
  )
}

export default TypeSelectorPlanner
