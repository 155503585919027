import Button from '@mui/material/Button';
import Rails from '@rails/ujs';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import { ISelectOption, ProjectData } from '../Common/types';
import { TT_WINDOWS } from './List';
import OperatorModal from './OperatorModal';

export interface BottomMenuProps {
  selectedItems: number[];
  projects: ProjectData[];
  tasktype: string;
  taskstatus: ISelectOption;
  statusesUpdated: () => void;
}

export interface ProjectWithTasks {
  project_id: number;
  task_type_ids: number[];
}

const BottomMenu = (props: BottomMenuProps) => {
  const [operatorVisible, setOperatorVisible] = useState(false),
    [projectsWithTasks, setProjectsWithTasks] = useState(
      new Array<ProjectWithTasks>()
    ),
    [newStatus, setNewStatus] = useState(props.taskstatus);

  function updateTaskStatuses() {
    if (props.selectedItems.length === 0) {
      return;
    }

    Axios({
      method: 'PUT',
      url: `/project_tasks`,
      data: {
        projects: props.selectedItems,
        newstatus: newStatus.value,
        task_types: props.tasktype,
      },
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        props.statusesUpdated();
        toastr.success('Statuses have been updated');
      })
      .catch((err) => {
        console.log(err);
        toastr.error('Statuses NOT updated', err);
      });
  }

  function generateReport(reportType: string): void {
    if (props.selectedItems.length === 0) {
      return;
    }
    let query_params = props.selectedItems
      .map((id) => `projects[]=${id}`)
      .join('&');
    window
      .open(`/reports?report=${reportType}&${query_params}`, '_blank')
      .focus();
  }

  useEffect(() => {
    const chosenProjects = props.projects.filter(
      (p) => props.selectedItems.indexOf(p.id) > -1
    );

    let arr: ProjectWithTasks[] = [];
    chosenProjects.forEach((proj) => {
      const tt_ids = proj.task_statuses
        ? proj.task_statuses
            .filter((ts) => ts.status === '-')
            .map((ts) => ts.id)
        : [];

      if (tt_ids.length > 0) {
        arr.push({
          project_id: proj.id,
          task_type_ids: tt_ids,
        });
      }
    });
    setProjectsWithTasks(arr);
  }, [props.selectedItems]);

  return (
    <div className="row">
      <div className="col-md-1 text-right">
        <label> Mass action</label>
      </div>
      <div className="col-md-2">
        <div className="form-group">
          <Button
            variant="contained"
            disabled={projectsWithTasks.length === 0}
            onClick={() => setOperatorVisible(true)}
          >
            Assign Machine
          </Button>
          <OperatorModal
            show={operatorVisible}
            task_types={props.tasktype}
            projects={projectsWithTasks}
            handleClose={() => setOperatorVisible(false)}
          ></OperatorModal>
        </div>
      </div>

      {props.tasktype === TT_WINDOWS && (
        <div className="col-md-4 col-md-offset-1 text-right">
          <label> Reports</label>
          <>
            <Button
              variant="outlined"
              disabled={props.selectedItems.length === 0}
              onClick={() => generateReport('missing')}
            >
              {' '}
              Missing
            </Button>{' '}
            <Button
              variant="outlined"
              disabled={props.selectedItems.length === 0}
              onClick={() => generateReport('warehouse')}
            >
              {' '}
              Warehouse
            </Button>
          </>
        </div>
      )}
    </div>
  );
};
export default BottomMenu;
