import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import List from './List'

const ListApp = () => (
  <Router>
    <Route component={List} />;
  </Router>
);

export default ListApp

