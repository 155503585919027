import React, { ChangeEventHandler, useEffect, useState } from 'react'
import Axios from 'axios'
import { int, ISelectOption, MachineData, Nullable, ProjectTaskData } from '../Common/types'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import {
  FormControl,
  InputLabel,
  Select as MUI_Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
  Grid,
  FormHelperText
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

//@ts-ignore
//i18n.translations = I18n.translations || {};

interface ITaskOperatorLine {
  index: int,
  onTrashClick: (index: int) => void,
  onTaskTypeChange: (index: int, task_type: ISelectOption) => void,
  onMachineChange: (index: int, machine: MachineData) => void,
  onDeadlineChange: (index: int, deadline: string) => void,
  isTaskDuplicated: boolean,
  projectTask: ProjectTaskData,
  machines: MachineData[],
  operators: ISelectOption[],
  taskTypes: ISelectOption[]
}

const emptyMachine: MachineData = { id: 0, name: '--empty--', operational: false, task_types: [] };

const TaskOperatorLine = (props: ITaskOperatorLine) => {
  const t = (label: string): string => { return label };

  const taskType = props.projectTask.task_type;

  const machines = [emptyMachine,
    ...props.machines.filter((m) => m.task_types.map((tt) => tt.id).includes(taskType?.id))]

  const [errorText, setErrorText] = useState('');
  const today = dayjs(new Date());
  const [deadline, setDeadline] = React.useState<Dayjs>(dayjs(props.projectTask.deadline, "DD/MM/YYYY"));

  const handleTaskTypeChange = (event: SelectChangeEvent) => {
    const selectedOption = props.taskTypes.find((tt) => tt.value === event.target.value);
    props.onTaskTypeChange(props.index, selectedOption);
  };

  const handleMachineChange = (event: SelectChangeEvent) => {
    const selectedOption = machines.find((m) => m.id === parseInt(event.target.value));
    if (!selectedOption) return;

    props.onMachineChange(props.index, selectedOption);
    validate(selectedOption);

  };

  const handleDeadlineChange = (newValue: Dayjs | null) => {
    setDeadline(newValue);
    props.onDeadlineChange(props.index, newValue ? newValue.format('DD/MM/YYYY') : '')
  };

  const validate = (val: Nullable<MachineData>) => {
    if (val && val.id > 0) {
      setErrorText("")
    } else {
      setErrorText("cannot be empty")
    }
  };

  useEffect(() => {
    validate(props.projectTask.machine)
  }, [props.projectTask.machine])


  return (
    <Grid container spacing={1} alignItems="space-between">
      <Grid xs={12} md={1} item  >
        <button onClick={() => props.onTrashClick(props.index)}
          className="btn btn-danger"
          title={t('remove')}
        >
          <i className="fa fa-trash-o"></i>
        </button>
      </Grid>
      <Grid xs={12} md={4} item   >
        <FormControl sx={{ maxWidth: 200 }} error={props.isTaskDuplicated}  >
          <InputLabel id="task-type-label">Task Type</InputLabel>
          <MUI_Select
            labelId="task-type-label"
            value={taskType?.id.toString()}
            label="Task Type"
            onChange={handleTaskTypeChange}

          >
            {props.taskTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </MUI_Select>

          {props.isTaskDuplicated &&
            <FormHelperText>Task is duplicated</FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid xs={12} md={4} item  >
        <FormControl sx={{ maxWidth: 200 }} error={errorText !== ''}>
          <InputLabel id="machine-label">Machine</InputLabel>
          <MUI_Select
            labelId="machine-label"
            label="Machine"
            value={props.projectTask.machine ? props.projectTask.machine.id.toString() : ""}
            onChange={handleMachineChange}


          >
            {machines.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </MUI_Select>

          <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>

        <LocalizationProvider dateAdapter={AdapterDayjs} >

          <DesktopDatePicker
            label="Deadline"
            value={deadline}
            minDate={today}
            inputFormat="DD/MM/YYYY"
            onChange={handleDeadlineChange}
            renderInput={(params: any) => <TextField {...params} />}
          />

        </LocalizationProvider>

      </Grid>
    </Grid>
  )
}

export default TaskOperatorLine;
