import React from 'react'
import HeaderSortableColumn from "./HeaderSortableColumn"
import { TT_INSTALLATION, TT_INSULATION, TT_PACKAGING, TT_WINDOWS, TT_WOODEN_PARTS } from './List'

interface HeaderDefaultProps {
  tasktype: string
}

const HeaderDefault = (props: HeaderDefaultProps) => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>Project ID </th>
        <HeaderSortableColumn column={'order_id'} label={'Order ID'} />
        <th>
          {props.tasktype === TT_WINDOWS && <i className="fa fa-list-alt" title="Windows &amp; Doors" ></i>}
          {props.tasktype === TT_INSTALLATION && <i className="fa fa-wrench" title="Installation kit" ></i>}
          {props.tasktype === TT_INSULATION && <i className="fa fa-thermometer" title="Insulation kit"></i>}
          {props.tasktype === TT_PACKAGING && <i className="fa fa-cubes" title="Packaging"></i>}
          {props.tasktype === TT_WOODEN_PARTS && <i className="fa fa-tree" title="Wood Parts" ></i>}
        </th>
        {props.tasktype === TT_WINDOWS && <th>Windows Quality</th>}
        {props.tasktype === TT_WINDOWS && <th>Doors Quality</th>}
        <HeaderSortableColumn column={'name'} label={'Name'} />
        <HeaderSortableColumn column={'deadline'} label={'Deadline'} />
        <th>State</th>
        <th>Progress</th>
        <HeaderSortableColumn column={'truck'} label={'Truck'} />
        <th>Customer</th>
        <th>Sales Manager</th>
        <HeaderSortableColumn column={'order_date'} label={'Order date'} />
      </tr>
    </thead>
  )
}

export default HeaderDefault
