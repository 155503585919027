import React, { useEffect, useState } from 'react'
import { DocFileData } from "../Common/types"
import { Modal, Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser'
import Axios from 'axios'


interface FileInfoProps {
  doc_file: DocFileData
}

const FileInfo = (props: FileInfoProps) => {

  const [show, setShow] = React.useState(false);
  const [doc_file, setDocFile] = React.useState(props.doc_file);
  const [activities, setActivities] = React.useState([]);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    if (activities.length == 0) {
      loadActivities();
    }
    setShow(true);
  }

  function loadActivities() {
    Axios({
      method: 'GET',
      url: `/repository/${props.doc_file.id}.json`,
      headers: {
        accept: 'application/json'
      }
    })
      .then((res) => {
        setActivities(res.data.activities)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const activities_array =
    activities.map(a => (

      <div className="vertical-timeline-block">
        <div className="vertical-timeline-content">
          <h4>
            {a.subject}
            <span className="vertical-date">
              <small> {a.created_at}</small>
            </span>
            <small> by <a href="#"> {a.user} </a></small>
          </h4>
          <div>
            {ReactHtmlParser(a.body)}
          </div>
        </div>

      </div>


    ))

  const history =
    <div id="vertical-timeline" className="vertical-container dark-timeline center-orientation">
      {activities_array}
    </div>

  const no_history_info = <h4 className="d-flex justify-content-center">No history</h4>

  return (
    <div>

      <a href="#" className="btn btn-xs btn-success" title="History" onClick={handleShow} >
        <i className="fa fa-info"></i>
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {doc_file.type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activities.length > 0 ? history : no_history_info}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default FileInfo
