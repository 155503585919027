import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import Axios from 'axios'
import toastr from 'toastr'
import * as i18n from "i18n-js"
import { TruckData } from '../Common/types';
import { customerLink, truckLink } from '../Common/functions';
declare function formatMoney(number: number, decPlaces: number, decSep: string, thouSep: string): string;

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface ListItemProps {
  truck: TruckData
  onDestroyHandler: () => void
}

const ListItem = (props: ListItemProps) => {

  function destroyTruck(): void {
    if (!window.confirm(i18n.t('are_you_sure'))) {
      return;
    }

    Axios({
      method: 'DELETE',
      url: `/trucks/${props.truck.id}.json`,
      data: {},
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        toastr.success("Truck removed", i18n.t('success'))
        props.onDestroyHandler();
      })
      .catch((err) => {
        toastr.error(err.response.data.message, i18n.t('error'))
      })
  }


  return (
    <tr>
      <td>
        {truckLink(props.truck, false, false, false)}
      </td>
      <td>
        {props.truck.consec_number}
      </td>
      <td>
        {customerLink(props.truck.customer)}
      </td>
      <td>{props.truck.customer?.country}</td>
      <td>
        {props.truck.typ}
      </td>
      <td className="text-center">
        {props.truck.payload_pct}
      </td>
      <td className="text-right">
        {formatMoney(props.truck.payload_price, 0, ".", ",")}
      </td>
      {/* <td className="text-center">
        {props.truck.payload_status}
      </td> */}
      <td>{props.truck.transport_cost}</td>
      <td>
        {props.truck.sales_manager}
      </td>
      <td>{props.truck.status} </td>
      <td className="text-center">{props.truck.dispatch_date} </td>
      <td>{props.truck.created_at} </td>
      <td>

        <a href={`/trucks/${props.truck.id}/${props.truck.typ}`} className="btn btn-primary">Show</a>
        &nbsp;
        <a href={`/trucks/${props.truck.id}/edit`} className="btn btn-primary">Edit</a>
        &nbsp;
        {props.truck.status !== 'DISPATCHED' &&
          <a href="#" className="btn btn-danger" rel="nofollow" onClick={() => destroyTruck()} >Destroy</a>
        }
      </td>
    </tr>

  )
}

export default ListItem
