import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import Axios from 'axios'
import toastr from 'toastr'
import ReactHtmlParser from 'react-html-parser'
import * as i18n from "i18n-js"
import { NotificationData } from '../Common/types';
import { customerLink, truckLink, projectLink, orderLink, projectState } from '../Common/functions';

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface ListItemProps {
  isChecked: boolean,
  notification: NotificationData,
  onCheckboxClick: (notification: NotificationData, checked: boolean) => void
}

const ListItem = (props: ListItemProps) => {
  const [checked, setChecked] = useState(false);
  const [isRead, setIsRead] = useState(props.notification.read_at !== '');

  const onReadClick = id => {
    manage(id, 'read')
  };

  function manage(id, action) {
    Axios({
      method: 'PUT',
      url: `/notifications/manage_selected`,
      data: { ids: id, operation: action },
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        if (res.data.status == 'success') {
          setIsRead(true)
          props.notification.read_at = 'now'
          // toastr.success(res.data.message, I18n.t('admin.success'))
        } else {
          toastr.error(res.data.message, i18n.t('error'))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    props.onCheckboxClick(props.notification, checked)
  }, [checked])

  useEffect(() => {
    if (props.isChecked) {
      setChecked(props.isChecked)
    } else {
      setChecked(false)
    }
  }, [props.isChecked])

  useEffect(() => {
    setIsRead(props.notification.read_at !== '')
  }, [props.notification.read_at])

  return (
    <tr className={isRead ? 'read' : 'unread'}>
      <td
        className="check-mail"
        onClick={() => setChecked(!checked)}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
          className="i-checks"
        />
      </td>
      <td
        className="mail-subject"
        onClick={() => setChecked(!checked)}
      >
        {ReactHtmlParser(props.notification.subject)}
      </td>
      <td
        onClick={() => setChecked(!checked)}
      >
        {ReactHtmlParser(props.notification.message)}
      </td>
      <td className="blue-link">{props.notification.project && projectLink(props.notification.project, true, true)} </td>
      <td className="blue-link">{props.notification.project && projectState(props.notification.project)} </td>
      <td className="blue-link">{props.notification.order && orderLink(props.notification.order)} </td>
      <td className="blue-link">{props.notification.customer && customerLink(props.notification.customer)} </td>
      <td>{props.notification.truck && truckLink(props.notification.truck)} </td>
      <td
        className="mail-date"
        onClick={() => setChecked(!checked)}
        title={i18n.t('sent_at')}
      >
        {props.notification.created_at}
      </td>
      <td
        className="mail-date"
        onClick={() => setChecked(!checked)}
        title={i18n.t('read_at')}
      >
        <strong className="text-success">{props.notification.read_at}</strong>
      </td>
      <td>
        {!isRead &&
          <button
            className={`btn btn-xs btn-white`}
            onClick={() => onReadClick(props.notification.id)}
            title={i18n.t('mark_as_read')}
          >
            <i className="fa fa-eye"></i>
          </button>
        }
      </td>
    </tr>
  )
}

export default ListItem
