
import React, { useEffect } from 'react'
import Rails from "@rails/ujs"

import { Box, FormControl, Stack, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import TaskOperatorLine from './TaskOperatorLine';
import Axios from 'axios'
import * as i18n from "i18n-js"
import { int, ISelectOption, MachineData, Nullable, ProjectData, ProjectTaskData, UserData } from '../Common/types';
import toastr from 'toastr';
import { FormatDate, inNDays } from '../Common/functions';
import { ProjectWithTasks } from './BottomMenu';
import * as moment from 'moment';

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface OperatorModalProps {
  show: boolean,
  projects: ProjectWithTasks[],
  task_types: string,
  handleClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const OperatorModal = (props: OperatorModalProps) => {
  const
    [laddaState, setLaddaState] = React.useState({ loading: false, progress: 0 }),
    [formValid, setFormValid] = React.useState(true),
    [operators, setOperators] = React.useState<ISelectOption[]>([]),
    [taskTypes, setTaskTypes] = React.useState<ISelectOption[]>([]),
    [rows, setRows] = React.useState<ProjectTaskData[]>([]);


  const [machines, setMachines] = React.useState<MachineData[]>([]);

  const relevantTaskTypes = React.useMemo(() => {
    const taskTypesArray = props.task_types.split(',');
    return taskTypes.filter((tt) => taskTypesArray.indexOf(tt.value) > -1)
  }, [props.task_types, taskTypes])

  const taskNames = React.useMemo(() => {
    const taskTypesArray = props.task_types.split(',');
    return taskTypes.filter((tt) => taskTypesArray.indexOf(tt.value) > -1).map((tt) => tt.label)
  }, [props.task_types, taskTypes])

  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  function submitForm(): void {
    laddaOn();

    Axios({
      method: 'POST',
      url: `/task_assignments/update_bulk`,
      data: {
        rows: rows,
        project_ids: props.projects.map((p) => p.project_id)
      },
      headers: {
        accept: 'application/json',
        "X-CSRF-Token": Rails.csrfToken(),
      }
    })
      .then((res) => {
        laddaOff();
        if (res.data.status === 'success') {
          toastr.success(res.data.message, i18n.t('success'))
          cleanUpAndClose()
          window.location.reload()
        } else {
          toastr.warning(res.data.message, i18n.t('warning'))
        }
      })
      .catch((err) => {
        laddaOff();
        if (err.response) {
          toastr.error(err.response.data.message, i18n.t('error'))
        }
        console.log(err)
      })

  }

  function loadMachines() {
    Axios({
      url: `/machines.json`
    })
      .then((res) => {
        setMachines(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function loadOperators() {
    Axios({ url: `/users.json` })
      .then((res) => {
        setOperators(res.data.map((u) => ({ label: u.nice_name, value: u.id.toString() })));
      })
      .catch((err) => {
        console.log(err)
      })
  }


  function loadTaskTypes() {
    Axios({ url: `/task_types.json` })
      .then((res) => {
        setTaskTypes(res.data.map((m) => ({ label: m.name, value: m.id.toString() })))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function findMachineFor(task_type: ISelectOption): Nullable<MachineData> {
    return machines
      .filter((m) => m.task_types.find((tt) => tt.id.toString() === task_type.value))
      .find((m) => m.operational)
  }

  function createTask(task_type: ISelectOption): ProjectTaskData {
    const machine = findMachineFor(task_type)
    return {
      id: 0,
      machine: {
        id: machine ? machine.id : 0,
        name: machine ? machine.name : '--empty--',
        operational: true
      },
      operator: {
        id: 0,
        name: ''
      },
      task_type: {
        id: parseInt(task_type.value),
        name: task_type.label
      },
      project: { id: 0, name: '' },
      status: 'todo',
      deadline: moment(inNDays(7)).format("DD/MM/YYYY"),
      priority: 'normal',
      completion_duration: 0
    }
  }

  function addNew() {
    const taken_ids = rows.map((r) => r.task_type.id.toString())
    const available = relevantTaskTypes.filter((tt) => taken_ids.indexOf(tt.value) === -1)

    if (available.length > 0) {
      setRows([...rows, createTask(available[0])])
    }
  }

  function addMissingOnly() {
    const todo_ids = [...new Set(props.projects.map((proj) => proj.task_type_ids).flat(1))];
    const available = relevantTaskTypes.filter((tt) => todo_ids.indexOf(parseInt(tt.value)) > -1)
    const newRows = available.map((tt) => createTask(tt))
    setRows(newRows)
  }

  function removeRow(ix: int) {
    setRows([...rows.slice(0, ix), ...rows.slice(ix + 1)])
  }

  function onTaskTypeChange(ix: int, task_type: ISelectOption) {
    const task_type_id = parseInt(task_type.value);

    const newRow = {
      ...rows[ix],
      task_type: { id: task_type_id, name: task_type.label },
      machine: findMachineFor(task_type)
    }
    setRows([...rows.slice(0, ix), newRow, ...rows.slice(ix + 1)])
  }

  function onMachineChange(ix: int, machine: MachineData) {
    const newRow = { ...rows[ix], machine: machine }
    setRows([...rows.slice(0, ix), newRow, ...rows.slice(ix + 1)])
  }

  function onDeadlineChange(ix: int, deadline: string) {
    const newRow = { ...rows[ix], deadline: deadline }
    setRows([...rows.slice(0, ix), newRow, ...rows.slice(ix + 1)])
  }

  function cleanUpAndClose() {
    props.handleClose()
  }

  function isTaskTypeDuplicated(taskTypeId: int): boolean {
    return rows.filter((r) => r.task_type && r.task_type.id === taskTypeId).length > 1
  }

  function validateForm() {
    const rowsWithoutMachinesCnt = rows.filter((r) => !r.machine || r.machine.id === 0).length
    setFormValid(rowsWithoutMachinesCnt === 0)
  }

  useEffect(() => {
    validateForm();
  }, [rows])

  useEffect(() => {
    loadOperators();
    loadTaskTypes();
    loadMachines();
  }, [])

  useEffect(() => {
    if (props.show) {
      addMissingOnly();
    } else {
      setRows([])
    }
  }, [props.show])

  return (
    <Modal
      open={props.show}
      onClose={() => props.handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>


        <Stack spacing={2} direction="column">
          <Typography variant="h6" component="h2">
            Assign Machine
          </Typography>
          <Typography  >
            {props.projects.length} projects selected. <br />
            Task type/s: {taskNames.join(", ")}
          </Typography>
          {rows.map((projectTask, index) =>
            <TaskOperatorLine
              key={`${index}-${projectTask.task_type.id}`}
              index={index}
              machines={machines}
              projectTask={projectTask}
              onTrashClick={removeRow}
              onTaskTypeChange={onTaskTypeChange}
              onMachineChange={onMachineChange}
              onDeadlineChange={onDeadlineChange}
              isTaskDuplicated={isTaskTypeDuplicated(projectTask.task_type.id)}
              taskTypes={relevantTaskTypes}
              operators={operators} />
          )}

          <Stack spacing={2} direction="row">

            <Button variant="outlined"
              disabled={rows.length > relevantTaskTypes.length - 1}
              onClick={() => addNew()}>+One</Button>


          </Stack>

          <Stack direction="row" spacing={2}
            justifyContent="space-between"
            alignItems="center">

            <Button onClick={() => cleanUpAndClose()} >Cancel </Button>
            {!laddaState.loading && <Button disabled={!formValid} variant="contained" onClick={() => submitForm()} >OK</Button>}
            {laddaState.loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500]
                }}
              />
            )}
          </Stack>
        </Stack>

      </Box>
    </Modal>
  )
}
export default OperatorModal
