
import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import { SupplierData, int } from '../Common/types';
import toastr from 'toastr'
import LaddaButton, { XL, SLIDE_UP } from 'react-ladda';

export interface DefineSupProps {
  show: boolean,
  handleClose: () => void;
  supplierAdded: (supplier: SupplierData) => void;
}

const DefineSup = (props: DefineSupProps) => {

  const [supplier, setSupplier] = useState({
    id: 0, name: "",

  }),

    [laddaState, setLaddaState] = React.useState({ loading: false, progress: 0 }),

    nameChanged = val => {
      setSupplier(old => ({ ...old, name: val }))
    };

  function submitForm(): void {
    laddaOn();

    Axios({
      method: "POST",
      url: `/suppliers.json`,
      data: {
        supplier: {
          name: supplier.name
        }
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();
        console.log('res', res)
        if (res.status === 201) {
          toastr.success("Supplier added")
          props.supplierAdded(res.data);
        }
        else {
          toastr.error(res.data['errors'], "Supplier error")
        }
      })
      .catch((err) => {
        laddaOff();
        console.log(err);
      })

  }

  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  useEffect(() => {
    setSupplier({
      id: 0, name: "",
    })
  }, [props.show])


  return (

    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header className="modal-lg" closeButton>
        <Modal.Title>
          New Supplier ...
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">

            <div className="ibox ">
              <div className="ibox-content">
                <div className="form-group row">
                  <label className="col-md-4 text-right">Name </label>
                  <div className='col-md-8'>
                    <input className="form-control" name="name" value={supplier.name} onChange={e => nameChanged(e.target.value)} />
                  </div>
                </div>
              </div >
            </div >
          </div >
        </div >
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.handleClose}>
          Cancel
        </Button>


        <LaddaButton
          className="btn btn-primary"
          loading={laddaState.loading}
          onClick={() => submitForm()}
          data-color="#eee"
          data-spinner-size={30}
          data-spinner-color="#ddd"
          data-spinner-lines={12}
        >
          Save
        </LaddaButton>

      </Modal.Footer>
    </Modal >

  )
}
export default DefineSup
