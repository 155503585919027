import React, { useEffect, useCallback, useState, useRef } from 'react'
//@ts-ignore
import { debounce } from 'lodash'

import { TextField } from '@mui/material';

import axios from 'axios';
import { int, ProductData } from '../../Common/types';
import { SearchProductData } from './ProductField';


export const PRODUCT_SEARCH_AFTER_CHAR = 1;

interface BarcodeTextFieldProps {
  index: int,
  readonly: boolean,
  autoFocus: boolean,
  autoValidate: boolean,
  barcode: string,
  product_id: int,
  addGood: () => void,
  onChange: (newBarcode: string) => void,
  changeProduct: (newProduct: SearchProductData) => void,
  product?: ProductData
}

const BarcodeTextField = (props: BarcodeTextFieldProps) => {

  const
    isLastRow = !props.autoValidate,
    [barcode, setBarcode] = useState(props.barcode),
    [errorText, setErrorText] = useState(''),

    barcodeRef = useRef(null),

    debouncedSingleSearch = useCallback(
      debounce((value) => findProductByBarcode(value), 500), []
    ),

    barcodeChanged = val => {
      setBarcode(val);
      validate(val);
      props.onChange(val);
      debouncedSingleSearch(val);
    },

    validate = (val) => {
      if (val !== undefined && val !== '') {
        setErrorText("")
      } else {
        setErrorText("cannot be empty")
      }
    },

    onKeyPressed = (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
        if (isLastRow) {
          props.addGood();
        }
      }
    },
    findProductByBarcode = (val: string) => {
      if (val && val.length > PRODUCT_SEARCH_AFTER_CHAR) {
        findProductByQueryString(`barcode=${val}`)
      }
    },
    findProductByQueryString = (query_string: string) => {
      axios({
        method: 'GET',
        url: `/products.json?${query_string}`,
        headers: {
          accept: 'application/json'
        }
      })
        .then((res) => {
          if (res.data.length > 0) {
            props.changeProduct(res.data[0] as SearchProductData);

          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    doFocus = () => {
      if (barcodeRef && barcodeRef.current) {
        barcodeRef.current.focus();
      }
    };

  useEffect(() => {
    if (props.autoFocus) doFocus();
  }, [props.autoFocus])



  return (

    <div className="col-xl-2 col-md-12"  >
      {props.readonly &&
        <a href={`/products/${props.product_id}`} target='_blank' >{barcode}</a>
      }
      {!props.readonly &&
        <TextField
          type="text"
          role="barcodeInput"
          label="Barcode"
          onChange={e => barcodeChanged(e.target.value)}
          value={barcode}
          inputRef={barcodeRef}
          onBlur={() => validate(barcode)}
          onKeyPress={onKeyPressed}
          error={errorText !== ''}
          helperText={errorText}
        />

      }
    </div>

  )
}

export default BarcodeTextField
