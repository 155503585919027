import React, { useEffect, useCallback, useState, useRef } from 'react'
//@ts-ignore
import { debounce } from 'lodash'

import { TextField, Button } from '@mui/material';
import { fieldName } from './CommonFuncs';


interface LocationTextFieldProps {
  index: number,
  location: string,
  autoFocus: boolean,
  autoValidate: boolean,
  readonly: boolean,
  addGood: () => void,
  onChange: (newVal) => void
}

const LocationTextField = (props: LocationTextFieldProps) => {
  const
    isLastRow = !props.autoValidate,
    [location, setLocation] = useState(props.location),
    [errorText, setErrorText] = useState(''),

    locationRef = useRef(null),

    locationChanged = val => {
      setLocation(val)
      props.onChange(val);
      validate(val)
    },

    validate = (val) => {
      if (val !== undefined && val !== '') {
        setErrorText("")
      } else {
        setErrorText("cannot be empty")
      }
    },

    onKeyPressed = (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
        if (isLastRow) {
          props.addGood();
        }
      }

      if (ev.key === 'Tab') {
        if (isLastRow) {
          ev.preventDefault();
          props.addGood();
        }
      }
    },
    doFocus = () => {
      if (locationRef && locationRef.current) {
        locationRef.current.focus();
      }
    };

  useEffect(() => {
    if (props.autoValidate) {
      validate(location);
    }
  }, [props.autoValidate])

  useEffect(() => {
    // TG: I don't know  why 100 ms later but without it
    if (props.autoFocus) setTimeout(() => { doFocus() }, 100);
  }, [props.autoFocus])

  return (
    <div className="col-12 col-md-12 col-xl-1">
      {props.readonly && location}
      {!props.readonly &&
        <div style={{ display: "inline-block" }}>
          <TextField
            onBlur={() => validate(location)}
            label="Location"
            value={location}
            name={fieldName(props.index, 'location')}
            onChange={e => locationChanged(e.target.value)}
            inputRef={locationRef}
            onKeyDown={onKeyPressed}
            error={errorText !== ''}
            helperText={errorText}
          />
        </div>
      }
    </div>
  )
}

export default LocationTextField
