import { createStore } from "redux"
import Axios from 'axios'
import { ProjectData, TruckData, FilterByColumn, FilterData, PopulateTrucks, PopulateProjects } from '../Common/types'

export interface VenueState {
  projects: ProjectData[],
  trucks: TruckData[],
  filters: FilterData
}

export const initialState = {
  projects: [],
  trucks: [],
  filters: {
    customers: [],
    selected_customer: null,
    managers: [],
    selected_manager: null,
    order_id: "",
    project_id: "",
    project_name: ""
  }
}

export function loadProjects() {
  Axios({ url: `/production_queue/data.json` })
    .then((res) => {
      venueStore.dispatch({
        type: "populateProjects",
        projects: res.data.projects
      })

    })
    .catch((err) => {
      console.log(err)
    })
}


export function loadTrucks() {
  Axios({ url: `/trucks/not_dispatched.json` })
    .then((res) => {
      venueStore.dispatch({
        type: "populateTrucks",
        trucks: res.data
      })

    })
    .catch((err) => {
      console.log(err)
    })
}

export type VenueAction =
  | PopulateProjects
  | PopulateTrucks
  | FilterByColumn


export const venueReducer = (
  state: VenueState = initialState,
  action: VenueAction
): VenueState => {

  switch (action.type) {
    case "populateProjects":
      const customers = action.projects.filter((p) => p.customer).map(p => p.customer)

      const uniq_customers = Array.from(new Set(customers.map((c) => c.name)));
      const uniq_managers = Array.from(new Set(action.projects.map((p) => p.sales_manager)));

      const filterData: FilterData = {
        customers: uniq_customers.map((n) => ({ label: n, value: n })),
        selected_customer: state.filters.selected_customer,
        managers: uniq_managers.map((n) => ({ label: n, value: n })),
        selected_manager: state.filters.selected_manager,
        order_id: "",
        project_id: "",
        project_name: ""
      }
      return { ...state, projects: action.projects, filters: filterData }
    case "populateTrucks":
      return { ...state, trucks: action.trucks }
    case "filterByColumn":
      return {
        ...state,
        filters: { ...state.filters, ...action.key_value }
      }
    default:
      return state
  }
}

export const venueStore = createStore(venueReducer)
