import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { ISelectOption } from "../Common/types"
import { VenueState } from "./AppReducer"
import Select from 'react-select'
import TextSearchField from '../Notifications/TextSearchField';
import { STATUSES } from "./App"

const Filters = (props: {changeColumns: (items: ISelectOption[]) => void}) => {

  const STATUS_LABELS: ISelectOption[] = STATUSES.map(x => {
    return ({label: x.replace(/\_/g, " ").toUpperCase(), value: x})
  })

  const order_id = useSelector<VenueState, string>(
    state => state.filters.order_id
  )

  const project_id = useSelector<VenueState, string>(
    state => state.filters.project_id
  )
  const customers = useSelector<VenueState, ISelectOption[]>(
    state => state.filters.customers
  )

  const sel_customer = useSelector<VenueState, string>(
    state => state.filters.selected_customer
  )

  const sel_manager = useSelector<VenueState, string>(
    state => state.filters.selected_manager
  )

  const managers = useSelector<VenueState, ISelectOption[]>(
    state => state.filters.managers
  )

  const project_name = useSelector<VenueState, string>(
    state => state.filters.project_name
  )

  const dispatch = useDispatch()

  function searchByOrderId(newVal) {
    dispatch({
      type: "filterByColumn",
      key_value: { order_id: newVal }
    })
  }

  function searchByProjectId(newVal) {
    dispatch({
      type: "filterByColumn",
      key_value: { project_id: newVal }
    })
  }

  function searchByProjectName(newVal) {
    dispatch({
      type: "filterByColumn",
      key_value: { project_name: newVal }
    })
  }

  const handleQueryChange = (field, item) => {
    let hash = {}
    hash[field] = item ? item.value : null

    dispatch({
      type: "filterByColumn",
      key_value: hash
    })

  };

  const handleStatusChange = (items) => {
    props.changeColumns(items);
  }

  return (

    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-lg-12">
        <h2>Tasks</h2>
      </div>

      <div className="col-lg-1 pt-3"></div>

      <div className="col-lg-2 pt-3">
        <TextSearchField
          inputText={project_name}
          placeholder={"Project Name"}
          searchBy={searchByProjectName} ></TextSearchField>
      </div>

      <div className="col-lg-2 pt-3">
        <TextSearchField
          inputText={order_id}
          placeholder={"Order Id"}
          searchBy={searchByOrderId} ></TextSearchField>
      </div>

      <div className="col-lg-2 pt-3">
        <TextSearchField
          inputText={project_id}
          placeholder={"Project Id"}
          searchBy={searchByProjectId} ></TextSearchField>
      </div>

      <div className="col-lg-2 pt-3">
        <Select
          isClearable
          placeholder="Customer"
          options={customers}
          defaultValue={sel_customer}
          onChange={val => handleQueryChange('selected_customer', val)}
        />

      </div>
      <div className="col-lg-2 pt-3">
        <Select
          isClearable
          placeholder="Managers"
          options={managers}
          defaultValue={sel_manager}
          onChange={val => handleQueryChange('selected_manager', val)}
        />
      </div>

      <div className="col-lg-1 pt-3"></div>

      <div className="col-lg-1 pt-1"></div>

      <div className="col-lg-4 pt-1">
      <Select
        isMulti
        placeholder="Status"
        options={STATUS_LABELS}
        defaultValue={STATUS_LABELS}
        onChange={val => handleStatusChange(val)}
      />
      </div>

    </div>
  )
}
export default Filters
