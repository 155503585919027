import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import Axios from 'axios'
import toastr from 'toastr'
import * as i18n from "i18n-js"
import { DocFileData } from '../Common/types';
import { customerLink, orderLink, projectLink, truckLink } from '../Common/functions';

import FileInfo from "./FileInfo"

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface ListItemProps {
  doc_file: DocFileData
}

const ListItem = (props: ListItemProps) => {


  return (
    <tr>
      <td>
        <a href={props.doc_file.url}>
          {props.doc_file.filename}
        </a>
      </td>
      <td>
        {orderLink(props.doc_file.order)}
      </td>
      <td>
        {projectLink(props.doc_file.project, false, true)}
      </td>
      <td>
        {truckLink(props.doc_file.truck)}
      </td>
      <td>
        {props.doc_file.type}
      </td>
      <td>
        {props.doc_file.ext}
      </td>
      <td>
        {customerLink(props.doc_file.customer)}
      </td>
      <td>
        {props.doc_file.sales_manager}
      </td>
      <td>
        {props.doc_file.created_at}
      </td>
      <td>
        {props.doc_file.updated_at}
      </td>
      <td> <FileInfo doc_file={props.doc_file} ></FileInfo></td>
    </tr >

  )
}

export default ListItem
