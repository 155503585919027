// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
global.toastr = require("toastr");
ReactRailsUJS.useContext(componentRequireContext);
