import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

interface ISelectOption {
  label: string;
  value: string;
  color: string;
}

interface KeywordInputProps {
  import_xlsx_keywords: null | string;
}

const getRandomColor = () => {
  const shade = Math.floor(Math.random() * 226);
  return `rgb(${shade}, ${shade}, ${shade})`;
};

const createOption = (label: string) => ({
  label,
  value: label,
  color: getRandomColor(),
});

const fromArrToStr = (arr: ISelectOption[]): string => {
  return arr.map((el) => el.value).join(";;");
};

const fromStrToArr = (str: string | null): ISelectOption[] => {
  if (!str) {
    return [];
  }
  return str.split(";;").map((s) => ({
    label: s,
    value: s,
    color: getRandomColor(),
  }));
};

const KeywordInput = (props: KeywordInputProps) => {
  const customStyles = {
    container: (base, state) => ({
      ...base,
      padding: "0",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    indicatorSeparator: () => ({ display: "none" }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: state.data.color || getRandomColor(),
      color: "white!important",
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color: "white!important",
    }),
  };
  const [val, setVal] = useState<ISelectOption[]>([]);

  useEffect(() => {
    // setVal()
    console.log(
      "fromStrToArr(props.import_xlsx_keywords) ",
      fromStrToArr(props.import_xlsx_keywords),
      "props.import_xlsx_keywords ",
      props.import_xlsx_keywords
    );
    setVal(fromStrToArr(props.import_xlsx_keywords));
    // @ts-ignore
    $('[data-toggle="tooltip"]').tooltip({
      animated: "fade",
      placement: "bottom",
    });
  }, []);

  return (
    <>
      <div className="form-group">
        <label>Import XSLX Keywords</label>
        <i
          className="ml-2 fa fa-question-circle"
          data-toggle="tooltip"
          title="Use this keyword list for allow create task based on xlsx using this names. Name is case sensitive"
        ></i>
        <input
          type="hidden"
          name="task_type[import_xlsx_keywords]"
          value={fromArrToStr(val)}
        />
        <CreatableSelect
          className="col-12 col-sm-6 col-md-6 col-lg-6 my-1 pl-0"
          isClearable
          isCreatable
          isMulti
          placeholder="Keyword matching with xlsx import"
          options={[]}
          defaultValue={val}
          value={val}
          onChange={(e: ISelectOption[]) => {
            setVal(e);
          }}
          onCreateOption={(inputValue: string) => {
            const newOption = createOption(inputValue);
            setVal((oldVal) => [...oldVal, newOption]);
          }}
          styles={customStyles}
          components={{ DropdownIndicator: () => null }}
          noOptionsMessage={() => null}
          menuPortalTarget={document.body}
          formatCreateLabel={(value) => value}
        />
      </div>
    </>
  );
};
export default KeywordInput;
