import { useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import Container_20 from './Container_20';
import Container_40 from './Container_40';
import Truck_90 from './Truck_90';
import Truck_120 from './Truck_120';
import PlannerMenuItem from './PlannerMenuItem';
import {PlannerMenuRouterProps} from './PlannerMenuRouter';

import {
  Router,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import { Wheel } from './Wheel';
import React from 'react';

// const newHistory = createBrowserHistory();
const PlannerMenu = (props: PlannerMenuRouterProps) => {
  useEffect(() => {
    props.history.push("/trucks/"+ props.truck_id +"/"+ props.typ)
  }, []);


  const app = useMemo(() => {
    return new Wheel(false)
  }, []);


  return (
    <div>
        {/* <PlannerMenuItem truck_id={props.truck_id} typ={props.typ}/> */}
        <Route path="/trucks/:id/truck_90" render={(props) => <Truck_90 app={app} {...props} truck_id={props.match.params.id} simple={false} />} />
        <Route path="/trucks/:id/truck_120" render={(props) => <Truck_120 app={app} {...props} truck_id={props.match.params.id} simple={false} />} />
        <Route path="/trucks/:id/container_20" render={(props) => <Container_20 app={app} {...props} truck_id={props.match.params.id} simple={false} />} />
        <Route path="/trucks/:id/container_40" render={(props) => <Container_40 app={app} {...props} truck_id={props.match.params.id} simple={false} />} />
    </div>
  )
}

export default PlannerMenu
