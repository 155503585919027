import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';
import { OperatorshipData } from '../Common/types';

const filter = createFilterOptions<OperatorshipData>();

const USER_SEARCH_AFTER_CHAR = 1;

interface OperatorAutoCompleteProps {
  changeHandler: (item: OperatorshipData) => void,
  clearClicked: () => void
}

export default function OperatorAutoComplete(props: OperatorAutoCompleteProps) {
  const [inputValue, setInputValue] = React.useState(''),
    [users, setUsers] = React.useState(new Array<OperatorshipData>()),

    handleChange = (item: OperatorshipData) => {
      if (item) {
        props.changeHandler(item);
      }
    },

    getUsers = () => {
      const url = `/operatorships.json?${inputValue.length > 0 ? `&q=${inputValue}` : ''}`
      axios({
        method: 'GET',
        url: url,
        headers: {
          accept: 'application/json'
        }
      })
        .then((res) => {

          if (res.data.length > 0) {
            setUsers(res.data);
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }


  React.useEffect(() => {
    if (inputValue && inputValue.length > USER_SEARCH_AFTER_CHAR) {
      getUsers()
    }

  }, [inputValue]);

  return (
    <div>
      <Autocomplete
        options={users}
        placeholder="Select user"
        clearOnEscape
        getOptionLabel={(option) => (option ? option.name : '')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        includeInputInList
        filterSelectedOptions
        filterOptions={(options, params) => {

          if (params.inputValue !== '') {
            return filter(options, params);
          } else {
            return options
          }

        }}

        onChange={(event: any, newValue: any) => {
          handleChange(newValue);
          props.clearClicked();
        }}
        onInputChange={(e, newInputValue, reason) => {
          setInputValue(newInputValue)

          if (reason === 'clear') {
            props.clearClicked()
          }
        }
        }
        renderInput={(params) => (
          <TextField {...params} label="Operator" fullWidth />
        )}

        renderOption={(props, option) => {
          return (
            <li {...props} key={option.user_id} value={option.user_id}>
              {option.name}
            </li>
          );
        }}

      />
    </div>
  );
}
