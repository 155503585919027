import * as React from "react"
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import toastr from 'toastr'
import { ShortTruckData } from './StatusSection'

export interface CloseButtonProps {
  truck: ShortTruckData,
  closeTruckHandler?: (newtruck: ShortTruckData) => void
}

const CloseButton = (props: CloseButtonProps) => {

  const [show, setShow] = React.useState(false);

  const [truck, setTruck] = React.useState(props.truck);


  const [startDate, setStartDate] = React.useState(new Date());

  const [today, setToday] = React.useState(new Date());

  const handleClose = () => setShow(false);

  function isDispatchDateSet(): boolean {
    return (truck.dispatch_date !== undefined && truck.dispatch_date !== '--/--/--')
  }

  function closeClicked(): void {
    if (!isDispatchDateSet()) {
      setShow(true);
    } else {
      submitForm(false);
    }
  }

  function getFormattedStartDate(): string {
    return (moment(startDate)).format('YYYY/MM/DD');
  }

  function submitForm(includeDate: boolean): void {

    let hash = includeDate ? { truck: { status: 'closed', dispatch_date: getFormattedStartDate() } } : { truck: { status: 'closed' } };

    Axios({
      method: "POST",
      url: `/trucks/${truck.id}/close`,
      data: hash,
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        setShow(false);
        console.log("Truck is CLOSED", res.data)
        if (res.data['status'] === 'ok') {
          props.closeTruckHandler ? props.closeTruckHandler(res.data["truck"]) : window.location.reload();
        }
        else {
          toastr.error("Truck is NOT closed", res.data['errors'])
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <Button className="btn btn-primary btn-lg" onClick={() => closeClicked()}>
        Close
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Select Dispatch Date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p>
            To close a truck you need to set a 'Dispatch Date' first:
          </p>

          <DatePicker
            minDate={today}
            selected={startDate}
            dateFormat="yyyy/MM/dd"
            shouldCloseOnSelect={true}
            onChange={(date) => setStartDate(date)} />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="primary" onClick={() => submitForm(true)}>
            Save
          </Button>

        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default CloseButton
