import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { GoodData, int, ProductData } from '../../Common/types';
import axios from 'axios';
import { PRODUCT_SEARCH_AFTER_CHAR } from '../Good';

const filter = createFilterOptions<ProductData>();

interface ProductSelectProps {
  barcode: string,
  index: number,
  htmlFieldName: string,
  product: ProductData,
  error: boolean,
  helperText: string,
  defineNewHandler: (index: int, barcode: string | null) => void,
  changeHandler: (item: ProductData) => void,
  defineOnBlur: () => void
}
export default function ProductSelect(props: ProductSelectProps) {
  // const mountedRef = React.useRef(true);

  const [inputValue, setInputValue] = React.useState(''),
    [value, setValue] = React.useState(props.product),

    [products, setProducts] = React.useState(new Array<ProductData>(props.product)),
    [isLoading, setIsLoading] = React.useState(false),

    handleChange = (item: ProductData) => {
      if (item && item.id == 0) {
        props.defineNewHandler(props.index, props.barcode)
      }
      else if (item) {
        setValue(item)
        props.changeHandler(item);
      }
    },

    getProducts = () => {
      setIsLoading(true);
      const url = `/products.json?${inputValue.length > 0 ? `&q=${inputValue}` : ''}`
      axios({
        method: 'GET',
        url: url,
        headers: {
          accept: 'application/json'
        }
      })
        .then((res) => {
          setIsLoading(false);
          if (res.data.length > 0) {
            setProducts(res.data);
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }


  React.useEffect(() => {

    if (inputValue && inputValue.length > PRODUCT_SEARCH_AFTER_CHAR) {
      getProducts()
    }

  }, [inputValue]);

  // React.useEffect(() => {
  //   return () => {
  //     mountedRef.current = false
  //   }
  // }, [])

  return (
    <div>
      <input type="hidden" name={props.htmlFieldName} value={value.id} />
      <Autocomplete
        onBlur={props.defineOnBlur}
        options={products}

        getOptionLabel={(option) => (option ? option.name : '')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        includeInputInList
        filterSelectedOptions
        filterOptions={(options, params) => {

          if (params.inputValue !== '') {
            const filtered = filter(options, params);
            filtered.push({
              id: 0,
              name: "Add new"
            });
            return filtered;
          } else {
            return options
          }

        }}

        value={value}
        onChange={(event: any, newValue: any) => {
          handleChange(newValue);
        }}
        onInputChange={(e, newInputValue, reason) => {
          setInputValue(newInputValue)

          // if (reason === 'clear') {
          //   setValue({ id: 0, name: '' });
          //   props.changeHandler({ id: 0, name: '', barcode: '' })
          // }
        }
        }
        renderInput={(params) => (
          <TextField {...params}
            error={props.error}
            helperText={props.helperText}
            label="Product" fullWidth />
        )}

        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id} value={option.id}>
              {option.name}
            </li>
          );
        }}

      />
    </div>
  );



}
