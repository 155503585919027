import React from 'react'
import * as i18n from "i18n-js"

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface PagingProps {
  rows: number,
  page: number,
  lastPage: number,
  onRowsChange: (rowsCount) => void,
  onNextClick: () => void,
  onPrevClick: () => void,
}

const Paging = (props: PagingProps) => {

  function selectionChanged(event: React.SyntheticEvent): void {
    const target = event.target as HTMLSelectElement;
    const newVal = parseInt(target.value)
    props.onRowsChange(newVal)
  }

  return (
      <div className="btn-group float-right">
        <select className="btn btn-primary btn-sm"
          onChange={selectionChanged} value={props.rows} title="Records per page" >
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="60">60</option>
          <option value="80">80</option>
        </select> &nbsp;&nbsp;
        <button
          disabled={(props.page < 2)}
          onClick={() => props.onPrevClick()}
          className="btn btn-white btn-sm"
          title={i18n.t('prev_page')}
        >
          <i className="fa fa-arrow-left"></i>
        </button>&nbsp;&nbsp;
        <span>{props.page}</span> &nbsp;&nbsp;
        <button
          disabled={!(props.page < props.lastPage)}
          onClick={() => props.onNextClick()}
          className="btn btn-white btn-sm"
          title={i18n.t('next_page')}
        >
          <i className="fa fa-arrow-right"></i>
        </button>
        <span > out of {props.lastPage}</span>
      </div>
  )
}

export default Paging
