
import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import { projectState } from '../Common/functions';

import { ProjectData } from '../Common/types';
import toastr from 'toastr'
import LaddaButton, { XL, SLIDE_UP } from 'react-ladda';

export interface SwapButtonProps {
  source_id: number
}

const SwapButton = (props: SwapButtonProps) => {


  const [show, setShow] = useState(false),
    [destId, setDestId] = useState(0),
    [projects, setProjects] = useState(new Array<ProjectData>());

  const [laddaState, setLaddaState] = React.useState({ loading: false, progress: 0 });

  const handleClose = () => {
    setShow(false);
    setDestId(0);
  }

  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  function submitForm(): void {
    laddaOn();

    Axios({
      method: "POST",
      url: `/swaps.json`,
      data: { swap: { source_id: props.source_id, dest_id: destId } },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();
        setShow(false);

        if (res.status == 201) {
          toastr.success("Swap created")
          window.location.reload();
        }
        else {
          toastr.error(res.data['errors'], "Swap error")
        }
      })
      .catch((err) => {
        laddaOff();
        console.log(err)
      })

  }

  function loadProjects() {
    Axios({
      url: `/swaps/candidate_projects.json?source_id=${props.source_id}`
    })
      .then((res) => {
        setProjects(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    loadProjects();
  }, [])

  return (
    <div>
      <button className="btn btn-info" onClick={() => setShow(true)}>
        Swap
      </button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Which project to swap for?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <table width="100%">
            <tr>
              <td></td>
              <th>Reseller</th>
              <th>Project</th>
              <th>ID</th>
              <th>State</th>
              <th>Order Date</th>
            </tr>
            {
              projects.map((p) => (
                <tr key={p.id}>
                  <td><input type="radio" id="html" name="dest_project" value={p.id} onClick={() => setDestId(p.id)} /></td>
                  <td>{p.customer.name}</td>
                  <td><a href={`${p.url}#tab-1`} target="_blank" >{p.name}</a></td>
                  <td>{p.visible_id}</td>

                  <td>{projectState(p)}</td>

                  <td>{p.order_date}</td>
                </tr>))
            }
          </table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>

          <LaddaButton
            className="btn btn-primary btn-lg"
            disabled={destId === 0}
            loading={laddaState.loading}
            onClick={() => submitForm()}
            data-color="#eee"
            data-size={XL}
            data-style={SLIDE_UP}
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
          >
            Swap
          </LaddaButton>

        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default SwapButton
