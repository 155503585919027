import React from 'react'
import HeaderSortableColumn from "./HeaderSortableColumn"

const HeaderWoodParts = () => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>Project ID</th>
        <HeaderSortableColumn column={'order_id'} label={'Order ID'} />
        <th><i className="fa fa-tree" title="Wood Parts" ></i></th>
        <HeaderSortableColumn column={'name'} label={'Name'} />
        <HeaderSortableColumn column={'deadline'} label={'Deadline'} />
        <th>State</th>
        <th>Progress</th>
        <HeaderSortableColumn column={'truck'} label={'Truck'} />
        <th>Customer</th>
        <th>Sales Manager</th>
        <th title="Wall logs">WL</th>
        <th title="Purlins, beams &amp; columns">PBC</th>
        <th title="Floor &amp; roof boards">FRB</th>
        <th title="Terrace boards">TB</th>
        <th title="Floor bearers">FB</th>
        <th title="Stairs &amp; fences">SF</th>
        <th title="Insulation beams">IB</th>
        <HeaderSortableColumn column={'order_date'} label={'Order date'} />
      </tr>
    </thead>
  )
}

export default HeaderWoodParts
