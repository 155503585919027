import * as React from "react"
import toastr from 'toastr'
import { isNullLiteralTypeAnnotation } from "../../../../../../.cache/typescript/4.3/node_modules/@babel/types/lib"
import CloseButton from "./CloseButton"
import DispatchButton from "./DispatchButton"

export interface ShortTruckData {
  id: number,
  status: string,
  dispatch_date: string,
  consec_number: string,
  all_projects_produced: boolean
}

const StatusSection = (props: ShortTruckData) => {

  const [truck, setTruck] = React.useState(props);

  function closeTruckHandler(newtruck: ShortTruckData): void {
    // console.log('closeTruckHandler', newtruck)
    toastr.success("Truck is CLOSED")
    setTruck(newtruck);
  }

  function dispatchTruckHandler(newtruck: ShortTruckData): void {
    // console.log('closeTruckHandler', newtruck)
    toastr.success("Truck is DISPATCHED")
    setTruck(newtruck);
  }

  function isDispatchDateSet(): boolean {
    return (truck.dispatch_date !== undefined && truck.dispatch_date !== '--/--/--')
  }

  const dispatch_date_text = isDispatchDateSet() ? truck.dispatch_date : "not_set"

  let action_button;

  if (truck.status === 'open') {
    action_button = <CloseButton truck={truck} closeTruckHandler={closeTruckHandler} />;
  } else if (truck.status === 'closed') {
    action_button = <DispatchButton
      truck={truck}
      dispatchTruckHandler={dispatchTruckHandler}
      all_projects_produced={props.all_projects_produced} />;
  } else {
    action_button = ''
  }

  return (
    <div className="ibox-content">
      <div className="form-group row"><label className="col-lg-5">Truck Number</label>
        <div className="col-lg-7">
          {truck.consec_number}
        </div>
      </div>

      <div className="form-group row"><label className="col-lg-5 ">Status</label>
        <div className="col-lg-7">
          {truck.status.toUpperCase()}
        </div>
      </div>
      <div className="form-group row"><label className="col-lg-5">Dispatch time</label>
        <div className="col-lg-7">
          {dispatch_date_text}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-12 text-center">
          {action_button}
        </div>
      </div>

    </div>
  )
}
export default StatusSection
