import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import * as i18n from "i18n-js";
import { RouteComponentProps } from "react-router-dom";
import { URLParamsReader } from '../Common/URLParamsReader';
import Paging from '../Notifications/Paging';
import { ProductData } from '../Common/types';
import TextSearchField from '../Notifications/TextSearchField';
import { Button, Link } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface ListProps extends RouteComponentProps<any> {
  history: string[],
  location: { pathname: string, search: string }
};

//@ts-ignore
i18n.translations = I18n.translations || {};

const BasicTable = (props: ListProps) => {
  const history = useHistory();
  const urlParams = new URLParamsReader(props.location.search);
  const [settings, setSettings] = useState({
    sidx: urlParams.getSidx('created_at'),
    sord: urlParams.getSord(),
    rows: urlParams.getRows(),
    page: urlParams.getPage(),
    query: urlParams.getString('query'),
  }),
    [lastPage, setLastPage] = useState(1),
    [products, setProducts] = useState(new Array<ProductData>()),
    [totalLength, setTotalLength] = useState(0),
    loadData = () => {
      let url_params = `rows=${settings.rows}&page=${settings.page}&sidx=${settings.sidx}&sord=${settings.sord}`;
      if (`${settings.query}`.length > 0) {
        url_params = `${url_params}&query=${settings.query}`
      }
      props.history.push(`/products?${url_params}`)

      axios({ url: `/products/data.json?${url_params}` })
        .then((res) => {
          setProducts(res.data.products)
          setTotalLength(res.data.records)
        })
        .catch((err) => {
          setProducts([])
          setTotalLength(0)
          console.log(err)
        })
    },
    handleNextPageClick = () => {
      if (settings.page < lastPage) {
        setSettings({ ...settings, page: settings.page + 1 })
      }
    },
    handlePrevPageClick = () => {
      if (settings.page > 1) {
        setSettings({ ...settings, page: settings.page - 1 })
      }
    },
    onRowsChange = (newRows: number) => {
      setSettings({ ...settings, page: 1, rows: newRows })
    },
    recalcLastPage = (): void => {
      setLastPage(Math.ceil(totalLength / settings.rows))
    },
    searchByQuery = (newVal: string) => {
      if (settings.query !== newVal) {
        setSettings({ ...settings, query: newVal })
      }
    },
    sortBy = (column: string): void => {
      if (settings.sidx === column) {
        const newdir = (settings.sord === 'desc' ? 'asc' : 'desc')
        setSettings({ ...settings, sord: newdir })
      }
      else {
        setSettings({ ...settings, sidx: column })
      }
      loadData();
    },
    editProduct = (prodId: number): void => {
      //   navigate(`/products/${prodId}/edit`, {replace: true});
      history.push(`/products/${prodId}/edit`)
    },
    getSortIcon = (column: string): string => {
      if (column === settings.sidx) {
        return (settings.sord === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up');
      }
      else {
        return 'fa fa-sort';
      }
    };

  useEffect(() => {
    recalcLastPage()
  }, [totalLength, settings])

  useEffect(() => {
    loadData();
  }, [settings])

  return (
    <div>
      <div className="row">
        <div className="col-md-3 text-left" >
          <a className="btn btn-primary" href="/notes/new?inventory=true&typ=R">+Inventory</a>
        </div>
        <div className="col-md-9 text-right row" style={{ gap: 10 }} >
          <a className="btn btn-primary" href="/notes">All notes</a>
          <a className="btn btn-primary" href="/notes/new?typ=R">+Receive</a>
          <a className="btn btn-primary" href="/notes/new?typ=I">+Issue</a>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-6">
          <TextSearchField
            inputText={settings.query}
            placeholder={"Search by barcode or name"}
            searchBy={searchByQuery}
          />
        </div>
        <div className="col-lg-6 animated fadeInRight">
          <Paging
            onRowsChange={onRowsChange}
            onNextClick={handleNextPageClick}
            onPrevClick={handlePrevPageClick}
            rows={settings.rows}
            page={settings.page}
            lastPage={lastPage}
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell onClick={() => sortBy('name')}>
                Name &nbsp;&nbsp;&nbsp;
                <span>
                  <i className={getSortIcon('name')}></i>
                </span>
              </TableCell>
              <TableCell align="right">Variant label</TableCell>
              <TableCell align="right">Unit sym</TableCell>
              <TableCell align="right">STOCK</TableCell>
              <TableCell onClick={() => sortBy('barcode')} align="right">
                Barcode &nbsp;&nbsp;&nbsp;
                <span>
                  <i className={getSortIcon('barcode')}></i>
                </span>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell  >
                  <a href={row.url}><img src={row.image}></img></a>
                </TableCell>

                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.variant_label}</TableCell>
                <TableCell align="right">{row.unit_sym}</TableCell>
                <TableCell align="right">{row.on_stock}</TableCell>
                <TableCell align="right">{row.barcode}</TableCell>
                <TableCell ><Link href={`/products/${row.id}/edit`} underline="none" >Edit</Link></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default BasicTable;
