import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { TruckData, ProjectData } from "../Common/types"
import Axios from 'axios'
import toastr from 'toastr'
import styled from "styled-components"
import LaddaButton, { XL, SLIDE_UP } from 'react-ladda';
import TruckButtonApp from '../ProductionQueue/TruckButtonApp';

const Wrapper = styled.div`
    padding: 1em;
    background: papayawhip;
  `;

  //@ts-ignore
// i18n.translations = I18n.translations || {};

export interface AssignTruckProps {
  selectedItems: number[],
  projects: ProjectData[]
  onUpdateTruckHandler: () => void
}

const AssignTruck = (props: AssignTruckProps) => {

  const [trucks, setTrucks] = useState(new Array<TruckData>());
  const [selectedTruckId, setSelectedTruckId] = useState(0);
  const [data, setData] = useState<{hasStockProject: boolean, customerIds: number[]}>({ hasStockProject: false, customerIds: [] });
  const [laddaState, setLaddaState] = React.useState({ loading: false, progress: 0 });

  function loadTrucks(): void {
    Axios({ url: `/trucks/not_dispatched.json` })
      .then((res) => {
        setTrucks(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function selectionChanged(event: React.SyntheticEvent): void {
    const target = event.target as HTMLSelectElement
    setSelectedTruckId(parseInt(target.value, 0))
  }

  React.useEffect(() => {
    loadTrucks();
  }, [])


  useEffect(() => {
    const has_stock_proj = props.projects
      .filter((p) => props.selectedItems.indexOf(p.id) > -1)
      .filter((p) => !p.customer).length > 0;


    if (has_stock_proj) {
      setData({ hasStockProject: true, customerIds: [] })
    }
    else {

      const proj_customers = props.projects
        .filter((p) => props.selectedItems.indexOf(p.id) > -1)
        .map((p) => p.customer.id);

      setData({ hasStockProject: false, customerIds: [...new Set(proj_customers)] })
    }

  }, [props.selectedItems, props.projects]);

  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  function updateTruck(): void {

    if (props.selectedItems.length === 0) {
      return
    }

    laddaOn();

    Axios({
      method: "POST",
      url: `/trucks/${selectedTruckId}/assign_projects.json`,
      data: { project_ids: props.selectedItems },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();
        loadTrucks();
        props.onUpdateTruckHandler();
        toastr.success("Truck is created")
      })
      .catch((err) => {
        laddaOff();
        console.log(err);
        toastr.error("Truck is NOT created", err)
      })

  }


  const warning = (data.hasStockProject ?
    (<span className="text-danger" title="STOCK projects can't have truck" ><i className="fa fa-exclamation-triangle"></i> STOCK project selected</span>)
    : (data.customerIds.length > 1 ? (<span className="text-danger" title="Different customer projects selected" ><i className="fa fa-exclamation-triangle"></i> Mixed customers</span>) : ""));

  const options =
    trucks.filter((t) => t.customer && t.customer.id === data.customerIds[0]).map(t => (
      <option key={t.id} value={t.id} >
        ({t.id}) {t.customer?.name} {t.payload_pct}%
      </option>
    ))

  return (
    <Wrapper className="col-md-6">
      <div className="row">
        <div className="col-md-4 d-flex align-items-center justify-content-end">Truck: {warning}</div>

        {/* <div className="col-md-4">
          <span className="select">
            <select className="form-control" disabled={data.hasStockProject || props.selectedItems.length === 0} onChange={selectionChanged} value={selectedTruckId}>
              <option key={0} value={0}>
                (-) NEW ...
              </option>
              {options}
            </select>
          </span>
        </div> */}

        <div className="col-md-4">
          {/* <LaddaButton
            className="btn btn-primary btn-lg"
            disabled={data.hasStockProject || props.selectedItems.length === 0}
            loading={laddaState.loading}
            onClick={updateTruck}
            data-color="#eee"
            data-size={XL}
            data-style={SLIDE_UP}
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
          >
            Assign
          </LaddaButton> */}
          <TruckButtonApp
            project_id={0}
            truck_id={0}
            projects_ids={props.selectedItems}
            projects_ids_has_stock={data.hasStockProject}
            reassign_mode={false}
          />
        </div>
      </div>
    </Wrapper>
  );
}
export default AssignTruck


