
import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { colorPriority } from '../Common/functions';
import { ISelectOption } from '../Common/types';
import { RoleContext } from './List';

interface PriorityFieldProps {
  priority: string,
  taskId: number,
  updateField: (taskId: number, data: any) => void
}

export const AllPriorities: ISelectOption[] = [
  { value: 'low', label: 'LOW' },
  { value: 'normal', label: 'NORMAL' },
  { value: 'high', label: 'HIGH' },
  { value: 'urgent', label: 'URGENT' }]

const PriorityField = (props: PriorityFieldProps) => {

  const
    roleContext = React.useContext(RoleContext);

  const allowedToChange = roleContext.hasRole('super_admin') || roleContext.hasRole('pm_production_manager_wooden')

  const [priority, setPriority] = useState({
    label: props.priority.toUpperCase(),
    value: props.priority
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChange(opt: ISelectOption) {
    setPriority(opt);
    props.updateField(props.taskId, { priority: opt.value })
    handleClose()
  }


  return (


    <>
      {allowedToChange &&
        <a href="#" onClick={handleClick} > {colorPriority(priority.value)} </a>
      }
      {!allowedToChange &&
        <span>{colorPriority(priority.value)}</span>
      }

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        anchorReference={'anchorEl'}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          AllPriorities.map((ps) =>
            <MenuItem key={ps.value} onClick={() => handleChange(ps)}>{colorPriority(ps.value)}</MenuItem>
          )
        }

      </Menu>
    </>


  )
}

export default PriorityField
