import * as React from "react"
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import toastr from 'toastr'
import { ShortTruckData } from './StatusSection'

export interface DispatchButtonProps {
  truck: ShortTruckData,
  dispatchTruckHandler?: (newtruck: ShortTruckData) => void,
  all_projects_produced: boolean
}

const DispatchButton = (props: DispatchButtonProps) => {

  const [truck, setTruck] = React.useState(props.truck);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);

  function dispatchClicked(): void {
    if (!props.all_projects_produced) {
      setShow(true);
    } else {
      submitForm();
    }
  }

  function submitForm() {
    Axios({
      method: "POST",
      url: `/trucks/${truck.id}/dispatch`,
      data: { truck: { status: 'dispatched' } },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        if (res.data['status'] === 'ok') {
          props.dispatchTruckHandler ? props.dispatchTruckHandler(res.data["truck"]) : window.location.reload();
        }
        else {
          toastr.error("Truck is NOT dispatched", res.data['errors'])
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <Button className="btn btn-primary btn-lg" onClick={() => dispatchClicked()}>
        Dispatch
      </Button>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-exclamation-triangle"></i> Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Not all projects have <span className="label label-success">In Delivery</span> status yet.
          </p>
          <p>Clicking 'OK' will do set them automatically all as <span className="label label-info">Delivered</span>.
          </p>
          <p>
            Are you sure to continue?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="primary" onClick={() => submitForm()}>
            Continue
          </Button>

        </Modal.Footer>
      </Modal>

    </div>
  )
}
export default DispatchButton
