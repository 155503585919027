
import React, { useEffect, useState } from 'react'
import { ProjectData } from "../Common/types"

export interface ReportButtonsProps {
  selectedItems: number[],
  projects: ProjectData[]
}


const ReportButtons = (props:ReportButtonsProps) => {

  const [totalPrice, setTotalPrice] = useState(0);

  function generateReport(): void {

    if (props.selectedItems.length === 0) {
      return
    }
    let query_params = props.selectedItems.map((id) => `projects[]=${id}` ).join('&')
    window.open(`/reports?${query_params}`, '_blank').focus();
  }

  useEffect(() => {
    const x = props.projects
      .filter((p) => props.selectedItems.indexOf(p.id) > -1)
      .reduce((sum, proj) => sum + proj.price, 0);

    setTotalPrice(x)

  }, [props.selectedItems])

  return (
    <div className="col-md-6">
      <h2> Selected ({props.selectedItems.length}) - Price: € {totalPrice}</h2>
      Show aggregated values for:
      <button name='material1' disabled className="btn btn-default" >1. Woods</button>
      <button name='material2'disabled={props.selectedItems.length === 0} onClick={generateReport} className="btn btn-default" >2. Windows&amp;doors</button>
      <button name='material3' disabled className="btn btn-default" >3. Instalation kit</button>
      <button name='material4' disabled className="btn btn-default" >4. Insulation</button>
      <button name='all' disabled className="btn btn-default" value="all">All</button>
    </div>
  )
}
export default ReportButtons


