
import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import { ProjectData } from '../Common/types';
import toastr from 'toastr'
import LaddaButton, { XL, SLIDE_UP } from 'react-ladda';

export interface CopyButtonProps {
  project_id: number,
  tab_index: number,
  copiedSuccess: () => void,
}

const CopyButton = (props: CopyButtonProps) => {


  const [show, setShow] = useState(false),
    [sourceId, setSourceId] = useState(0),
    [projects, setProjects] = useState(new Array<ProjectData>());

  const [laddaState, setLaddaState] = React.useState({ loading: false, progress: 0 });

  const handleClose = () => setShow(false);
  const handleOpen = () => {
    setShow(true);
    loadProjects();
  }

  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  function submitForm(): void {
    laddaOn();

    Axios({
      method: "POST",
      url: `/projects/${props.project_id}/packing_items_copy`,
      data: { source_project_id: sourceId },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();
        setShow(false);
        if (res.data['status'] === 'ok') {
          toastr.success("Package items copied.")
          // window.location.reload();
          props.copiedSuccess();
        }
        else {
          toastr.error(res.data['errors'], "Copy error")
        }
      })
      .catch((err) => {
        laddaOff();
        console.log(err)
      })

  }

  function loadProjects() {
    Axios({
      url: `/projects/${props.project_id}/projects_matches.json`
    })
      .then((res) => {
        setProjects(res.data.projects)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <div>
      <a href="#" onClick={() => handleOpen()}>
        Search out history
      </a>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Matching historic projects
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p>
            Select project as a source and click 'Copy'
          </p>

          <table width="100%">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Name</th>
                <th>Order Date</th>
              </tr>
            </thead>
            <tbody>
              {
                projects.map((p) => (
                  <tr key={p.id}>
                    <td><input type="radio" id="html" name="source_project" value={p.id} onClick={() => setSourceId(p.id)} /></td>
                    <td><a href={`${p.url}#tab-${props.tab_index}`} target="_blank" >{p.visible_id}</a></td>
                    <td><a href={`${p.url}#tab-${props.tab_index}`} target="_blank" >{p.name}</a></td>
                    <td>{p.order_date}</td>
                  </tr>))
              }
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>

          <LaddaButton
            className="btn btn-primary btn-lg"
            disabled={sourceId === 0}
            loading={laddaState.loading}
            onClick={() => submitForm()}
            data-color="#eee"
            data-size={XL}
            data-style={SLIDE_UP}
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
          >
            Copy
          </LaddaButton>

        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default CopyButton
