import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import Axios from 'axios'
import toastr from 'toastr'
import ReactHtmlParser from 'react-html-parser'
import * as i18n from "i18n-js"
import { OrderData } from '../Common/types';
import { customerLink, orderLink } from '../Common/functions';

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface ListItemProps {
  order: OrderData
  onDestroyHandler: () => void
}

const ListItem = (props: ListItemProps) => {


  return (
    <tr className={`deadline2-${props.order.deadline_color}`}>
      <td>
        {orderLink(props.order)}
      </td>
      <td>
        {props.order.ref_no}
      </td>
      <td>
        {props.order.project_count}
      </td>
      <td>
        {props.order.total_price}
      </td>
      <td>
        {props.order.shipping_price}
      </td>
      <td>
        {props.order.order_date}
      </td>
      <td>
        {props.order.deadline}
      </td>
      <td>
        {customerLink(props.order.customer)}
      </td>
      <td>
        {props.order.sales_manager}
      </td>
      <td>{props.order.comments} </td>
      <td>

        <a href={`/orders/${props.order.id}`} className="btn btn-primary">Show</a>
      </td>
    </tr>

  )
}

export default ListItem
