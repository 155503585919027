import * as i18n from 'i18n-js';
import React from 'react';
import {
  customerLink,
  customSym,
  orderLink,
  paymentNumbers,
  projectLink,
  projectState,
  stockSym,
  swapSym,
  tasksLink,
} from '../Common/functions';
import { ProjectData } from '../Common/types';
import TruckInfo from './TruckInfo';

declare function formatMoney(
  number: number,
  decPlaces: number,
  decSep: string,
  thouSep: string
): string;

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface ListItemProps {
  isChecked: boolean;
  project: ProjectData;
  onCheckboxClick: (project: ProjectData, checked: boolean) => void;
  onTruckUpdated: () => void;
}

const ListItem = (props: ListItemProps) => {
  function toggleChecked(): void {
    props.onCheckboxClick(props.project, !props.isChecked);
  }

  return (
    <tr className={`deadline2-${props.project.deadline_color}`}>
      <td onClick={() => toggleChecked()}>
        <input
          type="checkbox"
          checked={props.isChecked}
          onChange={() => toggleChecked()}
          className="i-checks"
        />
      </td>
      <td>
        {props.project.xls_file1_wood ? (
          <i className="fa fa-check text-navy"></i>
        ) : (
          ''
        )}
      </td>
      <td>{orderLink(props.project.order, props.project.order_visible_id)}</td>
      <td>{props.project.visible_id}</td>
      <td>
        {stockSym(props.project)} {customSym(props.project)}{' '}
        {swapSym(props.project)} {projectLink(props.project, false, true)}
      </td>
      <td>
        {props.project.truck && (
          <TruckInfo
            truck={props.project.truck}
            onTruckUpdated={props.onTruckUpdated}
          ></TruckInfo>
        )}
      </td>
      <td className="text-right">
        {formatMoney(props.project.price, 0, '.', ',')}
      </td>
      <td>{paymentNumbers(props.project)}</td>
      <td>{props.project.order_date}</td>
      <td>
        {props.project.deadline} ({props.project.days_to_deadline})
      </td>
      <td>{projectState(props.project)}</td>
      <td>{tasksLink(props.project)}</td>
      <td>{props.project.code}</td>
      <td>{customerLink(props.project.customer)}</td>
      <td>{props.project.sales_manager}</td>
    </tr>
  );
};

export default ListItem;
