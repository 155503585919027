import React, { useState, useEffect } from 'react'
import { int, SupplierData } from '../../Common/types';
import SupplierSelect from "./SupplierSelect"
import { fieldName } from './CommonFuncs';
import DefineSup from '../DefineSup';

interface SupplierFieldProps {
  index: number,
  supplier: SupplierData,
  readonly: boolean,
  autoValidate: boolean,
  autoFocus: boolean,
  changeSupplier: (newSupplier: SupplierData) => void,
  onChange: (newSupplier: SupplierData) => void
}

const SupplierField = (props: SupplierFieldProps) => {

  const [supplier, setSupplier] = useState(props.supplier),
    [errorText, setErrorText] = useState(''),
    [showDefine, setShowDefine] = useState(false),

    changeSupplierHandler = (sup: SupplierData): void => {
      // console.log('changeSupplierHandler', sup)
      setSupplier(sup);
      props.onChange(sup)
      validate(sup)
    },
    validate = (val: SupplierData) => {
      if (val && val.id > 0) {
        setErrorText("")
      } else {
        setErrorText("cannot be empty")
      }
    },
    defineNewSupplierHandler = (index: int): void => {
      setShowDefine(true)
    },
    supplierAddedHandler = (newSupplier: SupplierData): void => {
      setSupplier(newSupplier);
      setShowDefine(false);
      props.changeSupplier(newSupplier);
    };

  useEffect(() => {
    if (props.autoValidate) {
      validate(supplier);
    }
  }, [props.autoValidate])


  useEffect(() => {
    // skip at entrance
    if (props.supplier !== supplier) {
      validate(supplier);
    }
  }, [supplier])

  return (


    <div className="col-12 col-md-12 col-xl-2">
      {props.readonly && supplier && supplier.name}
      {!props.readonly &&
        <SupplierSelect
          defineOnBlur={() => validate(supplier)}
          index={props.index}
          supplier={supplier}
          defineNewHandler={defineNewSupplierHandler}
          changeHandler={changeSupplierHandler}
          key={supplier ? supplier.id : 0}
          htmlFieldName={fieldName(props.index, 'supplier_id')}
          error={errorText !== ''}
          helperText={errorText}
        />

      }
      <DefineSup show={showDefine}
        handleClose={() => { setShowDefine(false) }}
        supplierAdded={supplierAddedHandler}></DefineSup>
    </div>

  )
}

export default SupplierField
