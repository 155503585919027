import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import { RouteComponentProps } from "react-router-dom";
import Select from 'react-select';
import { hasAnyRole } from '../Common/functions';
import { ISelectOption, ProjectData, ROLES_LIST } from "../Common/types";
import { URLParamsReader } from "../Common/URLParamsReader";
import Paging from '../Notifications/Paging';
import TextSearchField from '../Notifications/TextSearchField';
import { AllProgressStatuses } from '../TaskManage/StatusField';
import BottomMenu from './BottomMenu';
import HeaderDefault from './HeaderDefault';
import HeaderWoodParts from './HeaderWoodParts';
import TableRowDefault from './TableRowDefault';
import TableRowWoodParts from './TableRowWoodParts';





interface ISearchContext {
  sortBy: (column: string) => void,
  getSortIcon: (column: string) => string
}

// export const SearchContext = React.createContext(
//   [{ sortBy: (column: string) => { }, getSortIcon: (column: string) => '' }, (c: ISearchContext) => { }] as [ISearchContext, Dispatch<SetStateAction<ISearchContext>>]);

export const SearchContext = React.createContext<ISearchContext>({} as ISearchContext);


interface ListProps extends RouteComponentProps<any> {
  history: string[],
  location: { pathname: string, search: string },
  roles: string[],
}

export const TT_FLOOR_BEARERS = "1"
export const TT_WALL_BEAMS = "2"
export const TT_PURLINS = "3"
export const TT_ROOF = "4"
export const TT_WINBOARDS_TRIMS = "5"
export const TT_STAIRS = "6"
export const TT_WOODEN_PARTS = "1,2,3,4,5,6"
export const TT_WINDOWS = "7"
export const TT_INSULATION = "8"
export const TT_INSTALLATION = "9"
export const TT_ROOF_COVERS = "10"
export const TT_IMPREGNATION = "11"
export const TT_PACKAGING = "12"


const List = (props: ListProps) => {

  const urlParams = new URLParamsReader(props.location.search, "status=1,CONFIRMED:2,WAITING:3,IN_PRODUCTION:4,IN_DELIVERY");

  const [settings, setSettings] = useState({
    tasktype: urlParams.getString('tasktype'),
    taskstatus: urlParams.getISelect('taskstatus', { label: 'TODO', value: 'todo' }),
    sidx: urlParams.getSidx('created_at'),
    sord: urlParams.getSord(),
    rows: urlParams.getRows(),
    page: urlParams.getPage(),
    customer: urlParams.getISelect('customer'),
    manager: urlParams.getISelect('manager'),
    query: urlParams.getString('query'),
    order_id: urlParams.getString('order_id'),
    status: urlParams.getISelectArray('status'),
  });

  const isManager = hasAnyRole(props.roles, [
    ROLES_LIST.SUPER_ADMIN,
    ROLES_LIST.PRODUCTION_WOODEN,
    ROLES_LIST.PRODUCTION_WINDOORS,
    ROLES_LIST.PACKING_MANAGER
  ])

  const
    [selectedItems, setSelectedItems] = useState(new Array<number>()),
    [totalLength, setTotalLength] = useState(0),
    [projects, setProjects] = useState(new Array<ProjectData>()),
    [lastPage, setLastPage] = useState(1),
    [customers, setCustomers] = useState(new Array<ISelectOption>()),
    [managers, setManagers] = useState(new Array<ISelectOption>()),
    [statuses, setStatuses] = useState(new Array<ISelectOption>()),
    handleCheckboxClick = (project: ProjectData, checked: boolean) => {
      if (checked) {
        setSelectedItems([...selectedItems, project.id])
      } else {
        setSelectedItems(selectedItems.filter(n => n != project.id))
      }
    },
    handleRefreshClick = () => {
      loadData()
    },
    handleNextPageClick = () => {
      if (settings.page < lastPage) {
        setSettings({ ...settings, page: settings.page + 1 })
      }
    },
    handlePrevPageClick = () => {
      if (settings.page > 1) {
        setSettings({ ...settings, page: settings.page - 1 })
      }
    },
    onRowsChange = (newRows: number) => {
      setSettings({ ...settings, page: 1, rows: newRows })
    };



  function statusesUpdated() {
    setSelectedItems([])
    loadData();
  }

  function sortBy(column: string): void {
    if (settings.sidx === column) {
      const newdir = (settings.sord === 'desc' ? 'asc' : 'desc')
      setSettings({ ...settings, sord: newdir })
    }
    else {
      setSettings({ ...settings, sidx: column })
    }
    handleRefreshClick();
  }

  function getSortIcon(column: string): string {
    if (column === settings.sidx) {
      return (settings.sord === 'desc' ? 'fa fa-sort-down' : 'fa fa-sort-up');
    }
    else {
      return 'fa fa-sort';
    }
  }

  function recalcLastPage(): void {
    setLastPage(Math.ceil(totalLength / settings.rows))
  }

  function loadFiltersData() {
    Axios({ url: `/project_tasks/fdata.json` })
      .then((res) => {
        setCustomers(res.data.customers)
        setManagers(res.data.managers)
        setStatuses(res.data.statuses)

      })
      .catch((err) => {
        console.log(err)
      })
  }


  function loadData() {
    let url_params = `tasktype=${settings.tasktype}&rows=${settings.rows}&page=${settings.page}&sidx=${settings.sidx}&sord=${settings.sord}`;

    if (settings.taskstatus) {
      url_params = `${url_params}&taskstatus=${settings.taskstatus.value},${settings.taskstatus.label}`
    }

    if (settings.customer) {
      url_params = `${url_params}&customer=${settings.customer.value},${settings.customer.label}`
    }
    if (settings.manager) {
      url_params = `${url_params}&manager=${settings.manager.value},${settings.manager.label}`
    }
    if (settings.status.length > 0) {
      const st_all = settings.status.map((st) => `${st.value},${st.label}`).join(":")
      url_params = `${url_params}&status=${st_all}`
    }
    if (`${settings.order_id}`.length > 0) {
      url_params = `${url_params}&order_id=${settings.order_id}`
    }
    if (`${settings.query}`.length > 0) {
      url_params = `${url_params}&query=${settings.query}`
    }
    props.history.push(`${props.location.pathname}?${url_params}`)

    Axios({
      method: 'GET',
      url: `/project_tasks/data.json?${url_params}`,
      headers: {
        accept: 'application/json'
      }
    })
      .then((res) => {
        if (res.data.projects.length > 0) {
          setProjects(res.data.projects)
          setTotalLength(res.data.records)
        } else {
          setProjects([])
          setTotalLength(0)
        }


        // if (settings.page > 1 && settings.page > lastPage) {
        //   setSettings({ ...settings, page: 1 })
        // }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlePageClick = (event) => {
    setSettings({ ...settings, page: event.selected + 1 })
  }


  function searchByOrderId(newVal: string) {
    if (settings.order_id !== newVal) {
      setSettings({ ...settings, order_id: newVal })
    }
  }

  function searchByQuery(newVal: string) {
    if (settings.query !== newVal) {
      setSettings({ ...settings, query: newVal })
    }
  }

  function visibleItems(): React.ReactNode[] {
    return projects.map((n) => (
      <TableRowDefault
        key={n.id}
        project={n}
        tasktype={settings.tasktype}
        isChecked={selectedItems.find(i => { return i === n.id || false }) !== undefined}
        onCheckboxClick={handleCheckboxClick}
        onTruckUpdated={loadData}
      />
    ))
  }

  function visibleWoodPartItems(): React.ReactNode[] {
    return projects.map((n) => (
      <TableRowWoodParts
        key={n.id}
        project={n}
        isChecked={selectedItems.find(i => { return i === n.id || false }) !== undefined}
        onCheckboxClick={handleCheckboxClick}
        onTruckUpdated={loadData}
      />
    ))
  }

  function handleCustomerChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, customer: opt })
    } else {
      setSettings({ ...settings, customer: null })
    }
  }

  function handleManagerChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, manager: opt })
    } else {
      setSettings({ ...settings, manager: null })
    }
  }

  function handleTaskStatusChange(opt: ISelectOption): void {
    if (opt) {
      setSettings({ ...settings, taskstatus: opt })
    } else {
      setSettings({ ...settings, taskstatus: null })
    }
  }

  function handleStatusChange(opt: ISelectOption[]): void {
    if (opt) {
      setSettings({ ...settings, status: opt })
    } else {
      setSettings({ ...settings, status: null })
    }
  }

  useEffect(() => {
    recalcLastPage()
  }, [totalLength, settings])


  useEffect(() => {
    loadData();
  }, [settings])

  useEffect(() => {
    loadFiltersData();
  }, [])

  return (
    <div className="wrapper wrapper-content">

      <div className="ibox-content m-b-sm border-bottom">
        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <h2>Tasks ({totalLength})</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <TextSearchField
              inputText={settings.query}
              placeholder={"Project ID or name"}
              searchBy={searchByQuery} ></TextSearchField>
          </div>
          <div className="col-sm-1">
            <TextSearchField
              inputText={settings.order_id}
              placeholder={"Order Id"}
              searchBy={searchByOrderId} ></TextSearchField>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Customer"
                options={customers}
                defaultValue={settings.customer}
                onChange={val => handleCustomerChange(val)}
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isClearable
                placeholder="Sales manager"
                options={managers}
                defaultValue={settings.manager}
                onChange={val => handleManagerChange(val)}
              />
            </div>
          </div>

          <div className="col-sm-2">
            <div className="form-group">
              <Select
                placeholder="Progress"
                options={AllProgressStatuses}
                defaultValue={settings.taskstatus}
                onChange={val => handleTaskStatusChange(val)}
              />
            </div>
          </div>

          <div className="col-sm-2">
            <div className="form-group">
              <Select
                isMulti
                placeholder="Status"
                options={statuses}
                defaultValue={settings.status}
                onChange={val => handleStatusChange(val)}
              />
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-lg-12 animated fadeInRight">
            <Paging
              onRowsChange={onRowsChange}
              onNextClick={handleNextPageClick}
              onPrevClick={handlePrevPageClick}
              rows={settings.rows}
              page={settings.page}
              lastPage={lastPage}
            />
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-lg-12 animated fadeInRight">
          <div className="mail-box">
            <table className="footable table table-hover table-mail footable-loaded ">

              <SearchContext.Provider value={{ sortBy: sortBy, getSortIcon: getSortIcon }}>
                {settings.tasktype !== TT_WOODEN_PARTS && <HeaderDefault tasktype={settings.tasktype} />}
                {settings.tasktype === TT_WOODEN_PARTS && <HeaderWoodParts />}
              </SearchContext.Provider>
              <tbody>
                {settings.tasktype === TT_WOODEN_PARTS && visibleWoodPartItems()}
                {settings.tasktype !== TT_WOODEN_PARTS && visibleItems()}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={18} className="footable-visible">
                    <ReactPaginate id="react-paginate"
                      // labels

                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      breakLabel={'…'}

                      disabledClassName={'disabled'}

                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination pull-right'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}

                      // functionality
                      initialPage={settings.page - 1}
                      pageCount={lastPage} //total pages
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      //   forcePage={5}
                      onPageChange={(event) => { handlePageClick(event) } /*Fxn when page clicked*/}
                      disableInitialCallback={true}
                    />

                  </td>
                </tr>

              </tfoot>
            </table>
          </div>
        </div>
      </div>


      {
        hasAnyRole(props.roles, []) &&
        <div className="ibox">
          <div className="ibox-content">
            {
              isManager &&
              <BottomMenu
                selectedItems={selectedItems}
                projects={projects}
                tasktype={settings.tasktype}
                taskstatus={settings.taskstatus}
                statusesUpdated={statusesUpdated}
              ></BottomMenu>
            }

          </div>

        </div>

      }

    </div >
  )
}

export default List
