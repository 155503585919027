import * as React from "react"
import { ProjectData, TruckData, CustomerData, OrderData, ROLES_LIST, TaskStatusVal } from "./types"
import * as i18n from "i18n-js"
import * as moment from 'moment';

declare function formatMoney(number: number, decPlaces: number, decSep: string, thouSep: string): string;

i18n.translations = {}
declare var I18n: any;
if (typeof I18n !== 'undefined') {
  i18n.translations = I18n.translations;
}
export function hasAnyRole(roles: string[], checkedRoles: string[]): boolean {
  checkedRoles.push(ROLES_LIST.SUPER_ADMIN);
  return checkedRoles.some((role) => roles.includes(role));
}

export function truckDisplayColor(pct: number): string {
  if (pct > 100) {
    return "truck-100"
  }
  else if (pct > 90) {
    return "truck-90"
  }
  else if (pct > 10) {
    return "truck-10"
  }
  else {
    return null
  }
}

export function paymentDisplayColor(is_paid: boolean): string {
  return is_paid ? "is-paid" : "is-not-paid"
}

export function projectDetails(project: ProjectData) {

  if (project.order) {
    const project_type = project.project_type ? i18n.t(`project_types.${project.project_type}`) : "n/a"
    const project_type_title = `Type: ${project_type}`

    const domain = `https://${project.order.domain}`
    const domain_title = `${i18n.t("domain")}: ${domain}`

    const country = project.order.country ? project.order.country : "n/a";
    const country_title = `${i18n.t("country")}: ${country}`

    const manager = project.sales_manager;
    const manager_title = `${i18n.t("sales_manager")}: ${manager}`

    return `${project_type_title}\n${domain_title}\n${country_title}\n${manager_title}`

  }
  else {
    return "STOCK project"
  }
}

export function paymentNumbers(project: ProjectData): React.ReactNode {
  if (project.order) {

    return <div>
      <span title={project.advanced_paid ? project.advanced_paid_date : ""}
        className={paymentDisplayColor(project.advanced_paid)}>{formatMoney(project.advanced_payment, 0, ".", ",")}</span>
      /
      <span title={project.full_paid ? project.full_paid_date : ""}
        className={paymentDisplayColor(project.full_paid)} >{formatMoney(project.left_payment, 0, ".", ",")}</span></div>
  } else {
    return "n/a"
  }
}

export function customSym(project: ProjectData): React.ReactNode {
  return !project.is_standard ? <sup><span className="label label-danger" title="Custom project">C</span></sup> : "";
}

export function stockSym(project: ProjectData): React.ReactNode {
  return project.was_on_stock ? <sup><span className="label label-primary" title="This project is or was on STOCK">S</span></sup> : "";
}

export function swapSym(project: ProjectData): React.ReactNode {
  return project.is_swap ? <sup><span className="label label-warning" title="Swap project">W</span></sup> : "";
}

export function customerLink(customer: CustomerData): React.ReactNode {
  return customer ? <a href={customer.url}>{customer.name}</a> : ""
}

export function orderLink(order: OrderData, label: string = null): React.ReactNode {
  //  return order ? <a href={order.url} title="PM order ID/ CP order ID">{order.id}/{order.cp_order_id}</a> : <a href={"/stock"}>STOCK</a>
  return order ? <a href={order.url} title="Order">{(label && label !== '') ? label : order.visible_id}</a> : <a href={"/stock"}>STOCK</a>
}

export function projectLink(project: ProjectData, includeId: boolean, includeName: boolean): React.ReactNode {
  // return project ? <a href={project.url} title="PM project ID/ CP project ID">{project.id}/{project.cp_project_id}</a> : ""
  return project ? <a href={project.url} title="Project" >{includeId ? project.visible_id : ""} {includeName ? project.name : ""}</a> : ""
}

export function projectLinkDecorated(project: ProjectData, includeId: boolean, includeName: boolean): React.ReactNode {
  return <>{stockSym(project)} {customSym(project)} {swapSym(project)} {projectLink(project, includeId, includeName)}</>
}

export function tasksLink(project: ProjectData): React.ReactNode {
  return project.state !== 'confirmed' ? <a style={{ fontSize: '0.7rem', padding: 0 }} className="btn btn-sm" data-toggle="modal" data-target="modal_show_project_tasks" data-remote="true" href={`/projects/${project.id}/tasks`}>{project.progress_bar[2]}% ({project.progress_bar[0]} / {project.progress_bar[1]})</a> : ""
}

export function truckLink(truck: TruckData,
  showDispatchDate: boolean = true,
  showLoadPct: boolean = false,
  showConsecNum: boolean = false): React.ReactNode {

  return (truck) ? <a href={truck.url}>{truckSpan(truck, showDispatchDate, showLoadPct, showConsecNum)}</a> : ""
}

export function truckDispatchDate(truck: TruckData): String {
  return (truck && truck.dispatch_date && truck.dispatch_date != "--/--/--") ? `[${truck.dispatch_date}]` : "--/--"
}

export function truckSpan(truck: TruckData,
  showDispatchDate: boolean = true,
  showLoadPct: boolean = false,
  showConsecNum: boolean = false): React.ReactNode {
  const multiAddresses = truck.multi_addresses ? <i className="fa fa-map-marker" aria-hidden="true"></i> : ""

  let typIcon = <i className="fa fa-truck"></i>;

  if (truck.typ === 'container_20') {
    typIcon = <i className="fa fa-archive"></i>;
  }
  else if (truck.typ === 'container_40') {
    typIcon = <i className="fa fa-archive"></i>;
  }

  return <span title={`${truck.payload_price} €- ${truck.payload_pct}%`}
    className={truckDisplayColor(truck.payload_pct)}>
    {multiAddresses}{typIcon}{truck.id} &nbsp;
    {showDispatchDate && truckDispatchDate(truck)}
    {showLoadPct && `${truck.payload_pct} %`}
    {showConsecNum && `${truck.consec_number}`}
  </span>
}

export function projectState(proj: ProjectData): React.ReactNode {
  if (proj.state === 'confirmed') {
    return <span className="label label-warning">Confirmed</span>
  }
  else if (proj.state === 'waiting') {
    return <span className="label label-success">Waiting</span>
  }
  else if (proj.state === 'in_production') {
    return <span className="label label-success">Production</span>
  }
  else if (proj.state === 'ready_for_delivery') {
    return <span className="label label-info">Ready For Delivery</span>
  }
  else {
    return <span className="label">{proj.state}</span>
  }

}


export function taskStateById(task_statuses: TaskStatusVal[], task_type_id: number): React.ReactNode {
  const result = task_statuses.find((ts) => ts.id === task_type_id)

  if (!result) return <></>;

  if (result.status === 'done') {
    return <span className="label label-success">D</span>
  }
  else if (result.status === 'in_progress') {
    return <span className="label label-info">P</span>
  }
  else if (result.status === 'waiting') {
    return <span className="label label-warning">W</span>
  }
  else if (result.status === 'todo') {
    return <span className="label">T</span>
  }
  else {
    return <span className="label">{result.status}</span>
  }

}



export function colorTaskStatus(status: string): React.ReactNode {
  const stat = status.toUpperCase();

  if (status === 'done') {
    return <span className="label label-success">{stat}</span>
  }
  else if (status === 'in_progress') {
    return <span className="label label-info">{stat}</span>
  }
  else if (status === 'waiting') {
    return <span className="label label-warning">{stat}</span>
  }
  else if (status === 'todo') {
    return <span className="label">{stat}</span>
  }
  else {
    return <span className="label">{stat}</span>
  }

}


export function colorPriority(priority: string): React.ReactNode {
  const stat = priority.toUpperCase();

  if (priority === 'low') {
    return <span className="label">{stat}</span>
  }
  else if (priority === 'normal') {
    return <span className="label label-info">{stat}</span>
  }
  else if (priority === 'high') {
    return <span className="label label-warning">{stat}</span>
  }
  else if (priority === 'urgent') {
    return <span className="label label-danger">{stat}</span>
  }
  else {
    return <span className="label">{stat}</span>
  }

}


export function taskState(state: number | undefined): React.ReactNode {
  if (state === 2) {
    return <span className="label label-success">DONE</span>
  }
  else if (state === 1) {
    return <span className="label label-info">IN PR.</span>
  }
  else if (state === 4) {
    return <span className="label label-warning">WAIT</span>
  }
  else {
    return <span className="label">TODO</span>
  }
}

export function cardIcon(is_done: boolean, is_required: boolean, icon: string,
  done_msg: string, not_done_msg: string, not_required_message): React.ReactNode {
  const fa_icon = `fa fa-${icon}`
  let color = ""
  let title = ""

  if (is_required) {
    if (is_done) {
      color = "text-green"
      title = done_msg
    }
    else {
      color = "text-warning"
      title = not_done_msg
    }
  }
  else {
    color = is_done ? "text-green" : "text-muted"
    title = is_done ? is_done : not_required_message
  }

  return <i className={`${color} ${fa_icon}`} title={title} ></i>
}

export function cardFileIcon(file_path: string, file_required: boolean, file_name: string, icon: string): React.ReactNode {
  return cardIcon((file_path && file_path.length > 0), file_required, icon,
    `${file_name}: ${i18n.t("uploaded")}`,
    `${file_name}: ${i18n.t("file_is_required")}`,
    `${file_name}: ${i18n.t("file_not_required")}`)
}

export function FormatDate(date) {
  return date ? moment(date).format("YYYY-MM-DD HH:mm").toString() : 'n.a.'
}


export function FormatPrice(price) {
  return price ? parseFloat(price).toFixed(2) : 0.00
}


export function inNDays(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() + (days));
  return date;
}
