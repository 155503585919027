import React, { useEffect, useState } from 'react'
import { Select, MenuItem, FormHelperText, FormControl } from '@mui/material';
import { fieldName } from './CommonFuncs';


interface UnitSelectProps {
  index: number,
  unit: string,
  readonly: boolean,
  autoValidate: boolean,
  onChange: (newUnit: string) => void
}

const UnitSelect = (props: UnitSelectProps) => {

  const [unit, setUnit] = useState(props.unit),
    [errorText, setErrorText] = useState(''),
    handleUnitChange = value => {
      setUnit(value)
      props.onChange(value)
      validate(value)
    },
    validate = (val) => {
      if (val !== undefined && val !== '') {
        setErrorText("")
      } else {
        setErrorText("cannot be empty")
      }
    };

  useEffect(() => {
    if (props.autoValidate) {
      validate(unit);
    }
  }, [props.autoValidate])

  return (

    <div className="col-4 col-md-4 col-xl-1">
      {props.readonly && props.unit}
      {!props.readonly &&
        <FormControl error={errorText !== ''} >
          <Select
            onBlur={() => validate(unit)}
            name={fieldName(props.index, 'unit')}
            label="Unit"
            value={unit}
            onChange={event => handleUnitChange(event.target.value)}
          >
            <MenuItem value={''}>-</MenuItem>
            <MenuItem value={'pcs'}>Pcs</MenuItem>
            <MenuItem value={'m'}>m</MenuItem>
            <MenuItem value={'m2'}>m2</MenuItem>
          </Select>
          <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
      }
    </div >

  )
}

export default UnitSelect
