

import * as React from "react"
import { useSelector } from "react-redux"
import { ProjectData, TruckData } from "../Common/types"
import { VenueState } from "./AppReducer"

import Task from "./Task"


export interface TaskColumnProps {
  state_name: string,
  onTruckChange: (newTruck: TruckData) => void
}

const TaskColumn = (props: TaskColumnProps) => {

  const selected_customer = useSelector<VenueState, string>(
    state => state.filters.selected_customer
  )

  const selected_manager = useSelector<VenueState, string>(
    state => state.filters.selected_manager
  )

  const searched_order_id = useSelector<VenueState, string>(
    state => state.filters.order_id
  )

  const searched_project_id = useSelector<VenueState, string>(
    state => state.filters.project_id
  )

  const searched_project_name = useSelector<VenueState, string>(
    state => state.filters.project_name.toLowerCase()
  )

  const projects = useSelector<VenueState, ProjectData[]>(
    state => state.projects
      .filter((p) => (selected_customer === null) || (p.customer && p.customer.name == selected_customer))
      .filter((p) => (selected_manager === null) || p.sales_manager == selected_manager)
      .filter((p) =>
        (searched_order_id === "") ||
        (p.order && p.order.visible_id && p.order.visible_id.startsWith(searched_order_id)))
      .filter((p) =>
        (searched_project_id === "") ||
        (p.visible_id && p.visible_id.startsWith(searched_project_id)))
      .filter((p) => (p.name.toLowerCase().includes(searched_project_name)))
      .filter((p) => (p.state === props.state_name))
  )

  const projectTasks = projects.map(
    row => {
      return <Task key={row.id} project={row} onTruckChange={props.onTruckChange} />
    }
  )

  React.useEffect(() => {
    let temp = 0
    // finding column with highest height
    $(".agile-list").map(function () {
      const tmp = $(this).height()
      if (tmp > temp) {
        temp = tmp;
      }
    })
    // setting height of all columns to highest column (+ 20, beacuse padding 10 top and 10 bottom)
    $(".agile-list").map(function () {
      $(this).css("min-height", temp + 20)
    })
  }, [projectTasks])

  return (
    <div className="ibox">
      <div className="ibox-content">
        <h3 className="text-center">{props.state_name.replace(/\_/g, " ").toUpperCase()}</h3>
        <ul className="sortable-list connectList agile-list" id={props.state_name}>
          {projectTasks}
        </ul>
      </div>
    </div>

  )
}
export default TaskColumn


