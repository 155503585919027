import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import List from './List'
interface ListProps {
  states: [[string, string]],
  roles: string[],
}
const ListApp = (props: ListProps) => {
  return <Router>
    <Route
      render={(internalProps) => <List {...internalProps}
        roles={props.roles} />}
    />
  </Router>
}
  ;

export default ListApp

