import React from 'react'
import { ProjectData } from '../Common/types';
import { customerLink, orderLink, projectLink, customSym, stockSym, swapSym, projectState, taskState, projectLinkDecorated, taskStateById } from '../Common/functions';
import TruckInfo from '../Project/TruckInfo'
import { TT_INSTALLATION, TT_INSULATION, TT_WINDOWS, TT_WOODEN_PARTS } from './List';

interface TableRowDefaultProps {
  isChecked: boolean,
  project: ProjectData,
  tasktype: string,
  onCheckboxClick: (project: ProjectData, checked: boolean) => void
  onTruckUpdated: () => void
}

const TableRowDefault = (props: TableRowDefaultProps) => {

  function toggleChecked(): void {
    props.onCheckboxClick(props.project, !props.isChecked)
  }



  return (
    <tr className={`deadline2-${props.project.deadline_color}`}>
      <td onClick={() => toggleChecked()}>
        <input
          type="checkbox"
          checked={props.isChecked}
          onChange={() => toggleChecked()}
          className="i-checks"
        />
      </td>
      <td>
        {props.project.visible_id}
      </td>
      <td>
        {orderLink(props.project.order, props.project.order_visible_id)}
      </td>
      <td>

        {props.tasktype === TT_WINDOWS && props.project.xls_file2_items_required &&
          <>
            {props.project.xls_file2_items && <i className="fa fa-check text-navy" title="Uploaded"></i>}
            {!props.project.xls_file2_items && <i className="fa fa-exclamation text-warning pl-1" title="[Windows &amp; Doors] file is required"></i>}
          </>
        }

        {props.tasktype === TT_INSTALLATION && props.project.xls_file3_instal_required &&
          <>
            {props.project.xls_file3_instal && <i className="fa fa-check text-navy" title="Uploaded"></i>}
            {!props.project.xls_file3_instal && <i className="fa fa-exclamation text-warning pl-1" title="Installation kit file is required"></i>}
          </>
        }

        {props.tasktype === TT_INSULATION && props.project.xls_file4_insul_required &&
          <>
            {props.project.xls_file3_instal && <i className="fa fa-check text-navy" title="Uploaded"></i>}
            {!props.project.xls_file3_instal && <i className="fa fa-exclamation text-warning pl-1" title="Insulation kit file is required"></i>}
          </>
        }

      </td>

      {props.tasktype === TT_WINDOWS && <td>{props.project.windows_quality}</td>}
      {props.tasktype === TT_WINDOWS && <td>{props.project.doors_quality}</td>}

      <td>{projectLinkDecorated(props.project, false, true)}</td>
      <td>{props.project.deadline} ({props.project.days_to_deadline})</td>
      <td>{projectState(props.project)}</td>
      <td>{taskStateById(props.project.task_statuses, parseInt(props.tasktype))}</td>
      <td>
        {props.project.truck && <TruckInfo truck={props.project.truck} onTruckUpdated={props.onTruckUpdated} ></TruckInfo>}
      </td>
      <td>
        {customerLink(props.project.customer)}
      </td>
      <td>
        {props.project.sales_manager}
      </td>
      <td>
        {props.project.order_date}
      </td>

    </tr >

  )
}

export default TableRowDefault
