
import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import { ProductData, int } from '../Common/types';
import toastr from 'toastr'
import LaddaButton from 'react-ladda';
import XL from 'react-ladda';
import SLIDE_UP from 'react-ladda';

export interface UpdateTasksButtonProps {
  show: boolean,
  projects: Array<number>,
  taskStates: [[string, string]],
  handleClose: () => void,
  handleSubmit: () => void;
  // productAdded: (product: ProductData) => void;
}

const UpdateTasksButton = (props: UpdateTasksButtonProps) => {

  const [laddaState, setLaddaState] = useState({ loading: false, progress: 0 }),
    [selectedState, setSelectedState] = useState("done"),
    [htmlTable, setHtmlTable] = useState(""),
    stateOptions = props.taskStates.map((task) => (
      <option key={task[1]} value={task[1]} >
        {task[0]}
      </option>));

  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  function submitForm() {
    laddaOn();
    Axios({
      method: "POST",
      url: `/project_tasks/update_many.json`,
      data: {
        projects_ids: props.projects,
        newstatus: selectedState,
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();
        toastr.success("Tasks states updated successfully.");
        props.handleClose();
        props.handleSubmit();
      })
      .catch((err) => {
        laddaOff();
        toastr.error("Tasks states not updated ", err)
        console.log(err)
      })
  }

  function selectionActionChanged(event: React.SyntheticEvent): void {
    const target = event.target as HTMLSelectElement
    setSelectedState(target.value)
  };

  function loadData() {
    Axios({
      method: "POST",
      url: `/projects/projects_tasks`,
      data: {
        projects_ids: props.projects,
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        setHtmlTable(res.data)
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  };

  useEffect(() => {
    if (props.show) {
      loadData();
    }
  }, [props.show])

  return (

    <Modal data-testid="create_tasks_button" show={props.show} onHide={props.handleClose}>
      <Modal.Header className="modal-lg" closeButton>
        <Modal.Title>
          Update tasks states for projects: {props.projects.join(", ")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            Warning! The new status will be set to all existing tasks in the selected orders.
          </div>
          <div className="col-md-12">
            <div className="ibox form-group row">
              <label className="col-md-4 text-right">Mass action:</label>
              <div className='col-md-8'>
                <select
                  className="form-control"
                  onChange={selectionActionChanged}
                  value={selectedState}
                >
                  {stateOptions}
                </select>
              </div>
            </div >
          </div >
          <div className="col-md-12" style={{height: "50vh", overflow: "auto"}}>
            <div dangerouslySetInnerHTML={{__html: htmlTable}} />
          </div>
        </div >
      </Modal.Body >
      <Modal.Footer>
        <Button variant="default" onClick={props.handleClose}>
          Cancel
        </Button>
        {!!stateOptions.length &&
          <LaddaButton
            className="btn btn-primary"
            loading={laddaState.loading}
            onClick={() => submitForm()}
            data-color="#eee"
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
          >
            Save
          </LaddaButton>
        }

      </Modal.Footer>
    </Modal >

  )
}
export default UpdateTasksButton
