

import * as React from "react"
import { Provider } from "react-redux"
import { loadTrucks, venueStore } from "./AppReducer"
import { CustomerData, ProjectData, TruckData } from "../Common/types"
import TruckButton from "./TruckButton"
import Axios from 'axios'


export interface TruckButtonAppProps {
  project_id: number,
  truck_id: number,
  reassign_mode: boolean,
  projects_ids?: number[],
  projects_ids_has_stock?: boolean;
}

const TruckButtonApp = (props: TruckButtonAppProps) => {


  const [truck, setTruck] = React.useState<TruckData>(null);
  const [customer, setCustomer] = React.useState<CustomerData>(null);

  function onTruckChange(newTruck: TruckData) {
    setTruck(newTruck)
    if (props.reassign_mode) {
      window.location.reload()
    }
  }

  function loadProject() {
    Axios({ url: `/projects/${props.project_id}.json` })
      .then((res) => {
        let proj: ProjectData = res.data;
        setTruck(proj.truck);
        setCustomer(proj.customer);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  React.useEffect(() => {
    loadTrucks();
  }, [truck])

  React.useEffect(() => {
    props.project_id !== 0 && loadProject();
  }, [])

  return (
    <Provider store={venueStore}>
      <TruckButton
        onTruckChange={onTruckChange}
        project_id={props.project_id}
        customer={customer}
        truck={truck}
        reassign_mode={props.reassign_mode}
        projects_ids={props.projects_ids}
        projects_ids_has_stock={props.projects_ids_has_stock}
      ></TruckButton>
    </Provider>
  )
}
export default TruckButtonApp


