
import React, { useEffect, useState } from 'react'
import Rails from "@rails/ujs"
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios'
import { ProductData, int } from '../Common/types';
import toastr from 'toastr'
import LaddaButton from 'react-ladda';
import XL from 'react-ladda';
import SLIDE_UP from 'react-ladda';

export interface CreateTasksButtonProps {
  show: boolean,
  projects: Array<number>,
  handleClose: () => void,
  handleSubmit: () => void;
  // productAdded: (product: ProductData) => void;
}

const CreateTasksButton = (props: CreateTasksButtonProps) => {

  const [laddaState, setLaddaState] = useState({ loading: false, progress: 0 }),
        [selectedState, setSelectedState] = useState("in_production"),
        [tasks, setTasks] = useState(new Array<[string, string]>()),
        stateOptions = tasks.map((task) => (
          <option key={task[1]} value={task[1]} >
          {task[0]}
          </option>));;

  function laddaOn() {
    setLaddaState({ loading: true, progress: 0.5 });
  }

  function laddaOff() {
    setLaddaState({ loading: false, progress: 0.0 });
  }

  function submitForm() {
    laddaOn();
    Axios({
      method: "POST",
      url: `/project_tasks/create_many.json`,
      data: {
        projects_ids: props.projects,
        task_type_id: selectedState,
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();
        toastr.success("Tasks created successfully")
        props.handleClose();
      })
      .catch((err) => {
        laddaOff();
        toastr.error("Tasks NOT created ", err)
        console.log(err)
      })
  }

  function selectionActionChanged(event: React.SyntheticEvent): void {
    const target = event.target as HTMLSelectElement
    setSelectedState(target.value)
  };

  function loadData() {
    Axios({
      method: "POST",
      url: `/task_types/projects_task_types.json`,
      data: {
        projects_ids: props.projects
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        setTasks(res.data.task_types)
        if (res.data.task_types.length) {
          setSelectedState(res.data.task_types[0][1])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  };

  useEffect(() => {
    if (props.show) {
      loadData();
    }
  }, [props.show])

  return (

    <Modal data-testid="create_tasks_button" show={props.show} onHide={props.handleClose}>
      <Modal.Header className="modal-lg" closeButton>
        <Modal.Title>
          Create tasks for projects: {props.projects.join(", ")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
          { stateOptions.length ?
            <div className="ibox form-group row">
              <label className="col-md-4 text-right">Task type</label>
              <div className='col-md-8'>
              <select
                className="form-control"
                onChange={selectionActionChanged}
                value={selectedState}
              >
                {stateOptions}
              </select>
              </div>
            </div >
            :
            <p>Projects have already created possible tasks</p>
            }
          </div >
        </div >
      </Modal.Body >
      <Modal.Footer>
        <Button variant="default" onClick={props.handleClose}>
          Cancel
        </Button>
        { !!stateOptions.length &&
          <LaddaButton
            className="btn btn-primary"
            loading={laddaState.loading}
            onClick={() => submitForm()}
            data-color="#eee"
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
          >
            Save
          </LaddaButton>
        }

      </Modal.Footer>
    </Modal >

  )
}
export default CreateTasksButton
