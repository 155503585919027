import Select from "react-select"
import * as React from "react"
import Rails from "@rails/ujs"
import axios from "axios"
import toastr from 'toastr'

export interface ShortTruckData {
  id: string,
  typ: "truck_90" | "truck_120" | "container_20" | "container_40"
  setSelectedType: (string) => any
}

interface OptionsData {
  label: string,
  value: string,
}

const TypeSelector = (props: ShortTruckData) => {
  const handleTruckTypeChange = (val: OptionsData) => {
    props.setSelectedType(val.value)
    toastr.warning("Warning", "If you change the type of truck, some packages may not fit on the truck.");
  }

  const options: OptionsData[] = [
    { label: "Truck 90", value: "truck_90" },
    { label: "Truck 120", value: "truck_120" },
    { label: "Container 20", value: "container_20" },
    { label: "Container 40", value: "container_40" }
  ]

  return (
    <div className="form-group">
      <Select
        placeholder="TruckType"
        options={options}
        defaultValue={options.find(opt => opt.value === props.typ)}
        onChange={(val: OptionsData)  => handleTruckTypeChange(val)}
      />
    </div>
  )
}

export default TypeSelector
