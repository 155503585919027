import React, { useEffect, useState } from 'react'
import * as i18n from "i18n-js"
import { PackingItemData, ShippingMark } from '../Common/types';
import * as strftime from "strftime";

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface PackingItemProps {
  item: PackingItemData,
  total_packs: number,
  position: number,
  pack_finished: boolean,
  sack_message: string,
  handleTrashClick: (item: PackingItemData) => void,
  handleSackClick: (item: PackingItemData) => void,
  handleDoneClick: (item: PackingItemData) => void
}

function shippingMarksImages(shippingMark: ShippingMark, index: number, id: number) {
  return (
    <img key={`${shippingMark.id}-${id}-${index}`} width="30px" height="40px" style={{margin: "0px 2px"}} src={shippingMark.url} alt={shippingMark.name}></img>
  )
}

const PackingItem = (props: PackingItemProps) => {

  const [done, setDone] = useState(props.item.user);
  useEffect(() => {
    setTooltips();
  }, [])

  return (
    <tr>
      <td>{props.pack_finished ? props.item.name : `${props.position} / ${props.total_packs}`}</td>
      <td className="text-right" >{props.item.width} </td>
      <td className="text-right" >{props.item.height} </td>
      <td className="text-right" >{props.item.depth} </td>
      <td className="text-right" >{props.item.weight} </td>
      <td className="text-right" >{props.item.shipping_marks.map((m, i) => shippingMarksImages(m, i, props.item.id))} </td>
      <td className="text-center" >

        <button onClick={() => props.handleTrashClick(props.item)}
          className="btn btn-danger btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title={i18n.t('remove')}
          disabled={props.pack_finished}
        >
          <i className="fa fa-trash-o"></i>
        </button>
        &nbsp;&nbsp;&nbsp;
        <button onClick={() => props.handleSackClick(props.item)}
          className="btn btn-white btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title={props.sack_message}
          disabled={!props.pack_finished}
        ><i className="fa fa-ambulance"></i>
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          onClick={() => props.handleDoneClick(props.item)}
          className={`btn btn-${done ? "danger" : "primary"} btn-sm`}
          data-toggle="tooltip"
          data-placement="top"
          title={`${done ? "UNDONE" : "DONE"}`}
        >
          <i className="fa fa-check"/>
        </button>
        &nbsp;
        {done &&
          <span
            className="btn btn-sm btn-info"
            data-toggle="tooltip"
            data-placement="top"
            data-html="true"
            title={`${done.email}<br/>${done.first_name} ${done.last_name}<br/>${strftime('%d/%m/%y %H:%M', new Date((props.item.done_at * 1000) || 0))}`}
          >
            <i className="fa fa-info-circle"></i>
          </span>}
      </td>
    </tr>
  )
}

export default PackingItem
