import Rails from "@rails/ujs";
import Axios from 'axios';
import * as i18n from "i18n-js";
import _ from "lodash";
import * as React from "react";
import { Button, Modal } from 'react-bootstrap';
import LaddaButton, { SLIDE_UP, XL } from 'react-ladda';
import { useSelector } from "react-redux";
import toastr from 'toastr';
import { truckSpan } from "../Common/functions";
import { CustomerData, TruckData } from "../Common/types";
import { VenueState } from "./AppReducer";

export interface TruckButtonProps {
  project_id: number,
  customer: CustomerData,
  truck: TruckData,
  onTruckChange: (newTruck: TruckData) => void,
  reassign_mode: boolean,
  projects_ids?: number[],
  projects_ids_has_stock?: boolean;
}

const TruckButton = (props: TruckButtonProps) => {

  const [customerId, setCustomerId] = React.useState(props.customer?.id);

  const [show, setShow] = React.useState(false);

  const [truckId, setTruckId] = React.useState(props.truck ? props.truck.id : 0);

  const [laddaState, setLaddaState] = React.useState({ loading: false, progress: 0 });

  const rawTrucks = useSelector<VenueState, TruckData[]>(state => state.trucks);

  const trucks =
    rawTrucks.filter((t) => (t.customer && props.customer && t.customer.id === props.customer.id));

  const otherTrucks = rawTrucks.filter((t) => (t.customer && props.customer && t.customer.id !== props.customer.id));

  React.useEffect(() => {
    setCustomerId(props.customer?.id)
  }, [props.customer])

  const handleClose = () => setShow(false);

  const setTruck = (truckId: number, customerId: number) => {
    setTruckId(truckId);
    setCustomerId(customerId);
  }

  function laddaOn() {
    setLaddaState({
      loading: true,
      progress: 0.5,
    });
  }


  function laddaOff() {
    setLaddaState({
      loading: false,
      progress: 0.0,
    });
  }

  function assignClicked() {
    setShow(true);
    // setTruckId(props.truck ? props.truck.id : 0)
    setTruck(props.truck ? props.truck.id : 0, props.customer?.id)
  }

  function submitForm(): void {
    if (truckId > 0 && props.truck && truckId == props.truck.id) {
      setShow(false);
      return
    }

    laddaOn();

    Axios({
      method: "POST",
      url: `/trucks/${truckId}/assign_projects.json`,
      data: { project_ids: !props.projects_ids ? [props.project_id] : props.projects_ids },
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        laddaOff();
        setShow(false);
        toastr.success("Truck is changed");
        props.onTruckChange(res.data);
      })
      .catch((err) => {
        laddaOff();
        toastr.error("Truck is NOT changed", err)
      })
  }

  const radios =
    trucks.map(t => (
      <div key={t.id}>
        <label>
          <input type="radio" onChange={e => setTruck(Number(e.target.value), props.customer.id)} checked={truckId === t.id} value={t.id} />  {truckSpan(t)}  {t.payload_pct}%
        </label>

        &nbsp;&nbsp;&nbsp;
        <a href={`/trucks/${t.id}`} target="_blank" title="Show truck details" ><span className="mr-2">
          <i className="fa fa-search"></i>
        </span></a>

      </div>
    ))

  const Others = ({raw = false }: { raw?: boolean }) => {
    return (
      <>
        {Object.entries(
          _.groupBy(raw ? rawTrucks : otherTrucks, (truck) => truck.customer?.id)
        ).map((t, idx) => {
          return (
            <React.Fragment key={`${t[1][0].id}-${idx}`}>
              {/* TODO: in future probably change domain_url to email or name */}
              <span className="font-weight-bold">
                {t[1][0].customer?.name} ({t[1][0].customer?.domain_url})
              </span>
              <br />
              {t[1].map((t2) => {
                return (
                  <div key={t2.id}>
                    <label>
                      <input
                        type="radio"
                        onChange={(e) =>
                          setTruck(Number(e.target.value), t2.customer?.id || 0)
                        }
                        checked={truckId === t2.id}
                        value={t2.id}
                      />{" "}
                      {truckSpan(t2)} {t2.payload_pct}%
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <a
                      href={`/trucks/${t2.id}`}
                      target="_blank"
                      title="Show truck details"
                    >
                      <span className="mr-2">
                        <i className="fa fa-search"></i>
                      </span>
                    </a>
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  let link = <></>;

  if (props.truck) {
    const label = props.reassign_mode ? "Reassign" : truckSpan(props.truck)
    link = <a href="#" className="btn btn-xs btn-outline-dark assign-truck" onClick={() => assignClicked()} >{label}</a>
  } else {
    link = <a href="#" className={`btn btn-outline-dark assign-truck${props.projects_ids_has_stock || props.projects_ids?.length === 0 ? " disabled" : ""}`} title={i18n.t('assign_truck')} onClick={() => assignClicked()} >
      <i className="fa fa-truck mr-2"></i> {i18n.t('assign')}
    </a>
  }

  return (
    <div className="pt-2">
      {link}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.truck ? "Reassign" : "Assign"} Truck</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!props.projects_ids && (
            <>
              {" "}
              <h3> OPEN trucks for {props.customer && props.customer.name}:</h3>
              <div className="form-group row">
                <div className="col-sm-10">
                  <div>
                    <label>
                      <input
                        type="radio"
                        onChange={(e) =>
                          setTruck(Number(e.target.value), props.customer.id)
                        }
                        checked={
                          truckId === 0 && props.customer?.id === customerId
                        }
                        value={0}
                      />{" "}
                      NEW
                    </label>
                  </div>

                  {radios}
                </div>
              </div>
            </>
          )}

          <h3> OPEN trucks for OTHER CUSTOMERS</h3>
          <div className="form-group row">
            <div className="col-sm-10">
              <Others raw={true} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>

          <LaddaButton
            className="btn btn-primary"
            loading={laddaState.loading}
            onClick={submitForm}
            data-color="#eee"
            data-size={XL}
            data-style={SLIDE_UP}
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
          >
            {props.truck ? "Reassign" : "Assign"}
          </LaddaButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default TruckButton
