import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BasicTable from './BasicTable';

const ListApp = () => (
  <Router>
    <Route component={BasicTable}   />;
  </Router>
);

export default ListApp

