import { Button, Checkbox, Chip, FormControlLabel } from '@mui/material';
import * as React from 'react';
import { useState } from "react";
import { int, OperatorshipData } from '../Common/types';

interface OperatorFieldProps {
  index: int,
  removeOperator: (index: int) => void,
  tempAwayClicked: (index: int, isChecked: boolean) => void,
  operatorship: OperatorshipData
}

export default function OperatorField(props: OperatorFieldProps) {
  const [tempAway, setTempAway] = useState(props.operatorship.temp_away);

  function fieldName(index, attr) {
    return (`machine[operatorships_attributes][${index}][${attr}]`)
  }

  const
    index = props.index,
    name = props.operatorship.name,
    userId = props.operatorship.user_id;

  function tempAwayClicked(isChecked: boolean) {
    setTempAway(isChecked);
    props.tempAwayClicked(index, isChecked)
  }

  if (props.operatorship._destroy && props.operatorship.id) {
    return (<>
      <input type="hidden" name={fieldName(index, 'id')} value={props.operatorship.id} />
      <input type="hidden" name={fieldName(index, '_destroy')} value='1' />
    </>
    )
  }

  return (
    <div className="row my-6">
      <div className="col-6">
        <div className="form-group">
          <input type="hidden"
            name={fieldName(index, 'id')}
            value={props.operatorship.id} />
          <input type="hidden"
            name={fieldName(index, 'user_id')}
            value={userId} />
          <input type="hidden"
            name={fieldName(index, 'temp_away')}
            value={tempAway ? '1' : '0'} />
          <Chip label={name} />
        </div>
      </div>
      <div className="col">

        <FormControlLabel
          control={
            <Checkbox checked={tempAway}
              onChange={(e) => tempAwayClicked(e.target.checked)}
            />
          }
          label="Away"
        />
      </div>
      <div className="col">
        <Button variant="outlined" color="error"
          onClick={() => props.removeOperator(index)}>x</Button>
      </div>
    </div>)


}
