import React, { useEffect } from 'react'
import Rails from "@rails/ujs"
import Axios from 'axios'
import * as i18n from "i18n-js"
import { Wheel } from "./Wheel"
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import toastr from 'toastr'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

interface MainProps {
  truck_id: number,
  app: Wheel,
  simple: boolean
}

i18n.translations = I18n.translations || {};

const Container_20 = (props: MainProps) => {


  const app = props.app;
  app.truckLeftDivId = "container-left";
  app.truckRightDivId = "container-right";
  app.plannerDivId = "planner";
  app.truckHeight = 240;
  app.truckWidth = 590;

  function loadPlanData() {
    Axios({ url: `/trucks/${props.truck_id}/packing_plan.json` })
      .then((res) => {
        let pplan = res.data;
        // pplan.width = app.truckWidth;
        // pplan.height = app.truckHeight;
        app.hello(pplan);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function savePlan(): void {
    app.savePackingPlan();
    const data = app.getPackingPlan();
    if (data === null) {
      toastr.error("Package plan not saved", "package colide")
    } else {
      // data = data
      Axios({
        method: "POST",
        url: `/trucks/${props.truck_id}/packing_plan.json`,
        data: data,
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
          "Content-Type": "application/json"
        }
      }).then((res) => {
        if (res.data['status'] === 'ok') {
          toastr.success("Package plan saved")
        }
        else {
          toastr.error("Package plan not saved", res.data['errors'])
        }

      }).catch((err) => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    loadPlanData();
  }, [])


  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={12} xs={12}>
            <Item style={{ marginTop: '20px', marginBottom: '20px', paddingBottom: '20px', visibility: 'hidden' }}></Item>
            <Item>
              <div className="ibox">
                <div className="ibox-content hide-scrollbar" id="planner" >
                  <div className="row">
                    <div className="col">
                      <div className="col justify-content-start">
                        <div className="row justify-content-center align-items-start">
                          <div className="col-4 d-flex justify-content-center package-height">
                            <div id="package-left-height">
                              <p className="ph" id="plh-1"></p>
                            </div>
                          </div>
                          <div className="col-4 d-flex justify-content-center package-height">
                            <div id="package-left-height">
                              <p className="ph" id="plh-2"></p>
                            </div>
                          </div>
                          <div className="col-4 d-flex justify-content-center package-height">
                            <div id="package-left-height">
                              <p className="ph" id="plh-3"></p>
                            </div>
                          </div>
                        </div>
                        <div id="container-left">
                          <div className="justify-content-start align-items-start" id="grid-box">
                            <div className="row justify-content-center align-items-start">
                              <div id="hoz-meter"></div>
                            </div>
                            <div className="row">
                              <div className="col" id="vert-box">
                                <div id="ver-meter"></div>
                              </div>
                              <div className="col" id="box-conteiner-container-20">
                                <div className="justify-content-end align-items-start" id="box-left">
                                  <div className="magnet" id="box-fill-left"></div>
                                </div>
                              </div>
                              <div className="col" id="vert-box">
                                <div id="ver-meter"></div>
                              </div>
                            </div>
                            <div className="row justify-content-center align-items-end">
                              <div id="hoz-meter"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center align-items-start">
                          <div className="col-4 d-flex justify-content-center package-height">
                            <div id="package-right-height">
                              <p className="ph" id="prh-1"></p>
                            </div>
                          </div>
                          <div className="col-4 d-flex justify-content-center package-height">
                            <div id="package-right-height">
                              <p className="ph" id="prh-2"></p>
                            </div>
                          </div>
                          <div className="col-4 d-flex justify-content-center package-height">
                            <div id="package-right-height">
                              <p className="ph" id="prh-3"></p>
                            </div>
                          </div>
                        </div>
                        <div className="justify-content-start align-items-start" id="container-right">
                          <div className="justify-content-start align-items-start" id="grid-box">
                            <div className="row justify-content-center align-items-start ">
                              <div id="hoz-meter-right"></div>
                            </div>
                            <div className="row">
                              <div className="col" id="vert-box">
                                <div id="ver-meter"></div>
                              </div>
                              <div className="col" id="box-conteiner">
                                <div className="justify-content-end align-items-start" id="box-right">
                                  <div className="magnet" id="box-fill-right"></div>
                                </div>
                              </div>
                              <div className="col" id="vert-box">
                                <div id="ver-meter"></div>
                              </div>
                            </div>
                            <div className="row justify-content-center align-items-end">
                              <div id="hoz-meter-right"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="container">
                        <div className="row justify-content-start">
                          <h1>Packages:</h1>
                        </div>
                        <div className="row justify-content-start align-items-start">
                          <div id="items"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {!props.simple &&
                <div className="row justify-content-end mb-3" >
                  <button className="btn btn-primary px-3 py-2 mr-3 my-2" id="closeButton" onClick={() => { window.location.replace(`/trucks/${props.truck_id}`); }} >Close</button>
                  <button className="btn btn-primary px-3 py-2 mr-3 my-2" id="saveButton" onClick={() => savePlan()} >Save</button>
                </div>
              }
            </Item>

          </Grid>
        </Grid>

      </Box>

    </>
  )
}

export default Container_20
