import List from '@mui/material/List';
import Axios from 'axios';
import React from 'react';
import { CategoryData } from '../Common/types';
import CategoryItem from './CategoryItem';


function App() {
  const [categories, setCategories] = React.useState(new Array<CategoryData>());

  function handleAddClick(category: CategoryData) {
    setCategories([...categories, category])
  }

  function handleDeleteClick(category: CategoryData) {
    const newCats = categories.filter((c) => c.id !== category.id)
    setCategories(newCats)
  }

  function loadCategories(): void {
    Axios({ url: `/categories.json` })
      .then((res) => {
        setCategories(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  React.useEffect(() => {
    loadCategories();
  }, [])


  return (
    <div>
      <h1>Category Tree</h1>

      <List  >
        {categories.filter((c) => !c.parent_category_id).map((c) =>
          <CategoryItem
            key={c.id}
            categories={categories}
            category={c}
            depth={0}
            onAddClick={handleAddClick}
            onDeleteClick={handleDeleteClick}
          />
        )}
      </List>

    </div>
  );
}

export default App;

