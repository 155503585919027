import React from 'react'
import * as i18n from "i18n-js"

//@ts-ignore
i18n.translations = I18n.translations || {};

export interface ActionsProps {
  selectedItems: number,
  onReadClick: () => void,
  onCheckClick: () => void,
  onTrashClick: () => void,
  onRefreshClick: () => void,
}

const Actions = (props: ActionsProps) => {
  return (
    <div className="btn-group float-left">
       <button
        onClick={props.onRefreshClick}
        className="btn btn-white btn-sm"
        data-toggle="tooltip"
        data-placement="left"
        title={i18n.t('refresh')}
      >
        <i className="fa fa-refresh"></i> {i18n.t('refresh')}
      </button>
      <button
        onClick={props.onReadClick}
        className="btn btn-white btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        title={i18n.t('mark_as_read')}
        disabled={props.selectedItems === 0}
      >
        <i className="fa fa-eye"></i>
      </button>
      <button onClick={props.onTrashClick}
        className="btn btn-white btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        title={i18n.t('remove')}
        disabled={props.selectedItems === 0}
      >
        <i className="fa fa-trash-o"></i>
      </button>
    </div>
  )
}

export default Actions
