import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Axios from 'axios';
import * as React from 'react';
import { CategoryData, CategoryshipData, int } from '../Common/types';

interface CategoryAppProps {
  categoryships: CategoryshipData[]
}

export default function CategoryApp(props:CategoryAppProps) {
  const [categoryIds, setCategoryIds] = React.useState(props.categoryships.map((c) => c.category_id));
  const [categories, setCategories] = React.useState(new Array<CategoryData>());
  const categoryships = React.useMemo(() => {

    const category_ids_only = props.categoryships.map((c) => c.category_id)
    const difference = categoryIds.filter((cid) => category_ids_only.indexOf(cid) === -1);
    const update = props.categoryships.map((c) => ({...c, '_destroy': categoryIds.indexOf(c.category_id) === -1 }));
    const append =  difference.map((cid) => ({id: 0, category_id: cid, '_destroy' : false}))
    return [ ... update, ... append]

  }, [categoryIds, categories]);

  const handleChange = (event: SelectChangeEvent<typeof categoryIds>) => {
    const {
      target: { value },
    } = event;

    const newCatIds = (typeof(value) === 'string') ? value.split(',').map((v) => parseInt(v)) : value
    setCategoryIds(newCatIds);
  };

  function loadCategories(): void {
    Axios({ url: `/categories.json` })
      .then((res) => {
        setCategories(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function catName(cid: int): string {
    const cat = categories.find((c) => c.id === cid);
    return cat ? cat.name : `${cid}`
  }

  function fieldName(index, attr) {
    return (`product[categoryships_attributes][${index}][${attr}]`)
  }

  React.useEffect(() => {
    loadCategories();
  }, [])


  return (
    <div>
      {categoryships.map((cship, index) =>
        <div key={cship.category_id}>
          <input type="hidden" name={fieldName(index, 'id')} value={cship.id > 0 ? cship.id : '' } />
          <input type="hidden" name={fieldName(index, 'category_id')} value={cship.category_id} />
          <input type="hidden" name={fieldName(index, '_destroy')} value={cship._destroy ? '1' : '0'} />
        </div>
      )}

      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          multiple
          value={categoryIds}
          onChange={handleChange}

          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((cid) => (
                <Chip key={cid} label={catName(cid)} />
              ))}
            </Box>
          )}

        >
          {categories.map((c: CategoryData) => (
            <MenuItem
              key={c.id}
              value={c.id}
              sx={{ paddingLeft: c.level * 4 }}
            >
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
